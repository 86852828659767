import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "font-semibold" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "pi pi-check-circle text-green-600"
}
const _hoisted_4 = {
  key: 1,
  class: "pi pi-times-circle text-red-600"
}
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 1 }

export function render(_ctx, _cache) {
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.changeSet), (property) => {
      return (_openBlock(), _createElementBlock("div", null, [
        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('productShopDataSync.' + property)), 1),
        _cache[0] || (_cache[0] = _createTextVNode(": ")),
        ((typeof _ctx.changeSet[property] !== 'string' && !(_ctx.changeSet[property] instanceof String)))
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
              (_ctx.changeSet[property] === true)
                ? (_openBlock(), _createElementBlock("i", _hoisted_3))
                : (_ctx.changeSet[property] === false || _ctx.changeSet[property] === null)
                  ? (_openBlock(), _createElementBlock("i", _hoisted_4))
                  : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.changeSet[property]), 1))
            ]))
          : _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_6, [
              _createTextVNode(_toDisplayString(_ctx.stripTagsAndTruncate(_ctx.changeSet[property], 25)), 1)
            ])), [
              [
                _directive_tooltip,
                _ctx.changeSet[property],
                void 0,
                { top: true }
              ]
            ])
      ]))
    }), 256))
  ]))
}