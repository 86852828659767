import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import {FilterMatchMode} from 'primevue/api';
import LoadingPlugin from 'vue-loading-overlay';
import {onMounted, ref} from 'vue';
import {useToast} from 'vue-toastification';
import {
    getSupplierConfiguration,
    removeSupplierConfigurationDiscountGroup,
    updateSupplierConfiguration
} from '@/services/price-import';
import Panel from 'primevue/panel';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import Crons from '@/pages/tools/jobs/crons.vue';
import Checkbox from 'primevue/checkbox';
import Button from 'primevue/button';
import Tag from 'primevue/tag';
import AddSupplierDialog from '@/components/dialog/prices/add-supplier.vue';
import AddDiscountGroupDialog from '@/components/dialog/prices/add-discount-group.vue';
import {i18n} from '@/utils/i18n';
import {useConfirm} from 'primevue/useconfirm';

export default {
    name: 'ImportLogs',
    components: {
        DataTable,
        Column,
        InputText,
        Dropdown,
        loading: LoadingPlugin,
        Panel,
        TriStateCheckbox,
        InputNumber,
        AddSupplierDialog,
        AddDiscountGroupDialog,
        Crons,
        'p-checkbox': Checkbox,
        'p-button': Button,
        Tag
    },
    setup() {
        onMounted(() => {
            loadLazyData();
        });

        const dt = ref();
        const loading = ref(false);
        const allEnvs = ref(false);
        const totalRecords = ref(0);
        const editingRows = ref([]);
        const reloadRequired = ref(0);
        const displayAddDialog = ref(false);
        const displayAddDiscountGroupDialog = ref(false);
        const selectedConfiguration = ref(null);

        const toast = useToast();

        const confirm = useConfirm();

        const logs = ref();

        const filterDefintions: any = {
            supplierName: {value: null, matchMode: FilterMatchMode.CONTAINS},
            costPriceInGross: {value: null, matchMode: FilterMatchMode.EQUALS},
            listPriceInGross: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            trailingDiscountPossible: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            partialPriceList: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
            automaticImportActive: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            forceSellPriceToListPrice: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            }
        };

        const filters = ref(filterDefintions);

        const loadLazyData = () => {
            loading.value = true;

            getSupplierConfiguration()
                .then((data) => {
                    totalRecords.value = data.data.total;
                    logs.value = data.data.items.map((obj: any) => ({
                        ...obj,
                        automaticImportActive: !!obj.automaticImportJob
                    }));
                })
                .catch((error) => {
                    toast.error(error.message);
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        const onRowEditComplete = (event: {data: any; newData: any}) => {
            loading.value = true;
            const {
                costPriceInGross,
                listPriceInGross,
                trailingDiscountPossible,
                partialPriceList,
                automaticImportActive,
                importConfirmationPercentageThreshold,
                trailingDiscountPercentage,
                forceSellPriceToListPrice
            } = event.newData;
            updateSupplierConfiguration(event.data.id, {
                costPriceInGross,
                listPriceInGross,
                trailingDiscountPossible,
                partialPriceList,
                automaticImportActive,
                importConfirmationPercentageThreshold,
                trailingDiscountPercentage,
                forceSellPriceToListPrice
            })
                .then(() => {
                    reloadRequired.value = reloadRequired.value + 1;
                    loadLazyData();
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                    loading.value = false;
                });
        };

        const closeDialogListener = (forceRefresh: boolean = false) => {
            displayAddDialog.value = false;
            displayAddDiscountGroupDialog.value = false;
            selectedConfiguration.value = null;
            if (forceRefresh) {
                loadLazyData();
            }
        };

        const removeDiscountGroup = (
            supplierConfigurationId: string,
            element: {
                id: number;
                name: string;
                manufacturerId: string | null;
                percentage: number;
            }
        ) => {
            confirm.require({
                message: i18n.global.t('messages.deleteConfirmation', {
                    item:
                        (element.name || 'HR') +
                        ' : ' +
                        element.percentage +
                        '%'
                }),
                header: i18n.global.t('messages.pleaseConfirm'),
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: i18n.global.t('labels.yes'),
                rejectLabel: i18n.global.t('labels.no'),
                accept: () => {
                    loading.value = true;
                    removeSupplierConfigurationDiscountGroup(
                        supplierConfigurationId,
                        element.id
                    )
                        .then(() => {
                            loadLazyData();
                        })
                        .catch((error) => {
                            toast.error(error.message);
                            loading.value = false;
                        });
                }
            });
        };

        return {
            logs,
            dt,
            totalRecords,
            loading,
            allEnvs,
            filters,
            editingRows,
            onRowEditComplete,
            reloadRequired,
            locale: i18n.global.locale,
            formatter: new Intl.NumberFormat(i18n.global.locale),
            displayAddDialog,
            displayAddDiscountGroupDialog,
            closeDialogListener,
            selectedConfiguration,
            removeDiscountGroup
        };
    }
};
