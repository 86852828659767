<!-- Content Header (Page header) -->
<loading v-model:active="loading" />
<ScrollPanel style="height: calc(100vh - 12rem)">
    <Panel
        class="panel-container-datatable"
        :header="$t('labels.skuMaintenance')"
    >
        <form @submit.prevent="handleSearch(!v$.$invalid)">
            <div class="grid p-fluid">
                <div class="col field">
                    <label>{{$t('labels.productAdd.sku')}}</label>
                    <span class="p-input-icon-left">
                        <IconField iconPosition="left">
                            <InputIcon class="pi pi-search"> </InputIcon>
                            <InputText
                                class="text-sm"
                                v-model.trim="v$.articleNumber.$model"
                                :class="{'p-invalid':v$.articleNumber.$invalid && submitted}"
                                :placeholder="'SKU, ' + $t('labels.commaSeparated')"
                            />
                        </IconField>
                    </span>
                </div>
                <div class="col field">
                    <label>{{$t('labels.productAdd.supplier')}}</label>
                    <p-dropdown
                        v-model="v$.supplier.$model"
                        :options="(allSuppliers || [])"
                        optionLabel="label"
                        optionValue="value"
                        :class="{'p-invalid':v$.supplier.$invalid && submitted}"
                        filter
                        showClear
                    >
                    </p-dropdown>
                </div>
                <div class="col field">
                    <label>{{$t('labels.manufacturer')}}</label>
                    <p-multiselect
                        v-model="v$.manufacturer.$model"
                        :class="{'p-invalid':v$.manufacturer.$invalid && submitted}"
                        :options="manufacturerOptions || []"
                        optionLabel="name"
                        optionValue="id"
                        filter
                    >
                    </p-multiselect>
                </div>
                <div class="col-2 field">
                    <label>Nicht in PL vorhanden</label>
                    <div>
                        <p-checkbox
                            v-model="v$.eol.$model"
                            binary
                            class="mt-2"
                        />
                    </div>
                </div>
                <div class="col-1 w-10rem">
                    <p-button
                        severity="success"
                        v-text="$t('buttons.confirm')"
                        type="submit"
                        :disabled="(editingRows || []).length > 0"
                        style="margin-top: 20px"
                    >
                    </p-button>
                </div>
            </div>
        </form>
        <DataTable
            v-if="products.length > 0"
            class="p-datatable-sm text-sm"
            paginator
            :rows="10"
            style="height: 100%"
            scrollHeight="calc(100vh - 23rem)"
            editMode="row"
            v-model:editingRows="editingRows"
            @row-edit-save="onRowEditComplete"
            ref="dt"
            dataKey="id"
            :totalRecords="totalRecords"
            :value="products"
            scrollable
            :paginator-template="'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'"
            :rows-per-page-options="[10,20,50,100,200]"
            :current-page-report-template="'{first} to {last} of {totalRecords}'"
            v-model:filters="filters"
            filterDisplay="row"
        >
            <Column
                field="articleNumberNameCombined"
                :sortable="true"
                :header="$t('labels.articleNumber')"
                style="min-width: 13rem"
            >
                <template #filter="{filterModel,filterCallback}">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @keydown.enter="filterCallback()"
                        class="p-column-filter text-xs"
                        :placeholder="$t('labels.articleNumber')"
                    />
                </template>
                <template #body="{data}">
                    <span style="vertical-align: middle"
                        ><a
                            :href="data.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + data.id"
                            target="_blank"
                            >{{data.articleNumber}}</a
                        >
                    </span>
                    <div>{{data.name}}</div>

                    <div class="text-xs" v-if="ekPriceCalculation(data)">
                        {{$t('labels.ekPrice')}}:
                        <b>{{ ekPriceCalculation(data) }} </b>
                    </div>

                    <div class="text-xs" v-if="uvpPriceCalculation(data)">
                        UVP:
                        <b>{{ uvpPriceCalculation(data) }} </b>
                    </div>
                </template>
            </Column>
            <Column
                field="supplySourceArticleNumber"
                sortable
                :header="$t('labels.productAdd.sku')"
                style="min-width: 12rem"
            >
                <template #filter="{filterModel,filterCallback}">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @keydown.enter="filterCallback()"
                        class="p-column-filter text-xs"
                        :placeholder="$t('labels.productAdd.sku')"
                    />
                </template>
                <template #editor="{ data, field }">
                    <InputText
                        v-model="data[field]"
                        class="p-column-filter text-sm"
                        inputClass="w-8rem"
                    />
                </template>
                <template #body="{data}">
                    <b>{{ data.supplySourceArticleNumber}}</b>
                </template>
            </Column>
            <Column
                field="supplySourceName"
                sortable
                style="min-width: 15rem"
                :header="$t('labels.nameFromSupplySource')"
            >
                <template #filter="{filterModel,filterCallback}">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @keydown.enter="filterCallback()"
                        class="p-column-filter text-xs"
                        :placeholder="$t('labels.name')"
                    />
                </template>
                <template #editor="{ data, field }">
                    <InputText
                        v-model="data[field]"
                        class="p-column-filter text-sm"
                        inputClass="w-8rem"
                    />
                </template>
                <template #body="{data}"> {{ data.supplySourceName}} </template>
            </Column>

            <Column
                field="supplierNumberNameCombined"
                sortable
                :header="$t('labels.productAdd.supplier')"
                style="min-width: 8rem"
            >
                <template #filter="{filterModel,filterCallback}">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @keydown.enter="filterCallback()"
                        class="p-column-filter text-xs"
                        :placeholder="$t('labels.name')"
                    />
                </template>
                <template #body="{data}">
                    {{ data.supplierNumber}} {{ data.supplierName}}
                </template>
            </Column>
            <Column
                field="eolTag"
                dataType="boolean"
                bodyClass="text-center"
                header="Nicht in PL vorhanden"
                sortable
            >
                <template #filter="{filterModel, filterCallback}">
                    <div class="w-full flex justify-content-center ml-3">
                        <div>
                            <TriStateCheckbox
                                v-model="filterModel.value"
                                @change="filterCallback()"
                            />
                        </div>
                    </div>
                </template>
                <template #body="{data}">
                    <i
                        class="pi"
                        :class="{'text-green-600 pi-check-circle': data.eolTag, 'text-red-600 pi-times-circle': !(data.eolTag)}"
                    ></i>
                </template>
            </Column>
            <Column
                field="manufacturerName"
                sortable
                style="min-width: 12rem"
                :header="$t('labels.manufacturer')"
            >
                <template #filter="{filterModel,filterCallback}">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @keydown.enter="filterCallback()"
                        class="p-column-filter text-xs"
                        :placeholder="$t('labels.manufacturer')"
                    />
                </template>
                <template #body="{data}">
                    <span style="vertical-align: middle"
                        >{{ data.manufacturerName }}</span
                    >
                </template>
            </Column>
            <Column
                rowEditor
                style="width: 10%; min-width: 5rem"
                bodyStyle="text-align:center"
            ></Column>
        </DataTable>
    </Panel>
</ScrollPanel>
