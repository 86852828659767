import {computed, onMounted, ref, watch} from 'vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Fieldset from 'primevue/fieldset';
import InputNumber from 'primevue/inputnumber';
import Divider from 'primevue/divider';
import Calendar from 'primevue/calendar';
import Dialog from 'primevue/dialog';
import MultiSelect from 'primevue/multiselect';
import {helpers, required, requiredIf} from '@vuelidate/validators';
import {useVuelidate} from '@vuelidate/core';
import {i18n} from '@/utils/i18n';
import {useToast} from 'vue-toastification';
import {updateBasicInfo} from '@/services/products';
import {getAll} from '@/services/metadata';
import Dropdown from 'primevue/dropdown';
import Chips from 'primevue/chips';
import countries from 'i18n-iso-countries';
import {isWeclappVersionColliding} from '@/services/user-activity';
import store from '@/store';
import {stripTagsAndTruncate} from '@/utils/helpers';
import ConfirmLeave from '@/components/dialog/general/confirm-leave.vue';
import router from '@/router';
import {getAllUnits} from '@/services/shopware';
import ScrollPanel from 'primevue/scrollpanel';
import Panel from 'primevue/panel';
import Textarea from 'primevue/textarea';

export default {
    emits: ['reload-product'],
    components: {
        InputText,
        'p-button': Button,
        'app-checkbox': Checkbox,
        'p-fieldset': Fieldset,
        InputNumber,
        Divider,
        'p-calendar': Calendar,
        'p-dialog': Dialog,
        'p-multiselect': MultiSelect,
        'p-dropdown': Dropdown,
        ConfirmLeave: ConfirmLeave,
        Chips,
        ScrollPanel,
        Panel,
        'p-textarea': Textarea
    },
    props: {
        productDetails: Object,
        customAttributesDefinition: Object
    },
    setup(props: any, context: any) {
        const submitted = ref(false);
        const productDetails = ref(null);
        const unitOptions = ref([]);
        const currencyOptions = ref([]);
        const manufacturerOptions = ref([]);
        const shopwareUnitOptions = ref([]);
        const userOptions = ref([]);
        const customsTariffNumberOptions = ref([]);
        const articleCategoryOptions = ref([]);
        const savingInProgress = ref(false);
        const toast = useToast();
        const customAttributesDefinition = ref(null);

        const showCollidingVersionDialog = ref(false);

        const parseIntoDropdownList = (data: any) => {
            return data.map((item: {id: string; name: string}) => {
                return {
                    label: item.name,
                    value: item.id
                };
            });
        };

        const nextRoute = ref(null);
        const displayConfirmLeaveDialog = ref(false);

        /*onBeforeRouteLeave((to: any) => {
            if (v$.value.$anyDirty) {
                displayConfirmLeaveDialog.value = true;
                nextRoute.value = to;
                return false;
            }
        });*/

        const onChoiceConfirmed = (choice: boolean) => {
            if (choice) {
                v$.value.$reset();
                router.push(nextRoute.value);
            } else {
                displayConfirmLeaveDialog.value = false;
            }
        };

        onMounted(() => {
            getAll([
                'unit',
                'currency',
                'manufacturer',
                'customsTariffNumber',
                'user',
                'articleCategory'
            ])
                .then((data) => {
                    if (data.data?.unit) {
                        unitOptions.value = parseIntoDropdownList(
                            data.data.unit
                        );
                    }
                    if (data.data?.currency) {
                        currencyOptions.value = parseIntoDropdownList(
                            data.data.currency
                        );
                    }
                    if (data.data?.user) {
                        userOptions.value = parseIntoDropdownList(
                            data.data.user
                        );
                    }
                    if (data.data?.manufacturer) {
                        manufacturerOptions.value = data.data.manufacturer.map(
                            (item: {id: string; name: string}) => {
                                return {
                                    label: item.name,
                                    value: item.name
                                };
                            }
                        );
                    }
                    if (data.data?.articleCategory) {
                        articleCategoryOptions.value = parseIntoDropdownList(
                            data.data.articleCategory
                        );
                    }

                    if (data.data?.customsTariffNumber) {
                        customsTariffNumberOptions.value =
                            data.data.customsTariffNumber.map(
                                (item: {
                                    id: string;
                                    name: string;
                                    description: string | null;
                                }) => {
                                    return {
                                        label: item.description
                                            ? stripTagsAndTruncate(
                                                  item.description,
                                                  100,
                                                  '...'
                                              ) +
                                              ' ( ' +
                                              item.name +
                                              ' ) '
                                            : item.name,
                                        value: item.id
                                    };
                                }
                            );
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                });

            getAllUnits()
                .then((data) => {
                    shopwareUnitOptions.value = data.data;
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        });

        const weeeOptions = computed(() => {
            return (
                customAttributesDefinition.value?.article_weee_relevant
                    ?.selectableValues || []
            ).map((item: {id: string; value: string}) => {
                return {
                    label: stripTagsAndTruncate(item.value, 100, '...'),
                    value: item.id
                };
            });
        });

        const gemaOptions = computed(() => {
            return (
                customAttributesDefinition.value?.article_gema_relevant
                    ?.selectableValues || []
            ).map((item: {id: string; value: string}) => {
                return {
                    label: stripTagsAndTruncate(item.value, 100, '...'),
                    value: item.id
                };
            });
        });

        const warrantyTimeOptions = computed(() => {
            return (
                customAttributesDefinition.value?.article_garantiezeit
                    ?.selectableValues || []
            ).map((item: {id: string; value: string}) => {
                return {
                    label: stripTagsAndTruncate(item.value, 100, '...'),
                    value: item.id
                };
            });
        });

        const state = ref({
            productName: '',
            productNumber: '',
            ean: '',
            mpn: '',
            manufacturer: '',
            customsTariffNumber: '',
            countryOfOrigin: '',
            articleCategory: '',
            matchCode: null,
            procurementLeadDays: 0,
            minimumStockQuantity: null,
            targetStockQuantity: null,
            priceUponRequest: '0',
            serialNumberRequired: false,
            articleGrossWeight: null,
            articleNetWeight: null,
            articleLength: null,
            articleWidth: null,
            articleHeight: null,
            purchaseUnit: null,
            referenceUnit: null,
            dimensionUnit: null,
            shippingFree: false,
            article_weee_relevant: '',
            article_gema_relevant: '',
            article_batteriegesetz_relevant: false,
            article_ueberlaenge: false,
            article_sperrgut: false,
            article_flag_akku_groesser_100wh: false,
            article_flag_aerosole: false,
            SN_AUTO_GENERATE: false,
            article_metatags: [],
            article_memo: '',
            art_eol_datum: null,
            sellFromDate: null,
            article_flag_akku_kleiner_100wh: false,
            article_markenmehrwert: false,
            article_garantiezeit: '',
            article_comment_delivery_note: '',
            article_sell_out: false,
            availableInShop: false,
            activeInShop: false,
            availableInShop2: false,
            activeInShop2: false,
            article_webshop_VDHname: null,
            article_webshop_VDHarticleNumber: null,
            article_no_pl_match_spare_part: false,
            tags: []
        });

        const rules = {
            productName: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            productNumber: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            ean: {},
            mpn: {},
            manufacturer: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            customsTariffNumber: {},
            countryOfOrigin: {},
            articleCategory: {},
            matchCode: {},
            procurementLeadDays: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return (
                            productDetails.value?.weclapp?.articleType !==
                            'SALES_BILL_OF_MATERIAL'
                        );
                    })
                )
            },
            minimumStockQuantity: {},
            targetStockQuantity: {},
            priceUponRequest: {},
            serialNumberRequired: {},
            articleGrossWeight: {},
            articleNetWeight: {},
            articleLength: {},
            articleWidth: {},
            articleHeight: {},
            purchaseUnit: {},
            referenceUnit: {},
            dimensionUnit: {},
            shippingFree: {},
            article_weee_relevant: {},
            article_gema_relevant: {},
            article_batteriegesetz_relevant: {},
            article_ueberlaenge: {},
            article_sperrgut: {},
            article_flag_akku_groesser_100wh: {},
            article_flag_aerosole: {},
            SN_AUTO_GENERATE: {},
            article_metatags: {},
            article_memo: {},
            art_eol_datum: {},
            sellFromDate: {},
            article_flag_akku_kleiner_100wh: {},
            article_markenmehrwert: {},
            article_no_pl_match_spare_part: {},
            article_garantiezeit: {},
            article_comment_delivery_note: {},
            article_sell_out: {},
            availableInShop: {},
            activeInShop: {},
            availableInShop2: {},
            activeInShop2: {},
            article_webshop_VDHname: {},
            article_webshop_VDHarticleNumber: {},
            tags: {}
        };

        const v$ = useVuelidate(rules, state);

        watch(props, () => {
            if (props.customAttributesDefinition) {
                customAttributesDefinition.value =
                    props.customAttributesDefinition;
            }

            if (!props.productDetails) {
                return;
            }

            productDetails.value = props.productDetails;

            const alternativeQuantities =
                props.productDetails.weclapp?.articleAlternativeQuantities &&
                props.productDetails.weclapp?.articleAlternativeQuantities.find(
                    (item: any) => item.warehouseName === 'Wiesbaden'
                );

            Object.assign(state.value, {
                productNumber: props.productDetails.weclapp?.articleNumber,
                productName: props.productDetails.weclapp?.name,
                ean: props.productDetails.weclapp?.ean,
                mpn: props.productDetails.weclapp?.manufacturerPartNumber,
                procurementLeadDays:
                    props.productDetails.weclapp?.procurementLeadDays,
                minimumStockQuantity:
                    alternativeQuantities?.minimumStockQuantity
                        ? parseInt(alternativeQuantities?.minimumStockQuantity)
                        : null,
                targetStockQuantity: alternativeQuantities?.targetStockQuantity
                    ? parseInt(alternativeQuantities?.targetStockQuantity)
                    : null,
                priceUponRequest: props.productDetails.shopware?.priceOnRequest,
                manufacturer: props.productDetails.weclapp?.manufacturerName,
                countryOfOrigin:
                    props.productDetails.weclapp?.countryOfOriginCode,
                articleCategory:
                    props.productDetails.weclapp?.articleCategoryId,
                matchCode: props.productDetails.weclapp?.matchCode,
                customsTariffNumber:
                    props.productDetails.weclapp?.customsTariffNumberId,
                serialNumberRequired:
                    !!props.productDetails.weclapp?.serialNumberRequired,
                articleGrossWeight:
                    props.productDetails.weclapp?.articleGrossWeight,
                articleNetWeight:
                    props.productDetails.weclapp?.articleNetWeight,
                articleLength: props.productDetails.weclapp?.articleLength
                    ? props.productDetails.weclapp.articleLength * 100
                    : null,
                articleWidth: props.productDetails.weclapp?.articleWidth
                    ? props.productDetails.weclapp.articleWidth * 100
                    : null,
                articleHeight: props.productDetails.weclapp?.articleHeight
                    ? props.productDetails.weclapp.articleHeight * 100
                    : null,
                sellFromDate: props.productDetails.weclapp?.sellFromDate
                    ? new Date(props.productDetails.weclapp.sellFromDate)
                    : null,
                purchaseUnit: props.productDetails.shopware?.purchaseUnit,
                referenceUnit: props.productDetails.shopware?.referenceUnit,
                dimensionUnit: props.productDetails.shopware?.unit?.name,
                shippingFree: props.productDetails.shopware?.shippingFree,
                tags: (props.productDetails.weclapp?.tags ?? []).map(
                    (item: string) =>
                        item.toUpperCase().replace('TTC-PRODUCT-META-', '')
                )
            });

            initializeCustomAttributes();
        });

        const initializeCustomAttributes = () => {
            if (!productDetails.value) {
                return;
            }

            [
                'article_weee_relevant',
                'article_gema_relevant',
                'article_garantiezeit',
                'article_memo',
                'article_comment_delivery_note'
            ].forEach((item) => {
                const temp: {[k: string]: string} = {};
                const attributes =
                    productDetails.value.weclapp?.customAttributes;
                temp[item] = attributes[item] || null;

                Object.assign(state.value, temp);
            });

            ['article_metatags'].forEach((item) => {
                const temp: {[k: string]: string} = {};
                const attributes =
                    productDetails.value.weclapp?.customAttributes;
                temp[item] = attributes[item]
                    ? (attributes[item] || '')
                          .split(',')
                          .map((item: string) => item.trim())
                    : [];

                Object.assign(state.value, temp);
            });

            [
                'article_batteriegesetz_relevant',
                'article_ueberlaenge',
                'article_sperrgut',
                'article_flag_akku_groesser_100wh',
                'article_flag_aerosole',
                'SN_AUTO_GENERATE',
                'article_flag_akku_kleiner_100wh',
                'article_markenmehrwert',
                'article_sell_out',
                'article_no_pl_match_spare_part'
            ].forEach((item) => {
                const temp: {[k: string]: boolean} = {};
                const attributes =
                    productDetails.value.weclapp?.customAttributes;
                temp[item] = !!(attributes[item] || null);
                Object.assign(state.value, temp);
            });

            Object.assign(state.value, {
                availableInShop:
                    productDetails.value.weclapp?.customAttributes
                        ?.article_ecom_new_tt_backend || null,
                activeInShop:
                    productDetails.value.weclapp?.customAttributes
                        ?.article_ecom_new_tt_frontend || null,
                availableInShop2:
                    productDetails.value.weclapp?.customAttributes
                        ?.article_ecom_new_vdh_backend || null,
                activeInShop2:
                    productDetails.value.weclapp?.customAttributes
                        ?.article_ecom_new_vdh_frontend || null,
                article_webshop_VDHname:
                    productDetails.value.weclapp?.customAttributes
                        ?.article_webshop_VDHname || null,
                article_webshop_VDHarticleNumber:
                    productDetails.value.weclapp?.customAttributes
                        ?.article_webshop_VDHarticleNumber || null
            });

            ['art_eol_datum'].forEach((item) => {
                const temp: {[k: string]: Date} = {};
                const attributes =
                    productDetails.value.weclapp?.customAttributes;
                temp[item] = attributes[item]
                    ? new Date(attributes[item])
                    : null;
                Object.assign(state.value, temp);
            });
        };

        const handleSubmit = async (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            savingInProgress.value = true;

            const weclappVesrionCollidingResult =
                await isWeclappVersionColliding(
                    'product',
                    productDetails.value.weclapp?.articleNumber ||
                        productDetails.value.shopware?.productNumber
                );

            try {
                if (weclappVesrionCollidingResult.data) {
                    showCollidingVersionDialog.value = true;
                } else {
                    executeUpdate();
                }
            } catch (error: any) {
                executeUpdate();
            }
        };

        const executeUpdate = () => {
            updateBasicInfo(
                productDetails.value.weclapp?.articleNumber ||
                    productDetails.value.shopware?.productNumber,
                state.value
            )
                .then((data) => {
                    if (data?.data?.shopwareFailed) {
                        toast.warning(
                            i18n.global.t('messages.changesSavedOnlyInWeclapp'),
                            {
                                timeout: 500
                            }
                        );
                    } else {
                        toast.success(
                            i18n.global.t('messages.changesSavedSuccessfully'),
                            {
                                timeout: 500
                            }
                        );
                    }
                    v$.value.$reset();
                    context.emit('reload-product');
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    savingInProgress.value = false;
                });
        };

        const setDropdownValue = (fieldName: string, event: any) => {
            const temp: {[k: string]: string} = {};
            temp[fieldName] = event.value;
            Object.assign(state.value, temp);
        };

        const tagsPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'];
            return user?.permissions?.indexOf('tags-edit') !== -1;
        });

        const countryList =
            countries.getNames(i18n.global.locale) || countries.getNames('en');

        const cancelSavingHandler = () => {
            showCollidingVersionDialog.value = false;
            savingInProgress.value = false;
        };

        const platform = computed(() => {
            const currentPlatform = store.getters['auth/platform'];
            const platforms = store.getters['auth/platforms'];
            return platforms.find(
                (item: any) => item.value === currentPlatform
            );
        });

        const alreadyAvailableInShop = computed(() => {
            return (
                (productDetails.value &&
                    productDetails.value.weclapp?.customAttributes
                        ?.article_ecom_new_tt_backend) ||
                false
            );
        });

        const alreadyAvailableInShop2 = computed(() => {
            return (
                (productDetails.value &&
                    productDetails.value.weclapp?.customAttributes
                        ?.article_ecom_new_vdh_backend) ||
                false
            );
        });

        const computedCurrency = computed(() => {
            return productDetails.value &&
                productDetails.value.weclapp?.articlePrices &&
                productDetails.value.weclapp?.articlePrices.length > 0
                ? productDetails.value.weclapp?.articlePrices[0]?.currencyId
                : '253';
        });

        const copyToClipboard = (data: any, event: any) => {
            navigator.clipboard.writeText(data);
            event.stopPropagation();
            toast.success(i18n.global.t('labels.copied'), {
                timeout: 500
            });
        };

        return {
            state,
            v$,
            handleSubmit,
            submitted,
            savingInProgress,
            productTypeOptions: [
                {
                    value: 'STORABLE',
                    label: i18n.global.t(
                        'labels.productHome.productTypeOptions.storable'
                    )
                },
                {
                    value: 'BASIC',
                    label: i18n.global.t(
                        'labels.productHome.productTypeOptions.basic'
                    )
                },
                {
                    value: 'SERVICE',
                    label: i18n.global.t(
                        'labels.productHome.productTypeOptions.service'
                    )
                },
                {
                    value: 'SALES_BILL_OF_MATERIAL',
                    label: i18n.global.t(
                        'labels.productHome.productTypeOptions.salesBillOfMaterial'
                    )
                },
                {
                    value: 'SHIPPING_COST',
                    label: i18n.global.t(
                        'labels.productHome.productTypeOptions.shippingCost'
                    )
                }
            ],
            priceUponRequestOptions: [
                {
                    value: '0',
                    label: i18n.global.t('labels.productHome.deactivated')
                },
                {
                    value: '1',
                    label: i18n.global.t('labels.productHome.activated')
                },
                {
                    value: '2',
                    label: i18n.global.t(
                        'labels.productHome.individualPriceUponRequest'
                    )
                }
            ],
            taxRateTypeOptions: [
                'STANDARD',
                'REDUCED',
                'ZERO',
                'SLIGHTLY_REDUCED',
                'SUPER_REDUCED'
            ].map((item: string) => {
                return {
                    value: item,
                    label: i18n.global.t(
                        'labels.productAdd.taxRateTypeOptions.' + item
                    )
                };
            }),
            unitOptions,
            currencyOptions,
            manufacturerOptions,
            userOptions,
            customsTariffNumberOptions,
            articleCategoryOptions,
            weeeOptions,
            gemaOptions,
            warrantyTimeOptions,
            shopwareUnitOptions,
            countryOptions: Object.entries(countryList).map((item) => {
                return {
                    label: item[1],
                    value: item[0]
                };
            }),
            locale: i18n.global.locale,
            setDropdownValue,
            showCollidingVersionDialog,
            cancelSavingHandler,
            executeUpdate,
            platform,
            alreadyAvailableInShop,
            alreadyAvailableInShop2,
            productDetails,
            computedCurrency,
            displayConfirmLeaveDialog,
            onChoiceConfirmed,
            copyToClipboard,
            tagsPermissionAvailable,
            customAttributesDefinition
        };
    }
};
