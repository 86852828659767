import Dialog from 'primevue/dialog';
import {onMounted, ref, watch} from 'vue';
import Button from 'primevue/button';
import {helpers, required} from '@vuelidate/validators';
import {useVuelidate} from '@vuelidate/core';
import {i18n} from '@/utils/i18n';
import {useToast} from 'vue-toastification';
import Dropdown from 'primevue/dropdown';
import {getSpreadsheets} from '@/services/import';
import {getAll} from '@/services/metadata';
import LoadingPlugin from 'vue-loading-overlay';
import {getAllTemplates} from '@/services/price-import';
import Calendar from 'primevue/calendar';
import Checkbox from 'primevue/checkbox';

export default {
    emits: ['close-dialog'],
    components: {
        'p-dialog': Dialog,
        'p-dropdown': Dropdown,
        'p-button': Button,
        'p-calendar': Calendar,
        'p-checkbox': Checkbox,
        LoadingPlugin
    },
    props: {
        displayResponsive: Boolean,
        files: {
            type: Array<string>,
            default: []
        },
        chosenSupplier: {
            type: String,
            default: null
        },
        partialPriceList: {
            type: Boolean,
            default: false
        }
    },
    setup(props: any, context: any) {
        const showDialog = ref(false);
        const submitted = ref(false);
        const uploadedFiles = ref([]);
        const toast = useToast();
        const manufacturerOptions = ref([]);
        const spreadsheets = ref([]);
        const loading = ref(false);
        const chosenSupplier = ref(null);
        const importTemplates = ref([]);
        const partialPriceList = ref(false);

        const state = ref({
            sheetName: null,
            manufacturer: null,
            uvpStartDate: new Date(),
            ekStartDate: new Date()
        });

        const rules = {
            sheetName: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            manufacturer: {},
            uvpStartDate: {},
            ekStartDate: {}
        };

        const v$ = useVuelidate(rules, state);

        onMounted(() => {
            getAll(['manufacturer'], false)
                .then((data: any) => {
                    manufacturerOptions.value = data.data?.manufacturer || [];
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                });
        });

        watch(
            () => props.displayResponsive,
            (val) => {
                showDialog.value = val;
            }
        );

        watch(
            () => props.partialPriceList,
            (val) => {
                partialPriceList.value = val;
            }
        );

        watch(
            () => props.chosenSupplier,
            (val) => {
                chosenSupplier.value = val;
                resetForm();
            }
        );

        watch(
            () => props.files,
            (val) => {
                if (val.length > 0) {
                    if (
                        val.length !== uploadedFiles.value.length ||
                        val[0].path !== uploadedFiles.value[0].path
                    ) {
                        uploadedFiles.value = val;
                        loading.value = true;
                        getAllTemplates({
                            supplierNumber: chosenSupplier.value
                        })
                            .then((templateData: any) => {
                                importTemplates.value = templateData.data;
                                getSpreadsheets(uploadedFiles.value)
                                    .then((data: any) => {
                                        spreadsheets.value = data;
                                        const resolved = resolveSavedTemplate();

                                        if (spreadsheets.value.length === 1) {
                                            state.value.sheetName =
                                                spreadsheets.value[0];
                                        } else if (resolved) {
                                            state.value.sheetName =
                                                resolved.sheetName;
                                        }

                                        if (resolved?.manufacturer) {
                                            state.value.manufacturer =
                                                manufacturerOptions.value.find(
                                                    (opt) =>
                                                        opt.id ===
                                                        resolved.manufacturer
                                                );
                                        }
                                    })
                                    .catch((error) => {
                                        toast.error(
                                            error.response?.data?.error ||
                                                error.message
                                        );
                                    })
                                    .finally(() => {
                                        loading.value = false;
                                    });
                            })
                            .catch((error) => {
                                toast.error(
                                    error.response?.data?.error || error.message
                                );
                                loading.value = false;
                            });
                    }
                } else {
                    uploadedFiles.value.length = 0;
                }
            }
        );

        const closeDialog = () => {
            resetForm();
        };

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            context.emit(
                'close-dialog',
                state.value.sheetName,
                state.value.manufacturer,
                state.value.ekStartDate,
                state.value.uvpStartDate,
                partialPriceList.value
            );
        };

        const resetForm = () => {
            state.value.sheetName = null;
            state.value.manufacturer = null;
            state.value.uvpStartDate = new Date();
            state.value.ekStartDate = new Date();
            submitted.value = false;
        };

        const onSpreadsheetChange = (event: any) => {
            if (event?.value) {
                const resolved = resolveSavedTemplate(event.value);

                if (resolved?.sheetName) {
                    state.value.sheetName = resolved.sheetName;
                }

                if (resolved?.manufacturer) {
                    state.value.manufacturer = manufacturerOptions.value.find(
                        (opt) => opt.id === resolved.manufacturer
                    );
                }
            }
        };

        const resolveSavedTemplate = (
            sheetName: string | null = null
        ): {
            public: boolean;
            sheetName: string | null;
            manufacturer: string | null;
        } | null => {
            const filteredPersonal = (importTemplates.value || []).filter(
                (item: {public: boolean; sheetName: string | null}) => {
                    return (
                        !item.public &&
                        (sheetName === null || item.sheetName === sheetName)
                    );
                }
            );
            if (filteredPersonal.length === 1) {
                return filteredPersonal[0];
            }

            const filteredPublic = (importTemplates.value || []).filter(
                (item: {public: boolean; sheetName: string | null}) => {
                    return (
                        item.public &&
                        (sheetName === null || item.sheetName === sheetName)
                    );
                }
            );

            return filteredPublic.length === 1 ? filteredPublic[0] : null;
        };

        return {
            showDialog,
            closeDialog,
            state,
            v$,
            handleSubmit,
            submitted,
            locale: i18n.global.locale,
            spreadsheets,
            manufacturerOptions,
            loading,
            onSpreadsheetChange,
            partialPriceList
        };
    }
};
