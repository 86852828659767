import {IUser} from '@/interfaces/user';
import AppMenuItem from '@/components/menu-item/menu-item.vue';

export default {
    name: 'app-menu-sidebar',
    components: {
        AppMenuItem
    },

    setup() {
        return {
            menu: [
                {
                    name: '',
                    children: [
                        {
                            name: 'labels.import',
                            iconClass: ['fas', 'upload'],
                            isVisible: (user: IUser): boolean => {
                                return (
                                    !user ||
                                    user.permissions.indexOf('imports-view') !==
                                        -1 ||
                                    user.permissions.indexOf('imports-add') !==
                                        -1 ||
                                    user.permissions.indexOf(
                                        'shop-orders-import-view'
                                    ) !== -1
                                );
                            },
                            children: [
                                {
                                    name: 'labels.wizard',
                                    path: '/',
                                    iconClass: ['fas', 'hat-wizard'],
                                    isVisible: (user: IUser): boolean => {
                                        return (
                                            !user ||
                                            user.permissions.indexOf(
                                                'imports-add'
                                            ) !== -1
                                        );
                                    }
                                },
                                {
                                    name: 'labels.importLog',
                                    path: '/import-logs',
                                    iconClass: ['fas', 'list'],
                                    isVisible: (user: IUser): boolean => {
                                        return (
                                            !user ||
                                            user.permissions.indexOf(
                                                'imports-view'
                                            ) !== -1
                                        );
                                    }
                                },
                                {
                                    name: 'labels.shopOrders',
                                    path: '/import-shop-orders',
                                    iconClass: ['fas', 'cart-shopping'],
                                    isVisible: (user: IUser): boolean => {
                                        return (
                                            !user ||
                                            user.permissions.indexOf(
                                                'shop-orders-import-view'
                                            ) !== -1
                                        );
                                    }
                                }
                            ]
                        },
                        {
                            name: 'labels.export',
                            iconClass: ['fas', 'download'],
                            path: '/export-wizard',
                            isVisible: (
                                user: IUser,
                                platform: {
                                    label: string;
                                    value: string;
                                    threeLetterId?: string;
                                } | null
                            ): boolean => {
                                return (
                                    (platform?.threeLetterId === 'ttl' ||
                                        platform?.threeLetterId === 'ttd') &&
                                    (!user ||
                                        user.permissions.indexOf(
                                            'products-view'
                                        ) !== -1)
                                );
                            }
                        },
                        {
                            name: 'labels.products',
                            iconClass: ['fas', 'tags'],
                            isVisible: (user: IUser): boolean => {
                                return (
                                    !user ||
                                    user.permissions.indexOf(
                                        'products-view'
                                    ) !== -1
                                );
                            },
                            children: [
                                {
                                    name: 'labels.list',
                                    path: '/products',
                                    iconClass: 'fa-list',
                                    isVisible: (user: IUser): boolean => {
                                        return (
                                            !user ||
                                            user.permissions.indexOf(
                                                'products-view'
                                            ) !== -1
                                        );
                                    }
                                },
                                {
                                    name: 'labels.webshopContent',
                                    path: '/products-webshop-view',
                                    iconClass: 'fa-photo-film',
                                    isVisible: (user: IUser): boolean => {
                                        return (
                                            !user ||
                                            user.permissions.indexOf(
                                                'products-view'
                                            ) !== -1
                                        );
                                    }
                                },
                                {
                                    name: 'labels.addNewProduct',
                                    path: '/products-add-wizard',
                                    iconClass: 'fa-plus',
                                    isVisible: (user: IUser): boolean => {
                                        return (
                                            !user ||
                                            user.permissions.indexOf(
                                                'products-edit'
                                            ) !== -1
                                        );
                                    }
                                },
                                {
                                    name: 'labels.usedProducts',
                                    path: '/used-products',
                                    iconClass: 'fa-box-open',
                                    isVisible: (user: IUser): boolean => {
                                        return (
                                            !user ||
                                            user.permissions.indexOf(
                                                'products-view'
                                            ) !== -1
                                        );
                                    }
                                },
                                {
                                    name: 'labels.addNewUsedProduct',
                                    path: '/products-add-used-wizard',
                                    iconClass: 'fa-plus',
                                    isVisible: (user: IUser): boolean => {
                                        return (
                                            !user ||
                                            user.permissions.indexOf(
                                                'products-edit'
                                            ) !== -1
                                        );
                                    }
                                },
                                {
                                    name: 'labels.incompleteMeasures',
                                    path: '/products-with-incomplete-dimensions',
                                    iconClass: 'fa-ruler',
                                    isVisible: (user: IUser): boolean => {
                                        return (
                                            !user ||
                                            user.permissions.indexOf(
                                                'products-edit'
                                            ) !== -1
                                        );
                                    }
                                },
                                {
                                    name: 'labels.deliveryTime',
                                    path: '/products-delivery-time',
                                    iconClass: 'fa-business-time',
                                    isVisible: (user: IUser): boolean => {
                                        return (
                                            !user ||
                                            user.permissions.indexOf(
                                                'products-edit'
                                            ) !== -1
                                        );
                                    }
                                },
                                {
                                    name: 'labels.salesOrder.disposition',
                                    path: '/products/stock-quantity',
                                    iconClass: 'fa-wave-square',
                                    isVisible: (user: IUser): boolean => {
                                        return (
                                            !user ||
                                            user.permissions.indexOf(
                                                'products-edit'
                                            ) !== -1
                                        );
                                    }
                                },
                                {
                                    name: 'labels.skuMaintenance',
                                    path: '/sku-maintenance',
                                    iconClass: 'fa-spell-check',
                                    isVisible: (user: IUser): boolean => {
                                        return (
                                            !user ||
                                            user.permissions.indexOf(
                                                'products-edit'
                                            ) !== -1
                                        );
                                    }
                                },
                                {
                                    name: 'labels.warehouseStockBPM',
                                    path: '/warehouse-stock-bpm',
                                    iconClass: 'fa-warehouse',
                                    isVisible: (
                                        user: IUser,
                                        platform: {
                                            label: string;
                                            value: string;
                                            threeLetterId?: string;
                                        } | null
                                    ): boolean => {
                                        return (
                                            (platform?.threeLetterId ===
                                                'ttl' ||
                                                platform?.threeLetterId ===
                                                    'ttd') &&
                                            (!user ||
                                                user.permissions.indexOf(
                                                    'weclapp-purchase-orders-view'
                                                ) !== -1)
                                        );
                                    }
                                },
                                {
                                    name: 'labels.shopDataSync',
                                    path: '/product-shop-data-sync',
                                    iconClass: 'fa-rotate',
                                    isVisible: (
                                        user: IUser,
                                        platform: {
                                            label: string;
                                            value: string;
                                            threeLetterId?: string;
                                        } | null
                                    ): boolean => {
                                        return (
                                            (platform?.threeLetterId ===
                                                'ttl' ||
                                                platform?.threeLetterId ===
                                                    'ttd') &&
                                            (!user ||
                                                user.permissions.indexOf(
                                                    'products-edit'
                                                ) !== -1)
                                        );
                                    }
                                }
                            ]
                        },
                        {
                            name: 'labels.prices',
                            iconClass: 'fa-dollar-sign',
                            isVisible: (
                                user: IUser,
                                platform: {
                                    label: string;
                                    value: string;
                                    threeLetterId?: string;
                                } | null
                            ): boolean => {
                                return (
                                    (platform?.threeLetterId === 'ttl' ||
                                        platform?.threeLetterId === 'ttd') &&
                                    (!user ||
                                        user.permissions.indexOf(
                                            'price-imports-view'
                                        ) !== -1)
                                );
                            },
                            children: [
                                {
                                    name: 'labels.supplierConfiguration',
                                    path: '/price-supplier-configuration',
                                    iconClass: 'fa-gear',
                                    isVisible: (user: IUser): boolean => {
                                        return (
                                            !user ||
                                            user.permissions.indexOf(
                                                'price-imports-view'
                                            ) !== -1
                                        );
                                    }
                                },
                                {
                                    name: 'labels.importLog',
                                    path: '/price-import-logs',
                                    iconClass: 'fa-list',
                                    isVisible: (user: IUser): boolean => {
                                        return (
                                            !user ||
                                            user.permissions.indexOf(
                                                'price-imports-view'
                                            ) !== -1
                                        );
                                    }
                                },
                                {
                                    name: 'labels.wizard',
                                    path: '/price-import-wizard',
                                    iconClass: ['fas', 'upload'],
                                    isVisible: (user: IUser): boolean => {
                                        return (
                                            !user ||
                                            user.permissions.indexOf(
                                                'price-imports-add'
                                            ) !== -1
                                        );
                                    }
                                }
                            ]
                        },
                        {
                            name: 'labels.tickets',
                            path: '/tickets',
                            iconClass: 'fa-comment-dots',
                            isVisible: (
                                user: IUser,
                                platform: {
                                    label: string;
                                    value: string;
                                    threeLetterId?: string;
                                } | null
                            ): boolean => {
                                return (
                                    (platform?.threeLetterId === 'ttl' ||
                                        platform?.threeLetterId === 'ttd') &&
                                    (!user ||
                                        user.permissions.indexOf(
                                            'weclapp-tickets-view'
                                        ) !== -1)
                                );
                            }
                        },
                        {
                            name: 'labels.incomingGoods',
                            path: '/incoming-goods',
                            iconClass: 'fa-warehouse',
                            isVisible: (
                                user: IUser,
                                platform: {
                                    label: string;
                                    value: string;
                                    threeLetterId?: string;
                                } | null
                            ): boolean => {
                                return (
                                    platform?.threeLetterId === 'ttd' &&
                                    (!user ||
                                        user.permissions.indexOf(
                                            'weclapp-incoming-goods-view'
                                        ) !== -1)
                                );
                            }
                        },
                        {
                            name: 'labels.purchaseOrders',
                            path: '/purchase-orders',
                            iconClass: ['fas', 'basket-shopping'],
                            isVisible: (
                                user: IUser,
                                platform: {
                                    label: string;
                                    value: string;
                                    threeLetterId?: string;
                                } | null
                            ): boolean => {
                                return (
                                    (platform?.threeLetterId === 'ttl' ||
                                        platform?.threeLetterId === 'ttd') &&
                                    (!user ||
                                        user.permissions.indexOf(
                                            'weclapp-purchase-orders-view'
                                        ) !== -1)
                                );
                            }
                        },

                        {
                            name: 'labels.shipments',
                            iconClass: 'fa-truck',
                            isVisible: (
                                user: IUser,
                                platform: {
                                    label: string;
                                    value: string;
                                    threeLetterId?: string;
                                } | null
                            ): boolean => {
                                return (
                                    (platform?.threeLetterId === 'ttl' ||
                                        platform?.threeLetterId === 'ttd') &&
                                    (!user ||
                                        user.permissions.indexOf(
                                            'weclapp-shipments-view'
                                        ) !== -1)
                                );
                            },
                            children: [
                                {
                                    name: 'labels.list',
                                    path: '/shipments',
                                    iconClass: 'fa-list',
                                    isVisible: (user: IUser): boolean => {
                                        return (
                                            !user ||
                                            user.permissions.indexOf(
                                                'weclapp-shipments-view'
                                            ) !== -1
                                        );
                                    }
                                },
                                {
                                    name: 'labels.shipment.openShipments',
                                    path: '/open-shipments',
                                    iconClass: 'fa-bell',
                                    isVisible: (user: IUser): boolean => {
                                        return (
                                            !user ||
                                            user.permissions.indexOf(
                                                'weclapp-shipments-view'
                                            ) !== -1
                                        );
                                    }
                                },
                                {
                                    name: 'labels.shipment.shipmentMask',
                                    path: '/shipments/outgoing',
                                    iconClass: 'fa-marker',
                                    isVisible: (user: IUser): boolean => {
                                        return (
                                            !user ||
                                            user.permissions.indexOf(
                                                'weclapp-shipments-edit'
                                            ) !== -1
                                        );
                                    }
                                },
                                {
                                    name: 'labels.shipment.headlessShipments',
                                    path: '/shipments/headless',
                                    iconClass: 'fa-gift',
                                    labelClass: 'text-sm',
                                    isVisible: (user: IUser): boolean => {
                                        return (
                                            !user ||
                                            user.permissions.indexOf(
                                                'headless-shipments-edit'
                                            ) !== -1
                                        );
                                    }
                                }
                            ]
                        },
                        {
                            name: 'labels.manufacturers',
                            iconClass: 'fa-microchip',
                            isVisible: (user: IUser): boolean => {
                                return (
                                    !user ||
                                    user.permissions.indexOf(
                                        'manufacturer-data-view'
                                    ) !== -1
                                );
                            },
                            children: [
                                {
                                    name: 'labels.addresses',
                                    path: '/manufacturers',
                                    iconClass: 'fa-list',
                                    isVisible: (user: IUser): boolean => {
                                        return (
                                            !user ||
                                            user.permissions.indexOf(
                                                'manufacturer-data-view'
                                            ) !== -1
                                        );
                                    }
                                },
                                {
                                    name: 'labels.responsiblePersons',
                                    path: '/manufacturer-responsible-persons',
                                    iconClass: 'fa-address-card',
                                    isVisible: (user: IUser): boolean => {
                                        return (
                                            !user ||
                                            user.permissions.indexOf(
                                                'manufacturer-data-view'
                                            ) !== -1
                                        );
                                    }
                                }
                            ]
                        },
                        {
                            name: 'labels.users',
                            path: '/users',
                            iconClass: 'fa-user',
                            isVisible: (user: IUser): boolean => {
                                return (
                                    !user ||
                                    user.permissions.indexOf('users-view') !==
                                        -1
                                );
                            }
                        },
                        {
                            name: 'labels.tools',
                            iconClass: 'fa-toolbox',
                            isVisible: (user: IUser): boolean => {
                                return (
                                    !user ||
                                    user.permissions.indexOf(
                                        'cron-jobs-view'
                                    ) !== -1 ||
                                    user.permissions.indexOf(
                                        'products-edit'
                                    ) !== -1 ||
                                    user.permissions.indexOf(
                                        'net-rivals-view'
                                    ) !== -1
                                );
                            },
                            children: [
                                {
                                    name: 'labels.cronjobs',
                                    path: '/jobs/crons',
                                    iconClass: 'fa-clock',
                                    isVisible: (user: IUser): boolean => {
                                        return (
                                            !user ||
                                            user.permissions.indexOf(
                                                'cron-jobs-view'
                                            ) !== -1
                                        );
                                    }
                                },
                                {
                                    name: 'labels.one-times',
                                    path: '/jobs/one-time',
                                    iconClass: 'fa-play',
                                    isVisible: (user: IUser): boolean => {
                                        return (
                                            !user ||
                                            user.permissions.indexOf(
                                                'products-edit'
                                            ) !== -1
                                        );
                                    }
                                },
                                {
                                    name: 'labels.notifications',
                                    path: '/jobs/email-notifications',
                                    iconClass: 'fa-envelope',
                                    isVisible: (user: IUser): boolean => {
                                        return (
                                            !user ||
                                            user.permissions.indexOf(
                                                'cron-jobs-view'
                                            ) !== -1
                                        );
                                    }
                                },
                                {
                                    name: 'labels.alerts',
                                    path: '/jobs/email-alerts',
                                    iconClass: 'fa-bell',
                                    isVisible: (user: IUser): boolean => {
                                        return (
                                            !user ||
                                            user.permissions.indexOf(
                                                'cron-jobs-view'
                                            ) !== -1
                                        );
                                    }
                                },
                                {
                                    name: 'labels.netRivals',
                                    path: '/net-rivals',
                                    iconClass: 'fa-chart-line',
                                    isVisible: (user: IUser): boolean => {
                                        return (
                                            !user ||
                                            user.permissions.indexOf(
                                                'net-rivals-view'
                                            ) !== -1
                                        );
                                    }
                                },
                                {
                                    name: 'labels.workflows.title',
                                    path: '/jobs/workflows',
                                    iconClass: 'fa-code-fork',
                                    isVisible: (user: IUser): boolean => {
                                        return (
                                            !user ||
                                            user.permissions.indexOf(
                                                'cron-jobs-view'
                                            ) !== -1
                                        );
                                    }
                                }
                            ]
                        },
                        {
                            name: 'labels.demoPool',
                            iconPath: 'demo.png',
                            isVisible: (
                                user: IUser,
                                platform: {
                                    label: string;
                                    value: string;
                                    threeLetterId?: string;
                                } | null
                            ): boolean => {
                                return (
                                    (platform?.threeLetterId === 'ttl' ||
                                        platform?.threeLetterId === 'ttd') &&
                                    (!user ||
                                        user.permissions.indexOf(
                                            'weclapp-rentals-view'
                                        ) !== -1)
                                );
                            },
                            children: [
                                {
                                    name: 'labels.rentalBoard',
                                    path: '/products/rental-board',
                                    iconClass: 'fa-calendar-alt',
                                    isVisible: (
                                        user: IUser,
                                        platform: {
                                            label: string;
                                            value: string;
                                            threeLetterId?: string;
                                        } | null
                                    ): boolean => {
                                        return (
                                            (platform?.threeLetterId ===
                                                'ttl' ||
                                                platform?.threeLetterId ===
                                                    'ttd') &&
                                            (!user ||
                                                user.permissions.indexOf(
                                                    'weclapp-rentals-view'
                                                ) !== -1)
                                        );
                                    }
                                }
                            ]
                        },
                        {
                            name: 'labels.printConfigurations',
                            path: '/print-configurations',
                            iconClass: 'fa-print',
                            isVisible: (
                                user: IUser,
                                platform: {
                                    label: string;
                                    value: string;
                                    threeLetterId?: string;
                                } | null
                            ): boolean => {
                                return (
                                    (platform?.threeLetterId === 'ttl' ||
                                        platform?.threeLetterId === 'ttd') &&
                                    (!user ||
                                        user.permissions.indexOf(
                                            'print-configurations-view'
                                        ) !== -1)
                                );
                            }
                        },

                        {
                            name: 'labels.systemLog',
                            path: '/system-logs',
                            iconClass: 'fa-history',
                            isVisible: (user: IUser): boolean => {
                                return (
                                    !user ||
                                    user.permissions.indexOf(
                                        'system-logs-view'
                                    ) !== -1
                                );
                            }
                        },
                        {
                            name: 'labels.accessLog',
                            path: '/access-logs',
                            iconClass: 'fa-arrow-down-9-1',
                            isVisible: (user: IUser): boolean => {
                                return (
                                    !user ||
                                    user.permissions.indexOf(
                                        'system-logs-view'
                                    ) !== -1
                                );
                            }
                        }
                    ]
                }
            ]
        };
    }
};
