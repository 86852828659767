import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"vertical-align":"middle"} }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "font-semibold" }
const _hoisted_4 = { class: "font-semibold" }
const _hoisted_5 = { style: {"text-transform":"uppercase"} }
const _hoisted_6 = {
  key: 1,
  class: "text-xs"
}
const _hoisted_7 = { class: "font-semibold" }
const _hoisted_8 = { class: "w-full text-center" }
const _hoisted_9 = { class: "w-full text-center" }
const _hoisted_10 = { class: "grid" }
const _hoisted_11 = { class: "col w-full text-center" }
const _hoisted_12 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_13 = {
  key: 1,
  class: "mt-2"
}
const _hoisted_14 = { class: "col w-full text-center" }
const _hoisted_15 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_16 = {
  key: 1,
  class: "mt-2"
}

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_InputText = _resolveComponent("InputText")
  const _component_Column = _resolveComponent("Column")
  const _component_Tag = _resolveComponent("Tag")
  const _component_TriStateCheckbox = _resolveComponent("TriStateCheckbox")
  const _component_ChangeSet = _resolveComponent("ChangeSet")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_Panel = _resolveComponent("Panel")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, { header: "Protokolle der Artikelsynchronisation" }, {
      default: _withCtx(() => [
        _createVNode(_component_DataTable, {
          class: "p-datatable-sm text-sm",
          stripedRows: "",
          paginator: "",
          rows: 10,
          scrollable: "",
          scrollHeight: "calc(100vh - 23rem)",
          lazy: "",
          ref: "dt",
          dataKey: "id",
          totalRecords: _ctx.totalRecords,
          value: _ctx.logs,
          responsiveLayout: "scroll",
          "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
          "rows-per-page-options": [10,20,50],
          "current-page-report-template": "{first} to {last} of {totalRecords}",
          filters: _ctx.filters,
          "onUpdate:filters": _cache[1] || (_cache[1] = $event => ((_ctx.filters) = $event)),
          filterDisplay: "row",
          onPage: _cache[2] || (_cache[2] = $event => (_ctx.loadLazyData($event))),
          onFilter: _cache[3] || (_cache[3] = $event => (_ctx.loadLazyData($event))),
          onSort: _cache[4] || (_cache[4] = $event => (_ctx.loadLazyData($event)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "productNumber",
              header: _ctx.$t('labels.articleNumber'),
              sortable: ""
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-sm"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("span", _hoisted_1, [
                  _createElementVNode("a", {
                    href: data.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + data.weclappId,
                    target: "_blank"
                  }, _toDisplayString(data.productNumber), 9, _hoisted_2),
                  _cache[5] || (_cache[5] = _createTextVNode(" [")),
                  _createElementVNode("span", _hoisted_3, _toDisplayString(data.weclappName), 1),
                  _cache[6] || (_cache[6] = _createTextVNode("]"))
                ])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "status",
              header: _ctx.$t('labels.status'),
              sortable: "",
              filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
            }, {
              body: _withCtx(({data}) => [
                data
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      (!data?.processingStartedAt)
                        ? (_openBlock(), _createBlock(_component_Tag, {
                            key: 0,
                            severity: "warning"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", null, _toDisplayString(_ctx.$t('labels.scheduled')), 1)
                            ]),
                            _: 1
                          }))
                        : (!data?.processingEndedAt)
                          ? (_openBlock(), _createBlock(_component_Tag, {
                              key: 1,
                              severity: "info"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", null, _toDisplayString(_ctx.$t('labels.inProgress')), 1)
                              ]),
                              _: 1
                            }))
                          : (_openBlock(), _createBlock(_component_Tag, {
                              key: 2,
                              severity: "success"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", null, _toDisplayString(_ctx.$t('labels.done')), 1)
                              ]),
                              _: 1
                            }))
                    ], 64))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "createdAt",
              sortable: "",
              header: _ctx.$t('labels.initiatedAt')
            }, {
              body: _withCtx(({data}) => [
                _createElementVNode("div", null, [
                  _createElementVNode("span", _hoisted_4, _toDisplayString(data.createdAt), 1)
                ]),
                _createElementVNode("div", null, [
                  (data.isAutomatic)
                    ? (_openBlock(), _createBlock(_component_Tag, {
                        key: 0,
                        severity: "primary"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('labels.mail.automatic')), 1)
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createElementBlock("span", _hoisted_6, [
                        _createTextVNode(_toDisplayString(_ctx.$t('labels.initiatedBy')) + " ", 1),
                        _createElementVNode("span", _hoisted_7, _toDisplayString(data.initiatedBy), 1)
                      ]))
                ])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, { field: "changeSet" }, {
              header: _withCtx(() => [
                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('labels.changeSet')), 1)
              ]),
              filter: _withCtx(({filterModel, filterCallback}) => [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_TriStateCheckbox, {
                    modelValue: filterModel.value,
                    "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                    onChange: $event => (filterCallback())
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                ])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _cache[7] || (_cache[7] = _createElementVNode("span", { class: "sales-channel-dot w-1rem h-1rem mr-1 sales-channel-teltec" }, null, -1)),
                    (Object.keys(JSON.parse(data.changeSet || '{}')).includes('teltec'))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                          _createVNode(_component_ChangeSet, {
                            changeSet: JSON.parse(data.changeSet)['teltec']
                          }, null, 8, ["changeSet"])
                        ]))
                      : (Object.keys(JSON.parse(data.changeSet || '{}')).includes('teltec-demo'))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                            _createVNode(_component_ChangeSet, {
                              changeSet: JSON.parse(data.changeSet)['teltec-demo']
                            }, null, 8, ["changeSet"])
                          ]))
                        : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _cache[8] || (_cache[8] = _createElementVNode("span", { class: "sales-channel-dot w-1rem h-1rem mr-1 sales-channel-videodata" }, null, -1)),
                    (Object.keys(JSON.parse(data.changeSet || '{}')).includes('videodata'))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                          _createVNode(_component_ChangeSet, {
                            changeSet: JSON.parse(data.changeSet)['videodata']
                          }, null, 8, ["changeSet"])
                        ]))
                      : (Object.keys(JSON.parse(data.changeSet || '{}')).includes('videodata-demo'))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                            _createVNode(_component_ChangeSet, {
                              changeSet: JSON.parse(data.changeSet)['videodata-demo']
                            }, null, 8, ["changeSet"])
                          ]))
                        : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["totalRecords", "value", "filters"])
      ]),
      _: 1
    })
  ], 64))
}