import {stripTagsAndTruncate} from '@/utils/helpers';
import {toRef, watch} from 'vue';

export default {
    name: 'SyncLogsChangeset',
    props: {
        changeSet: Object
    },

    setup(props: any) {
        const changeSet = toRef(props.changeSet);
        watch(
            () => props.changeSet,
            (val) => {
                changeSet.value = val;
            }
        );

        return {
            changeSet,
            stripTagsAndTruncate: stripTagsAndTruncate
        };
    }
};
