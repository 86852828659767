import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid formgrid p-fluid" }
const _hoisted_2 = { class: "field col" }
const _hoisted_3 = { class: "grid formgrid p-fluid" }
const _hoisted_4 = { class: "field col" }
const _hoisted_5 = { class: "formgrid grid p-fluid" }
const _hoisted_6 = { class: "field col" }
const _hoisted_7 = { class: "field col" }
const _hoisted_8 = {
  key: 0,
  class: "grid formgrid p-fluid"
}
const _hoisted_9 = { class: "field col" }
const _hoisted_10 = {
  key: 1,
  class: "grid formgrid p-fluid"
}
const _hoisted_11 = { class: "field col" }
const _hoisted_12 = { class: "flex justify-content-end" }

export function render(_ctx, _cache) {
  const _component_InputText = _resolveComponent("InputText")
  const _component_InputNumber = _resolveComponent("InputNumber")
  const _component_p_radio = _resolveComponent("p-radio")
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_p_button = _resolveComponent("p-button")
  const _component_p_dialog = _resolveComponent("p-dialog")

  return (_openBlock(), _createBlock(_component_p_dialog, {
    header: _ctx.$t('labels.addDiscountGroup'),
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[7] || (_cache[7] = $event => ((_ctx.showDialog) = $event)),
    breakpoints: {'960px': '75vw'},
    style: {width: '25vw'},
    onHide: _ctx.closeDialog,
    modal: true,
    class: "text-sm"
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[6] || (_cache[6] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.priceImport.cellValue')), 1),
            _createVNode(_component_InputText, {
              autocomplete: "off",
              modelValue: _ctx.v$.name.$model,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.v$.name.$model) = $event)),
              class: _normalizeClass({'p-invalid':_ctx.v$.name.$invalid && _ctx.submitted})
            }, null, 8, ["modelValue", "class"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.name.$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.discount')), 1),
            _createVNode(_component_InputNumber, {
              suffix: " %",
              locale: _ctx.locale,
              maxFractionDigits: 3,
              modelValue: _ctx.v$.discount.$model,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.v$.discount.$model) = $event)),
              class: _normalizeClass({'p-invalid':_ctx.v$.discount.$invalid && _ctx.submitted})
            }, null, 8, ["locale", "modelValue", "class"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.discount.$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[8] || (_cache[8] = _createElementVNode("label", null, "gilt für manuelle Importe", -1)),
            _createVNode(_component_p_radio, {
              modelValue: _ctx.v$.importType.$model,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.v$.importType.$model) = $event)),
              value: "manual",
              class: "ml-2 mb-1"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[9] || (_cache[9] = _createElementVNode("label", null, "gilt für automatische Importe", -1)),
            _createVNode(_component_p_radio, {
              modelValue: _ctx.v$.importType.$model,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.v$.importType.$model) = $event)),
              value: "automatic",
              class: "ml-2 mb-1"
            }, null, 8, ["modelValue"])
          ])
        ]),
        (_ctx.v$.importType.$model === 'automatic' && (_ctx.automaticImportColumnOptions || []).length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _cache[10] || (_cache[10] = _createElementVNode("label", null, "Spalte aus der Datei Automatischer Import", -1)),
                _createVNode(_component_p_dropdown, {
                  modelValue: _ctx.v$.automaticImportColumn.$model,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.v$.automaticImportColumn.$model) = $event)),
                  options: _ctx.automaticImportColumnOptions || [],
                  showClear: "",
                  class: _normalizeClass({'p-invalid':_ctx.v$.automaticImportColumn.$invalid && _ctx.submitted})
                }, null, 8, ["modelValue", "options", "class"]),
                (_ctx.submitted)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.automaticImportColumn.$silentErrors, (error) => {
                      return (_openBlock(), _createElementBlock("small", {
                        style: {"display":"block"},
                        class: "p-error",
                        key: error
                      }, _toDisplayString(error.$message), 1))
                    }), 128))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.v$.importType.$model !== 'automatic')
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.manufacturer')), 1),
                _createVNode(_component_p_dropdown, {
                  modelValue: _ctx.v$.manufacturer.$model,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.v$.manufacturer.$model) = $event)),
                  options: _ctx.manufacturerOptions || [],
                  optionLabel: "name",
                  filter: "",
                  showClear: "",
                  class: _normalizeClass({'p-invalid':_ctx.v$.manufacturer.$invalid && _ctx.submitted})
                }, null, 8, ["modelValue", "options", "class"]),
                (_ctx.submitted)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.manufacturer.$silentErrors, (error) => {
                      return (_openBlock(), _createElementBlock("small", {
                        style: {"display":"block"},
                        class: "p-error",
                        key: error
                      }, _toDisplayString(error.$message), 1))
                    }), 128))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_p_button, {
            disabled: _ctx.savingInProgress,
            severity: "success",
            textContent: _toDisplayString(_ctx.savingInProgress ? _ctx.$t('labels.inProgress') : _ctx.$t('buttons.confirm')),
            type: "submit"
          }, null, 8, ["disabled", "textContent"])
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["header", "visible", "onHide"]))
}