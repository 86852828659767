<div>
    <div v-for="property in Object.keys(changeSet)">
        <span class="font-semibold">
            {{$t('productShopDataSync.' + property)}}</span
        >:
        <span
            v-if="(typeof changeSet[property] !== 'string' && !(changeSet[property] instanceof String))"
            ><i
                v-if="changeSet[property] === true"
                class="pi pi-check-circle text-green-600"
            ></i
            ><i
                v-else-if="changeSet[property] === false || changeSet[property] === null"
                class="pi pi-times-circle text-red-600"
            ></i
            ><span v-else>{{changeSet[property]}}</span></span
        >
        <span v-else v-tooltip.top="changeSet[property]">
            {{stripTagsAndTruncate(changeSet[property], 25)}}
        </span>
    </div>
</div>
