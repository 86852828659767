<loading v-model:active="loading" />
<Panel>
    <template #header>
        <div class="w-full flex justify-content-between">
            <div class="flex align-items-center">
                <span class="font-bold"> {{$t('labels.usedProducts')}} </span>
                <p-button
                    class="ml-2"
                    @click="onAddNewProductClick"
                    size="small"
                    severity="success"
                >
                    {{ $t("buttons.addNewGeneric") }}
                </p-button>
            </div>
        </div>
    </template>
    <DataTable
        class="p-datatable-sm text-sm"
        stripedRows
        lazy
        :paginator="true"
        :rows="20"
        scrollable
        scrollHeight="calc(100vh - 23rem)"
        :totalRecords="totalRecords"
        :paginator-template="'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'"
        :rows-per-page-options="[10,20,50]"
        :current-page-report-template="'{first} to {last} of {totalRecords}'"
        ref="dt"
        dataKey="id"
        @page="onPage($event)"
        @filter="onFilter($event)"
        @sort="onSort($event)"
        :value="products"
        responsiveLayout="scroll"
        v-model:filters="filters"
        filterDisplay="row"
        v-model:expandedRows="expandedRows"
    >
        <Column :expander="true" headerStyle="width: 3em" />
        <Column
            field="articleNumber"
            :sortable="true"
            :header="$t('labels.articleNumber')"
            style="min-width: 16rem"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                    :placeholder="$t('labels.articleNumber')"
                />
            </template>
            <template #body="{data}">
                <div class="flex align-content-center">
                    <div
                        class="sales-channel-dot w-1rem h-1rem mr-1"
                        :class="{'sales-channel-teltec':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId) && data?.shopwareData?.active, 'sales-channel-videodata':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId) && data?.shopwareData?.active, 'sales-channel-teltec-border':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId) && data?.shopwareData && !data.shopwareData.active, 'sales-channel-videodata-border':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId) && data?.shopwareData && !data.shopwareData.active, 'bg-gray-200': !data?.shopwareData?.active}"
                    ></div>
                    <div
                        class="sales-channel-dot w-1rem h-1rem mr-1"
                        :class="{'sales-channel-videodata':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId) && data?.secondShopwareData?.active, 'sales-channel-teltec':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId) && data?.secondShopwareData?.active, 'sales-channel-videodata-border':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId) && data?.secondShopwareData && !data.secondShopwareData.active, 'sales-channel-teltec-border':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId) && data?.secondShopwareData && !data.secondShopwareData.active, 'bg-gray-200': !data?.secondShopwareData?.active}"
                    ></div>
                    <span class="text-lg"
                        ><a
                            :href="data.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + data.id"
                            target="_blank"
                            >{{data.articleNumber}}</a
                        ></span
                    >
                </div>
                <div class="text-xs">
                    {{$t('labels.stockTotal')}}: <b>{{ data.stockQuantity }}</b>
                </div>
                <div class="text-xs">
                    {{$t('labels.stockQuantityWithoutOrder')}}:
                    <b>{{ data.stockQuantityWithoutOrder }}</b>
                </div>
                <div class="text-xs">
                    {{$t('labels.vkPrice')}}:
                    <b>{{ vkPriceCalculation(data) }}</b>
                </div>
                <div class="text-xs">
                    {{$t('labels.ekPrice')}}:
                    <b>{{ ekPriceCalculation(data) }}</b>
                </div>
                <div class="text-xs" v-if="data.sourceProductNumber">
                    Neuware:
                    <a
                        :href="data.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + data.sourceProductId"
                        target="_blank"
                        >{{data.sourceProductNumber}}</a
                    >
                </div>
            </template>
        </Column>
        <Column
            field="createdDate"
            dataType="date"
            sortable
            :header="$t('labels.createdAt')"
            style="max-width: 15rem"
        >
            <template #filter="{filterModel}">
                <Calendar
                    class="p-column-filter text-xs"
                    v-model="filterModel.value"
                    dateFormat="dd.mm.yy"
                />
            </template>
            <template #body="data">
                {{ new Date(data.data.createdDate).toLocaleDateString("de-DE")
                }}
            </template>
        </Column>
        <Column
            field="name"
            :sortable="true"
            style="min-width: 16rem"
            :header="$t('labels.name')"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                    :placeholder="$t('labels.name')"
                />
            </template>
            <template #body="{data}">
                <span>{{ data.name || data.shopwareData?.name}}</span>
                <div class="mt-2">
                    <Tag
                        v-if="data.systemTags.indexOf('TTC-PRODUCT-3-STARS') !== -1"
                        severity="success"
                    >
                        <span>3 Sterne ★★★</span>
                    </Tag>
                    <Tag
                        v-if="data.systemTags.indexOf('TTC-PRODUCT-2-STARS') !== -1"
                        severity="warning"
                    >
                        <span>2 Sterne ★★</span>
                    </Tag>
                    <Tag
                        v-if="data.systemTags.indexOf('TTC-PRODUCT-1-STAR') !== -1"
                        severity="danger"
                    >
                        <span>1 Stern ★</span>
                    </Tag>
                    <Tag
                        v-if="data.systemTags.indexOf('TTC-PRODUCT-GRADE-A') !== -1"
                        severity="info"
                    >
                        <span>Grade A</span>
                    </Tag>
                    <Tag
                        v-if="data.systemTags.indexOf('TTC-PRODUCT-GRADE-B') !== -1"
                        severity="warning"
                    >
                        <span>Grade B</span>
                    </Tag>
                </div>
            </template>
        </Column>

        <Column
            field="tags"
            style="max-width: 12rem"
            :header="$t('labels.productHome.productType')"
            :filterMatchModeOptions="[{label: 'In', value: 'in'}]"
        >
            <template #filter="{filterModel,filterCallback}">
                <p-multiselect
                    class="p-column-filter text-sm w-full"
                    v-model="filterModel.value"
                    :options="[{label:'OpenBox', value:'TTC-PRODUCT-OPENBOX'}, {label:'Used', value:'TTC-PRODUCT-USED'}, {label:'ExDemo', value:'TTC-PRODUCT-EXDEMO'}, {label:'DEMO-POOL', value:'TTC-PRODUCT-DEMO'}, {label:'KOMMISSIONSWARE', value:'TTC-PRODUCT-COMMISSION-GOOD'}]"
                    optionLabel="label"
                    optionValue="value"
                    @change="filterCallback()"
                    :placeholder="$t('labels.productHome.productType')"
                >
                </p-multiselect>
            </template>
            <template #body="{data}">
                <Tag
                    v-if="data.systemTags.indexOf('TTC-PRODUCT-DEMO') !== -1"
                    severity="info"
                >
                    <span>DEMO</span>
                </Tag>
                <Tag v-if="data.systemTags.indexOf('TTC-PRODUCT-USED') !== -1">
                    <span>USED</span>
                </Tag>
                <Tag
                    v-if="data.systemTags.indexOf('TTC-PRODUCT-EXDEMO') !== -1"
                    severity="success"
                >
                    <span>EX-DEMO</span>
                </Tag>
                <Tag
                    v-if="data.systemTags.indexOf('TTC-PRODUCT-OPENBOX') !== -1"
                    severity="warning"
                >
                    <span>OPENBOX</span>
                </Tag>
                <Tag
                    v-if="data.systemTags.indexOf('TTC-PRODUCT-COMMISSION-GOOD') !== -1"
                    severity="danger"
                >
                    <span>KOMMISSIONSWARE</span>
                </Tag>
            </template>
        </Column>
        <!--<Column
            field="articleCategoryName"
            style="max-width: 12rem"
            :header="$t('labels.articleWeclappCategory')"
        >
        </Column>-->
        <Column
            :header="$t('labels.productDescription.shortDescription')"
            field="shortDescription1"
            style="max-width: 12rem"
        ></Column>
        <Column
            field="productCategory"
            :header="$t('labels.articleCategory')"
            style="min-width: 12rem"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    :placeholder="$t('labels.articleCategory')"
                />
            </template>
            <template #body="{data}">
                <ul v-if="data?.shopwareData?.categories">
                    <li v-for="element in data.shopwareData.categories">
                        {{ element.name }}
                    </li>
                </ul>
            </template>
        </Column>
        <Column
            field="availableInShop"
            dataType="boolean"
            bodyClass="text-center"
            style="max-width: 6rem"
            :header="$t('labels.productHome.availableInShop')"
        >
            <template #filter="{filterModel, filterCallback}">
                <TriStateCheckbox
                    v-model="filterModel.value"
                    @change="filterCallback()"
                />
            </template>
            <template #body="{data}">
                <i
                    class="pi"
                    :class="{'text-green-600 pi-check-circle': data.customAttributes?.article_ecom_new_tt_backend, 'text-red-600 pi-times-circle': !(data.customAttributes?.article_ecom_new_tt_backend)}"
                ></i>
            </template>
        </Column>
        <Column
            field="activeInShop"
            dataType="boolean"
            bodyClass="text-center"
            style="max-width: 6rem"
            :header="$t('labels.productHome.activeInShop')"
        >
            <template #filter="{filterModel, filterCallback}">
                <TriStateCheckbox
                    v-model="filterModel.value"
                    @change="filterCallback()"
                />
            </template>
            <template #body="{data}">
                <i
                    class="pi"
                    :class="{'text-green-600 pi-check-circle': data.customAttributes?.article_ecom_new_tt_frontend, 'text-red-600 pi-times-circle': !(data.customAttributes?.article_ecom_new_tt_frontend)}"
                ></i>
            </template>
        </Column>

        <Column style="min-width: 8rem">
            <template
                #body="{data}"
                v-if="editPermissionAvailable || viewAccessoriesPermissionAvailable || (data.shopwareData?.name && data.shopwareUrl) "
            >
                <a
                    v-if="(data.shopwareData?.name && data.shopwareUrl)"
                    target="_blank"
                    :href="resolvedShopwareUrl(data)"
                >
                    <i class="pi pi-shopping-cart text-color"></i>
                </a>

                <router-link
                    v-if="editPermissionAvailable"
                    :to="{
                                    name: 'ProductDetails',
                                    params: {id: data.articleNumber}
                                }"
                    ><i
                        class="pi pi-pencil text-color ml-2"
                        v-tooltip.left="$t('labels.articleDetails')"
                    ></i
                ></router-link>
            </template>
        </Column>
        <template #expansion="slotProps">
            <div
                v-if="slotProps.data?.description"
                v-html="slotProps.data.description"
            ></div>
            <div v-else>No description available.</div>
        </template>
    </DataTable>
</Panel>
