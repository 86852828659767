import apiClient from '@/utils/axios';
import {clearEmptyFilters} from '@/utils/helpers';
import {AxiosResponse} from 'axios';

export const getAll = (input: any): Promise<AxiosResponse> => {
    const rows = input.rows || 10;

    const params = Object.assign(
        {allEnvs: input.allEnvs},
        {
            start: input.first || 0,
            end: (input.first || 0) + rows,
            sortField: input.sortField || 'created_at_timestamp',
            sortOrder: (input.sortOrder || -1) === 1 ? 'asc' : 'desc',
            columns: JSON.stringify(input.columns || []),
            filters: JSON.stringify(clearEmptyFilters(input.filters || {})),
            filterConjunction: input.filterConjunction || 'and'
        },
        input.platform
            ? {
                  platform: input.platform
              }
            : {}
    );

    return apiClient.get('/api/v1/product-shop-data-sync', {params});
};
