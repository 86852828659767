import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import {FilterMatchMode} from 'primevue/api';
import {i18n} from '@/utils/i18n';
import LoadingPlugin from 'vue-loading-overlay';
import {onMounted, onUnmounted, ref} from 'vue';
import {useToast} from 'vue-toastification';
import {DateTime} from 'luxon';
import Panel from 'primevue/panel';
import Tag from 'primevue/tag';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import {getAll} from '@/services/product-shop-data-sync';
import ChangeSet from './changeset.vue';

export default {
    name: 'SyncLogs',
    components: {
        DataTable,
        Column,
        InputText,
        loading: LoadingPlugin,
        Panel,
        Tag,
        TriStateCheckbox,
        ChangeSet
    },
    setup() {
        onMounted(() => {
            filters.value = Object.assign({}, filterDefintions);

            loadLazyData({
                first: 0,
                rows: dt.value.rows,

                filters: filters.value
            });

            if (interval.value !== null) {
                clearInterval(interval.value);
            }

            interval.value = setInterval(() => {
                if (needsRefresh.value) {
                    loadLazyData({
                        first: 0,
                        rows: dt.value.rows,

                        filters: filters.value
                    });
                }
            }, intervalDurationInMicroseconds);
        });

        onUnmounted(() => {
            if (interval.value !== null) {
                clearInterval(interval.value);
            }
        });

        const dt = ref();
        const loading = ref(false);
        const totalRecords = ref(0);
        const interval = ref(null);
        const needsRefresh = ref(false);
        const toast = useToast();

        const intervalDurationInMicroseconds = 5000;

        const logs = ref();

        const filterDefintions: any = {
            productNumber: {value: null, matchMode: FilterMatchMode.CONTAINS},
            changeSet: {value: true, matchMode: FilterMatchMode.EQUALS}
        };

        const filters = ref(filterDefintions);

        const loadLazyData = (params: any) => {
            loading.value = true;

            getAll(params)
                .then((data) => {
                    totalRecords.value = data.data.total;
                    logs.value = data.data.items.map((obj: any) => ({
                        ...obj,
                        createdAt: obj.createdAtTimestamp
                            ? DateTime.fromSeconds(obj.createdAtTimestamp)
                                  .setLocale(i18n.global.locale)
                                  .setZone(
                                      process.env?.VUE_APP_DEFAULT_TIME_ZONE
                                  )
                                  .toLocaleString(
                                      DateTime.DATETIME_MED_WITH_SECONDS
                                  )
                            : null,
                        processingStartedAt: obj.processingStartedAtTimestamp
                            ? DateTime.fromSeconds(
                                  obj.processingStartedAtTimestamp
                              )
                                  .setLocale(i18n.global.locale)
                                  .setZone(
                                      process.env?.VUE_APP_DEFAULT_TIME_ZONE
                                  )
                                  .toLocaleString(
                                      DateTime.DATETIME_MED_WITH_SECONDS
                                  )
                            : null,
                        processingEndedAt: obj.processingEndedAtTimestamp
                            ? DateTime.fromSeconds(
                                  obj.processingEndedAtTimestamp
                              )
                                  .setLocale(i18n.global.locale)
                                  .setZone(
                                      process.env?.VUE_APP_DEFAULT_TIME_ZONE
                                  )
                                  .toLocaleString(
                                      DateTime.DATETIME_MED_WITH_SECONDS
                                  )
                            : null,
                        isAutomatic: obj.initiatedBy === 'SYSTEM'
                    }));
                    loading.value = false;
                    const inProgress = data.data.items.find((element: any) => {
                        return (
                            element.createdAtTimestamp &&
                            (!element.processingStartedAtTimestamp ||
                                !element.processingEndedAtTimestamp)
                        );
                    });

                    needsRefresh.value = !!inProgress;
                })
                .catch((error) => {
                    loading.value = false;
                    toast.error(error.message);
                });
        };

        const matchModesNumeric = [
            {label: 'Equals', value: FilterMatchMode.EQUALS},
            {label: 'Not Equals', value: FilterMatchMode.NOT_EQUALS},
            {label: 'Less Than', value: FilterMatchMode.LESS_THAN},
            {
                label: 'Less or Equal',
                value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
            },
            {label: 'Greater Than', value: FilterMatchMode.GREATER_THAN},
            {
                label: 'Greater or Equal',
                value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
            }
        ];

        return {
            logs,
            dt,
            totalRecords,
            loading,
            filters,
            matchModesNumeric,
            loadLazyData
        };
    }
};
