import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import {ref, computed} from 'vue';
import Loading from 'vue-loading-overlay';
import store from '@/store';
import Panel from 'primevue/panel';
import SupplierAutoComplete from '@/components/export-wizard/weclapp/supplier/supplier.vue';
import Stepper from 'primevue/stepper';
import StepperPanel from 'primevue/stepperpanel';
import PriceListUploadStep from '@/components/steps/price-wizard/price-list-upload-step.vue';
import FileUploadStep from '@/components/steps/price-wizard/file-upload-step.vue';
import {saveFiles} from '@/services/import';
import {useToast} from 'vue-toastification';
import {
    addSupplierConfiguration,
    getSupplierConfiguration
} from '@/services/price-import';
import {FilterMatchMode} from 'primevue/api';

export default {
    name: 'ImportWizard',
    components: {
        'p-button': Button,
        InputText,
        loading: Loading,
        Panel,
        SupplierAutoComplete,
        Stepper,
        StepperPanel,
        PriceListUploadStep,
        FileUploadStep
    },
    setup() {
        const supplierConfiguration = ref(null);
        const chosenSheet = ref(null);
        const chosenManufacturer = ref(null);
        const costPriceStartDate = ref(null);
        const listPriceStartDate = ref(null);
        const activeIndex = ref(0);
        const acceptFiles = ref([]);
        const partialPriceList = ref(false);

        const savedFile = ref(null);

        const isLoading = ref(false);

        const toast = useToast();

        const onSpreadsheetChosen = (
            files: Array<any>,
            sheetName: string,
            manufacturer: string | null,
            chosenCostPriceStartDate: any,
            chosenListPriceStartDate: any,
            partialPriceListValue: boolean
        ) => {
            acceptFiles.value.length = 0;
            files.forEach((a: File) => acceptFiles.value.push(a));
            chosenSheet.value = sheetName;
            chosenManufacturer.value = manufacturer || null;
            costPriceStartDate.value = chosenCostPriceStartDate;
            listPriceStartDate.value = chosenListPriceStartDate;
            partialPriceList.value = partialPriceListValue;
        };

        const showUploadButton = computed(() => {
            return (
                supplierConfiguration.value &&
                (chosenSheet.value !== null || savedFile.value)
            );
        });

        const uploadFile = async (callBackFunction: any) => {
            isLoading.value = true;
            try {
                if (
                    savedFile.value?.fileName &&
                    acceptFiles.value.some(
                        (item) => item.name === savedFile.value?.fileName
                    ) &&
                    chosenSheet.value === savedFile.value?.chosenSheet
                ) {
                    callBackFunction();
                } else {
                    const response = await saveFiles(
                        acceptFiles.value,
                        'weclapp-price-wizard-add',
                        chosenSheet.value
                    );

                    savedFile.value = {
                        ...response.saveResult,
                        chosenSheet: chosenSheet.value
                    };
                    callBackFunction();
                }
            } catch (error: any) {
                toast.error(error.message);
                acceptFiles.value.length = 0;
                savedFile.value = null;
            } finally {
                isLoading.value = false;
            }
        };

        const importPlatform = computed(() => {
            const currentPlatform = store.getters['auth/platform'];
            const platforms = store.getters['auth/platforms'];
            return platforms.find(
                (item: any) => item.value === currentPlatform
            );
        });

        const onSupplierChosen = (event: {
            supplier: {label: string; value: string};
        }) => {
            addSupplierConfiguration(
                event.supplier.label
                    .replace(event.supplier.value, '')
                    .replace(']', '')
                    .replace('[', '')
                    .trim(),
                event.supplier.value
            )
                .then(() => {
                    getSupplierConfiguration({
                        filters: {
                            supplierNumber: {
                                value: event.supplier.value,
                                matchMode: FilterMatchMode.EQUALS
                            }
                        }
                    })
                        .then((data: any) => {
                            supplierConfiguration.value =
                                data?.data?.items && data.data.items.length > 0
                                    ? data.data.items[0]
                                    : null;
                        })
                        .catch((error) => {
                            toast.error(
                                error.response?.data?.error || error.message
                            );
                        });
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                });
        };

        return {
            showUploadButton,
            savedFile,
            isLoading,
            importPlatform,
            onSupplierChosen,
            activeIndex,
            onSpreadsheetChosen,
            uploadFile,
            chosenManufacturer,
            costPriceStartDate,
            listPriceStartDate,
            chosenSheet,
            supplierConfiguration,
            partialPriceList
        };
    }
};
