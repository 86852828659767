import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';
import store from '@/store/index';
import {clearAuthTokens, isLoggedIn} from 'axios-jwt';

import Main from '@/modules/main/main.vue';
import Login from '@/modules/login/login.vue';
import Profile from '@/pages/profile/profile.vue';

import ImportWizard from '@/pages/import-wizard/import-wizard.vue';
import ExportWizard from '@/pages/export-wizard/export-wizard.vue';
import ImportLogs from '@/pages/import-wizard/logs/logs.vue';
import ImportShopOrders from '@/pages/import-wizard/shop-orders/list.vue';
import ImportShopOrderDetails from '@/pages/import-wizard/shop-orders/details.vue';

import Products from '@/pages/products/products.vue';
import ProductsWebhopView from '@/pages/products/products-webhop-view.vue';
import ProductsWithIncompleteDimensions from '@/pages/products/incomplete-dimensions/products-with-incomplete-dimensions.vue';
import ProductsWithIncompleteDimensionsDetails from '@/pages/products/incomplete-dimensions/update/mask.vue';
import ProductsDeliveryTime from '@/pages/products/delivery-time/products.vue';
import ProductsStockQuantity from '@/pages/products/stock-quantity/detail.vue';
import SkuMaintanance from '@/pages/products/sku-maintenance/detail.vue';

import ProductsAddWizard from '@/pages/products/add-wizard/add-new-product.vue';
import ProductsAddUsedWizard from '@/pages/products/add-wizard/add-used-product.vue';
import CloneExistingProduct from '@/pages/products/add-wizard/clone-existing-product.vue';

import UsedProducts from '@/pages/products/used/products.vue';

import PriceImportLogs from '@/pages/prices/import/logs/logs.vue';
import PriceImportLogDetails from '@/pages/prices/import/logs/details/details.vue';
import PriceImportLogSummary from '@/pages/prices/import/logs/summary/summary.vue';
import PriceSupplierConfiguration from '@/pages/prices/supplier-configuration/list.vue';
import PricesImportWizard from '@/pages/prices/import/wizard/import-wizard.vue';

import Tickets from '@/pages/tickets/tickets.vue';
import TicketDetails from '@/pages/tickets/details/details.vue';
import ProductDetails from '@/pages/products/details/details.vue';
import ProductChangelog from '@/pages/products/changelog/changelog.vue';
import {IUser} from '@/interfaces/user';
import {getProfile} from '@/services/auth';
import IncomingGoods from '@/pages/incoming-goods/incoming-goods.vue';
import Shipments from '@/pages/shipments/shipments.vue';
import OpenShipments from '@/pages/shipments/open/shipments.vue';
import ShipmentMask from '@/pages/shipments/mask/mask.vue';
import Users from '@/pages/users/users.vue';
import PrintConfigurations from '@/pages/print-configurations/print-configurations.vue';
import SystemLogs from '@/pages/system-logs/system-logs.vue';
import AccessLogs from '@/pages/access-logs/access-logs.vue';
import Crons from '@/pages/tools/jobs/crons.vue';
import NetRivals from '@/pages/tools/jobs/net-rivals.vue';
import EmailNotifications from '@/pages/tools/jobs/email-notifications.vue';
import EmailAlerts from '@/pages/tools/jobs/email-alerts.vue';
import OneTimeJobs from '@/pages/tools/jobs/one-time-jobs.vue';
import JobWorkflows from '@/pages/tools/jobs/workflows.vue';
import RentalBoard from '@/pages/products/rental/board/rental-board.vue';
import HeadlessShipments from '@/pages/shipments/headless/headless-shipments.vue';
import PurchaseOrders from '@/pages/purchase-orders/purchase-orders.vue';
import PurchaseOrderDetail from '@/pages/purchase-orders/detail/detail.vue';
import WarehouseStockBPM from '@/pages/warehouse/stock/warehouse-stock-bpm.vue';
import ManufacturersList from '@/pages/manufacturers/list.vue';
import ManufacturerResponsiblePersons from '@/pages/manufacturers/responsible-persons.vue';
import ShopDataSyncLogs from '@/pages/products/shop-data-sync/logs.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/main',
        name: 'Main',
        component: Main,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/profile',
                name: 'Profile',
                component: Profile,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'weclapp-shipments-edit'
                }
            },
            {
                path: '/',
                name: 'Import Wizard',
                component: ImportWizard,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'imports-add'
                }
            },
            {
                path: '/export-wizard',
                name: 'Export Wizard',
                component: ExportWizard,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'products-view'
                }
            },
            {
                path: '/import-logs',
                name: 'Import Logs',
                component: ImportLogs,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'imports-view'
                }
            },
            {
                path: '/import-shop-orders',
                name: 'Import Shop Orders',
                component: ImportShopOrders,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'shop-orders-import-view'
                }
            },
            {
                path: '/import-shop-orders/:id',
                name: 'ImportShopOrderDetails',
                component: ImportShopOrderDetails,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'shop-orders-import-view'
                }
            },

            {
                path: '/products',
                name: 'Products',
                component: Products,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'products-view'
                }
            },
            {
                path: '/products-webshop-view',
                name: 'ProductsWebshopView',
                component: ProductsWebhopView,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'products-view'
                }
            },
            {
                path: '/products-add-wizard',
                name: 'ProductsAddWizard',
                component: ProductsAddWizard,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'products-edit'
                }
            },
            {
                path: '/products-add-used-wizard',
                name: 'ProductsAddUsedWizard',
                component: ProductsAddUsedWizard,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'products-edit'
                }
            },
            {
                path: '/products-clone-wizard/:id',
                name: 'CloneExistingProduct',
                component: CloneExistingProduct,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'products-edit'
                }
            },
            {
                path: '/products-with-incomplete-dimensions',
                name: 'ProductsWithIncompleteDimensions',
                component: ProductsWithIncompleteDimensions,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'products-view'
                }
            },
            {
                path: '/products-delivery-time',
                name: 'ProductsDeliveryTime',
                component: ProductsDeliveryTime,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'products-edit'
                }
            },
            {
                path: '/sku-maintenance',
                name: 'SkuMaintanance',
                component: SkuMaintanance,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'products-edit'
                }
            },

            {
                path: '/used-products',
                name: 'UsedProducts',
                component: UsedProducts,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'products-view'
                }
            },
            {
                path: '/products/:id',
                name: 'ProductDetails',
                component: ProductDetails,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'products-edit'
                }
            },
            {
                path: '/products/:id/changelog',
                name: 'ProductChangelog',
                component: ProductChangelog,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'products-view'
                }
            },
            {
                path: '/products-with-incomplete-dimensions/:id',
                name: 'ProductsWithIncompleteDimensionsDetails',
                component: ProductsWithIncompleteDimensionsDetails,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'products-edit'
                }
            },
            {
                path: '/products/rental-board',
                name: 'ProductRentalBoard',
                component: RentalBoard,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'products-view'
                }
            },
            {
                path: '/products/stock-quantity',
                name: 'ProductsStockQuantity',
                component: ProductsStockQuantity,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'products-view'
                }
            },
            {
                path: '/warehouse-stock-bpm',
                name: 'WarehouseStockBPM',
                component: WarehouseStockBPM,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'weclapp-purchase-orders-view'
                }
            },
            {
                path: '/product-shop-data-sync',
                name: 'ShopDataSyncLogs',
                component: ShopDataSyncLogs,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'products-edit'
                }
            },
            {
                path: '/price-import-logs',
                name: 'Price Import Logs',
                component: PriceImportLogs,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'price-imports-view'
                }
            },
            {
                path: '/price-import-logs/:id',
                name: 'Price Import Log Details',
                component: PriceImportLogDetails,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'price-imports-view'
                }
            },
            {
                path: '/price-import-logs/:id/summary',
                name: 'Price Import Log Summary',
                component: PriceImportLogSummary,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'price-imports-view'
                }
            },
            {
                path: '/price-supplier-configuration',
                name: 'Price Supplier Configuration',
                component: PriceSupplierConfiguration,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'price-imports-view'
                }
            },
            {
                path: '/price-import-wizard',
                name: 'Price Import Wizard',
                component: PricesImportWizard,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'price-imports-add'
                }
            },
            {
                path: '/tickets',
                name: 'Tickets',
                component: Tickets,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'weclapp-tickets-view'
                }
            },
            {
                path: '/tickets/:id',
                name: 'TicketDetails',
                component: TicketDetails,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'weclapp-tickets-view'
                }
            },
            {
                path: '/purchase-orders',
                name: 'PurchaseOrders',
                component: PurchaseOrders,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'weclapp-purchase-orders-view'
                }
            },
            {
                path: '/purchase-orders/:purchaseOrderNumber',
                name: 'PurchaseOrderDetails',
                component: PurchaseOrderDetail,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'weclapp-purchase-orders-view'
                }
            },

            {
                path: '/shipments',
                name: 'Shipments',
                component: Shipments,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'weclapp-shipments-view'
                }
            },
            {
                path: '/open-shipments',
                name: 'OpenShipments',
                component: OpenShipments,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'weclapp-shipments-view'
                }
            },
            {
                path: '/shipments/outgoing/:shipmentNumber?',
                name: 'ShipmentMask',
                component: ShipmentMask,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'weclapp-shipments-edit'
                }
            },
            {
                path: '/shipments/headless',
                name: 'HeadlessShipments',
                component: HeadlessShipments,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'headless-shipments-view'
                }
            },
            {
                path: '/incoming-goods',
                name: 'IncomingGoods',
                component: IncomingGoods,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'weclapp-incoming-goods-view'
                }
            },
            {
                path: '/manufacturers',
                name: 'ManufacturersList',
                component: ManufacturersList,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'manufacturer-data-view'
                }
            },
            {
                path: '/manufacturer-responsible-persons',
                name: 'ManufacturerResponsiblePersons',
                component: ManufacturerResponsiblePersons,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'manufacturer-data-view'
                }
            },
            {
                path: '/users',
                name: 'Users',
                component: Users,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'users-view'
                }
            },
            {
                path: '/print-configurations',
                name: 'PrintConfigurations',
                component: PrintConfigurations,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'print-configurations-view'
                }
            },
            {
                path: '/system-logs',
                name: 'SystemLogs',
                component: SystemLogs,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'system-logs-view'
                }
            },
            {
                path: '/access-logs',
                name: 'AccessLogs',
                component: AccessLogs,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'system-logs-view'
                }
            },
            {
                path: '/jobs/crons',
                name: 'Crons',
                component: Crons,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'cron-jobs-view'
                }
            },
            {
                path: '/net-rivals',
                name: 'NetRivals',
                component: NetRivals,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'net-rivals-view'
                }
            },
            {
                path: '/jobs/email-notifications',
                name: 'EmailNotifications',
                component: EmailNotifications,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'cron-jobs-view'
                }
            },
            {
                path: '/jobs/email-alerts',
                name: 'EmailAlerts',
                component: EmailAlerts,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'cron-jobs-view'
                }
            },
            {
                path: '/jobs/one-time',
                name: 'OneTimeJobs',
                component: OneTimeJobs,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'products-edit'
                }
            },
            {
                path: '/jobs/workflows',
                name: 'JobWorkflows',
                component: JobWorkflows,
                meta: {
                    requiresAuth: true,
                    requiredPermission: 'cron-jobs-view'
                }
            }
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            requiresUnauth: true
        }
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

const resolveHomePage = (user: IUser): string => {
    const parentDefinition = routes.find((item) => item.path === '/main');
    const regularHomePageDefinition = parentDefinition.children.find(
        (item) => item.path === '/'
    );

    if (
        regularHomePageDefinition &&
        user &&
        user.permissions.indexOf(
            regularHomePageDefinition.meta.requiredPermission.toString()
        ) !== -1
    ) {
        return regularHomePageDefinition.path;
    }

    const firstAccessible = parentDefinition.children.find((item) => {
        return (
            user &&
            user.permissions.indexOf(
                item.meta.requiredPermission?.toString()
            ) !== -1
        );
    });

    return firstAccessible?.path || '/main';
};

router.beforeEach(async (to, from, next) => {
    if (to.meta.requiresAuth && !isLoggedIn()) {
        if (to.fullPath !== '/') {
            localStorage.setItem('targetUrl', to.fullPath);
        }
        next('/login');
    } else if (to.meta.requiresUnauth && !!isLoggedIn()) {
        const user = store.getters['auth/user'] || (await getProfile());
        if (!user || !user.permissions) {
            clearAuthTokens();
            next();
        } else {
            next(resolveHomePage(user));
        }
    } else if (to.meta.requiredPermission) {
        const user = store.getters['auth/user'] || (await getProfile());
        if (!user || !user.permissions) {
            clearAuthTokens();
            next();
        } else {
            const targetUrl = localStorage.getItem('targetUrl');
            localStorage.removeItem('targetUrl');

            const lastUrl = localStorage.getItem('lastUrl');
            localStorage.removeItem('lastUrl');

            if (targetUrl && targetUrl !== to.fullPath) {
                next(targetUrl);
            } else if (
                lastUrl &&
                lastUrl !== to.fullPath &&
                from.fullPath === '/login'
            ) {
                next(lastUrl);
            } else if (
                user.permissions.indexOf(
                    to.meta.requiredPermission.toString()
                ) === -1
            ) {
                next(resolveHomePage(user));
            } else {
                next();
            }
        }
    } else {
        next();
    }
});

router.afterEach((to) => {
    if (to.fullPath !== '/login') {
        localStorage.setItem('lastUrl', to.fullPath);
    }
});

export default router;
