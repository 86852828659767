<p-dialog
    :header="$t('labels.addDiscountGroup')"
    v-model:visible="showDialog"
    :breakpoints="{'960px': '75vw'}"
    :style="{width: '25vw'}"
    @hide="closeDialog"
    :modal="true"
    class="text-sm"
>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
        <div class="grid formgrid p-fluid">
            <div class="field col">
                <label>{{$t('labels.priceImport.cellValue')}}</label>
                <InputText
                    autocomplete="off"
                    v-model="v$.name.$model"
                    :class="{'p-invalid':v$.name.$invalid && submitted}"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.name.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="grid formgrid p-fluid">
            <div class="field col">
                <label>{{$t('labels.productAdd.discount')}}</label>
                <InputNumber
                    suffix=" %"
                    :locale="locale"
                    :maxFractionDigits="3"
                    v-model="v$.discount.$model"
                    :class="{'p-invalid':v$.discount.$invalid && submitted}"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.discount.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="formgrid grid p-fluid">
            <div class="field col">
                <label>gilt für manuelle Importe</label>
                <p-radio
                    v-model="v$.importType.$model"
                    value="manual"
                    class="ml-2 mb-1"
                />
            </div>
            <div class="field col">
                <label>gilt für automatische Importe</label>

                <p-radio
                    v-model="v$.importType.$model"
                    value="automatic"
                    class="ml-2 mb-1"
                />
            </div>
        </div>
        <div
            class="grid formgrid p-fluid"
            v-if="v$.importType.$model === 'automatic' && (automaticImportColumnOptions || []).length > 0"
        >
            <div class="field col">
                <label>Spalte aus der Datei Automatischer Import</label>
                <p-dropdown
                    v-model="v$.automaticImportColumn.$model"
                    :options="automaticImportColumnOptions || []"
                    showClear
                    :class="{'p-invalid':v$.automaticImportColumn.$invalid && submitted}"
                >
                </p-dropdown>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.automaticImportColumn.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div
            class="grid formgrid p-fluid"
            v-if="v$.importType.$model !== 'automatic'"
        >
            <div class="field col">
                <label>{{$t('labels.manufacturer')}}</label>
                <p-dropdown
                    v-model="v$.manufacturer.$model"
                    :options="manufacturerOptions || []"
                    optionLabel="name"
                    filter
                    showClear
                    :class="{'p-invalid':v$.manufacturer.$invalid && submitted}"
                >
                </p-dropdown>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.manufacturer.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="flex justify-content-end">
            <p-button
                :disabled="savingInProgress"
                severity="success"
                v-text="savingInProgress ? $t('labels.inProgress') : $t('buttons.confirm')"
                type="submit"
            >
            </p-button>
        </div>
    </form>
</p-dialog>
