import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, withModifiers as _withModifiers, withKeys as _withKeys, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "grid p-fluid" }
const _hoisted_2 = { class: "col field" }
const _hoisted_3 = { class: "p-input-icon-left" }
const _hoisted_4 = { class: "col field" }
const _hoisted_5 = { class: "col field" }
const _hoisted_6 = { class: "col-2 field" }
const _hoisted_7 = { class: "col-1 w-10rem" }
const _hoisted_8 = { style: {"vertical-align":"middle"} }
const _hoisted_9 = ["href"]
const _hoisted_10 = {
  key: 0,
  class: "text-xs"
}
const _hoisted_11 = {
  key: 1,
  class: "text-xs"
}
const _hoisted_12 = { class: "w-full flex justify-content-center ml-3" }
const _hoisted_13 = { style: {"vertical-align":"middle"} }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_InputIcon = _resolveComponent("InputIcon")
  const _component_InputText = _resolveComponent("InputText")
  const _component_IconField = _resolveComponent("IconField")
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_p_multiselect = _resolveComponent("p-multiselect")
  const _component_p_checkbox = _resolveComponent("p-checkbox")
  const _component_p_button = _resolveComponent("p-button")
  const _component_Column = _resolveComponent("Column")
  const _component_TriStateCheckbox = _resolveComponent("TriStateCheckbox")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_Panel = _resolveComponent("Panel")
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_ScrollPanel, { style: {"height":"calc(100vh - 12rem)"} }, {
      default: _withCtx(() => [
        _createVNode(_component_Panel, {
          class: "panel-container-datatable",
          header: _ctx.$t('labels.skuMaintenance')
        }, {
          default: _withCtx(() => [
            _createElementVNode("form", {
              onSubmit: _cache[5] || (_cache[5] = _withModifiers($event => (_ctx.handleSearch(!_ctx.v$.$invalid)), ["prevent"]))
            }, [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.sku')), 1),
                  _createElementVNode("span", _hoisted_3, [
                    _createVNode(_component_IconField, { iconPosition: "left" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_InputIcon, { class: "pi pi-search" }),
                        _createVNode(_component_InputText, {
                          class: _normalizeClass(["text-sm", {'p-invalid':_ctx.v$.articleNumber.$invalid && _ctx.submitted}]),
                          modelValue: _ctx.v$.articleNumber.$model,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.v$.articleNumber.$model) = $event)),
                          modelModifiers: { trim: true },
                          placeholder: 'SKU, ' + _ctx.$t('labels.commaSeparated')
                        }, null, 8, ["modelValue", "class", "placeholder"])
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.supplier')), 1),
                  _createVNode(_component_p_dropdown, {
                    modelValue: _ctx.v$.supplier.$model,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.v$.supplier.$model) = $event)),
                    options: (_ctx.allSuppliers || []),
                    optionLabel: "label",
                    optionValue: "value",
                    class: _normalizeClass({'p-invalid':_ctx.v$.supplier.$invalid && _ctx.submitted}),
                    filter: "",
                    showClear: ""
                  }, null, 8, ["modelValue", "options", "class"])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.manufacturer')), 1),
                  _createVNode(_component_p_multiselect, {
                    modelValue: _ctx.v$.manufacturer.$model,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.v$.manufacturer.$model) = $event)),
                    class: _normalizeClass({'p-invalid':_ctx.v$.manufacturer.$invalid && _ctx.submitted}),
                    options: _ctx.manufacturerOptions || [],
                    optionLabel: "name",
                    optionValue: "id",
                    filter: ""
                  }, null, 8, ["modelValue", "class", "options"])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _cache[8] || (_cache[8] = _createElementVNode("label", null, "Nicht in PL vorhanden", -1)),
                  _createElementVNode("div", null, [
                    _createVNode(_component_p_checkbox, {
                      modelValue: _ctx.v$.eol.$model,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.v$.eol.$model) = $event)),
                      binary: "",
                      class: "mt-2"
                    }, null, 8, ["modelValue"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_p_button, {
                    severity: "success",
                    textContent: _toDisplayString(_ctx.$t('buttons.confirm')),
                    type: "submit",
                    disabled: (_ctx.editingRows || []).length > 0,
                    style: {"margin-top":"20px"}
                  }, null, 8, ["textContent", "disabled"])
                ])
              ])
            ], 32),
            (_ctx.products.length > 0)
              ? (_openBlock(), _createBlock(_component_DataTable, {
                  key: 0,
                  class: "p-datatable-sm text-sm",
                  paginator: "",
                  rows: 10,
                  style: {"height":"100%"},
                  scrollHeight: "calc(100vh - 23rem)",
                  editMode: "row",
                  editingRows: _ctx.editingRows,
                  "onUpdate:editingRows": _cache[6] || (_cache[6] = $event => ((_ctx.editingRows) = $event)),
                  onRowEditSave: _ctx.onRowEditComplete,
                  ref: "dt",
                  dataKey: "id",
                  totalRecords: _ctx.totalRecords,
                  value: _ctx.products,
                  scrollable: "",
                  "paginator-template": 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown',
                  "rows-per-page-options": [10,20,50,100,200],
                  "current-page-report-template": '{first} to {last} of {totalRecords}',
                  filters: _ctx.filters,
                  "onUpdate:filters": _cache[7] || (_cache[7] = $event => ((_ctx.filters) = $event)),
                  filterDisplay: "row"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Column, {
                      field: "articleNumberNameCombined",
                      sortable: true,
                      header: _ctx.$t('labels.articleNumber'),
                      style: {"min-width":"13rem"}
                    }, {
                      filter: _withCtx(({filterModel,filterCallback}) => [
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: filterModel.value,
                          "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                          onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                          class: "p-column-filter text-xs",
                          placeholder: _ctx.$t('labels.articleNumber')
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
                      ]),
                      body: _withCtx(({data}) => [
                        _createElementVNode("span", _hoisted_8, [
                          _createElementVNode("a", {
                            href: data.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + data.id,
                            target: "_blank"
                          }, _toDisplayString(data.articleNumber), 9, _hoisted_9)
                        ]),
                        _createElementVNode("div", null, _toDisplayString(data.name), 1),
                        (_ctx.ekPriceCalculation(data))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                              _createTextVNode(_toDisplayString(_ctx.$t('labels.ekPrice')) + ": ", 1),
                              _createElementVNode("b", null, _toDisplayString(_ctx.ekPriceCalculation(data)), 1)
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.uvpPriceCalculation(data))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                              _cache[9] || (_cache[9] = _createTextVNode(" UVP: ")),
                              _createElementVNode("b", null, _toDisplayString(_ctx.uvpPriceCalculation(data)), 1)
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["header"]),
                    _createVNode(_component_Column, {
                      field: "supplySourceArticleNumber",
                      sortable: "",
                      header: _ctx.$t('labels.productAdd.sku'),
                      style: {"min-width":"12rem"}
                    }, {
                      filter: _withCtx(({filterModel,filterCallback}) => [
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: filterModel.value,
                          "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                          onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                          class: "p-column-filter text-xs",
                          placeholder: _ctx.$t('labels.productAdd.sku')
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
                      ]),
                      editor: _withCtx(({ data, field }) => [
                        _createVNode(_component_InputText, {
                          modelValue: data[field],
                          "onUpdate:modelValue": $event => ((data[field]) = $event),
                          class: "p-column-filter text-sm",
                          inputClass: "w-8rem"
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ]),
                      body: _withCtx(({data}) => [
                        _createElementVNode("b", null, _toDisplayString(data.supplySourceArticleNumber), 1)
                      ]),
                      _: 1
                    }, 8, ["header"]),
                    _createVNode(_component_Column, {
                      field: "supplySourceName",
                      sortable: "",
                      style: {"min-width":"15rem"},
                      header: _ctx.$t('labels.nameFromSupplySource')
                    }, {
                      filter: _withCtx(({filterModel,filterCallback}) => [
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: filterModel.value,
                          "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                          onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                          class: "p-column-filter text-xs",
                          placeholder: _ctx.$t('labels.name')
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
                      ]),
                      editor: _withCtx(({ data, field }) => [
                        _createVNode(_component_InputText, {
                          modelValue: data[field],
                          "onUpdate:modelValue": $event => ((data[field]) = $event),
                          class: "p-column-filter text-sm",
                          inputClass: "w-8rem"
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ]),
                      body: _withCtx(({data}) => [
                        _createTextVNode(_toDisplayString(data.supplySourceName), 1)
                      ]),
                      _: 1
                    }, 8, ["header"]),
                    _createVNode(_component_Column, {
                      field: "supplierNumberNameCombined",
                      sortable: "",
                      header: _ctx.$t('labels.productAdd.supplier'),
                      style: {"min-width":"8rem"}
                    }, {
                      filter: _withCtx(({filterModel,filterCallback}) => [
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: filterModel.value,
                          "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                          onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                          class: "p-column-filter text-xs",
                          placeholder: _ctx.$t('labels.name')
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
                      ]),
                      body: _withCtx(({data}) => [
                        _createTextVNode(_toDisplayString(data.supplierNumber) + " " + _toDisplayString(data.supplierName), 1)
                      ]),
                      _: 1
                    }, 8, ["header"]),
                    _createVNode(_component_Column, {
                      field: "eolTag",
                      dataType: "boolean",
                      bodyClass: "text-center",
                      header: "Nicht in PL vorhanden",
                      sortable: ""
                    }, {
                      filter: _withCtx(({filterModel, filterCallback}) => [
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("div", null, [
                            _createVNode(_component_TriStateCheckbox, {
                              modelValue: filterModel.value,
                              "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                              onChange: $event => (filterCallback())
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                          ])
                        ])
                      ]),
                      body: _withCtx(({data}) => [
                        _createElementVNode("i", {
                          class: _normalizeClass(["pi", {'text-green-600 pi-check-circle': data.eolTag, 'text-red-600 pi-times-circle': !(data.eolTag)}])
                        }, null, 2)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "manufacturerName",
                      sortable: "",
                      style: {"min-width":"12rem"},
                      header: _ctx.$t('labels.manufacturer')
                    }, {
                      filter: _withCtx(({filterModel,filterCallback}) => [
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: filterModel.value,
                          "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                          onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                          class: "p-column-filter text-xs",
                          placeholder: _ctx.$t('labels.manufacturer')
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
                      ]),
                      body: _withCtx(({data}) => [
                        _createElementVNode("span", _hoisted_13, _toDisplayString(data.manufacturerName), 1)
                      ]),
                      _: 1
                    }, 8, ["header"]),
                    _createVNode(_component_Column, {
                      rowEditor: "",
                      style: {"width":"10%","min-width":"5rem"},
                      bodyStyle: "text-align:center"
                    })
                  ]),
                  _: 1
                }, 8, ["editingRows", "onRowEditSave", "totalRecords", "value", "filters"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["header"])
      ]),
      _: 1
    })
  ], 64))
}