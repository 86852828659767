import Dialog from 'primevue/dialog';
import {onMounted, ref, watch} from 'vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import {helpers, required, requiredIf} from '@vuelidate/validators';
import {useVuelidate} from '@vuelidate/core';
import {i18n} from '@/utils/i18n';
import {useToast} from 'vue-toastification';
import InputNumber from 'primevue/inputnumber';
import {
    addSupplierConfigurationDiscountGroup,
    getAutomaticPriceConfiguration
} from '@/services/price-import';
import {getAll} from '@/services/metadata';
import Dropdown from 'primevue/dropdown';
import RadioButton from 'primevue/radiobutton';

export default {
    emits: ['close-dialog'],
    components: {
        'p-dialog': Dialog,
        InputText,
        InputNumber,
        'p-button': Button,
        'p-dropdown': Dropdown,
        'p-radio': RadioButton
    },
    props: {
        displayResponsive: Boolean,
        supplierConfiguration: Object
    },
    setup(props: any, context: any) {
        const showDialog = ref(props.displayResponsive);
        const submitted = ref(false);
        const supplierConfiguration = ref(props.supplierConfiguration);
        const savingInProgress = ref(false);
        const toast = useToast();
        const manufacturerOptions = ref([]);
        const automaticImportColumnOptions = ref([]);

        onMounted(() => {
            getAll(['manufacturer'], false)
                .then((data: any) => {
                    manufacturerOptions.value = data.data?.manufacturer || [];
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                });
        });

        const state = ref({
            name: null,
            discount: null,
            importType: 'manual',
            manufacturer: null,
            automaticImportColumn: null
        });

        const rules = {
            name: {},
            discount: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            importType: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            manufacturer: {},
            automaticImportColumn: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return state.value.importType === 'automatic';
                    })
                )
            }
        };

        const v$ = useVuelidate(rules, state);

        watch(
            () => props.supplierConfiguration,
            async (val) => {
                supplierConfiguration.value = val;
                if (val) {
                    const options = await getAutomaticPriceConfiguration(
                        val.supplierName
                    );

                    automaticImportColumnOptions.value = options.data;
                }
            }
        );

        watch(
            () => props.displayResponsive,
            (val) => {
                showDialog.value = val;
                resetForm();
            }
        );

        const closeDialog = (forceRefresh: boolean = false) => {
            resetForm();
            context.emit('close-dialog', forceRefresh);
        };

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            savingInProgress.value = true;

            addSupplierConfigurationDiscountGroup(
                supplierConfiguration.value.id,
                state.value
            )
                .then(() => {
                    toast.success(
                        i18n.global.t('messages.changesSavedSuccessfully')
                    );
                    closeDialog(true);
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    savingInProgress.value = false;
                });
        };

        const resetForm = () => {
            state.value.name = null;
            state.value.discount = null;
            state.value.manufacturer = null;
            state.value.automaticImportColumn = null;
            state.value.importType = 'manual';

            submitted.value = false;
        };

        return {
            showDialog,
            closeDialog,
            state,
            v$,
            handleSubmit,
            submitted,
            savingInProgress,
            locale: i18n.global.locale,
            manufacturerOptions,
            automaticImportColumnOptions
        };
    }
};
