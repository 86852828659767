import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import InputSwitch from 'primevue/inputswitch';
import ProgressBar from 'primevue/progressbar';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import {FilterMatchMode, FilterService} from 'primevue/api';
import {i18n} from '@/utils/i18n';
import LoadingPlugin from 'vue-loading-overlay';
import {computed, onMounted, ref} from 'vue';
import {useToast} from 'vue-toastification';
import Tag from 'primevue/tag';
import {
    ekPriceCalculation,
    getPlatformCellContent,
    roundTo2Decimals,
    stripTagsAndTruncate,
    uvpPriceCalculation,
    vkPriceCalculation
} from '@/utils/helpers';
import Panel from 'primevue/panel';
import MultiSelect from 'primevue/multiselect';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Badge from 'primevue/badge';
import {getSingle} from '@/services/price-import';
import {useRoute} from 'vue-router';
import {DateTime} from 'luxon';
import router from '@/router';
import {useConfirm} from 'primevue/useconfirm';
import {removePriceImport, runPriceImportManually} from '@/services/import';
import store from '@/store';
import {ILayoutConfig} from '@/interfaces/layout/config';
import {getSome} from '@/services/metadata';

export default {
    name: 'ImportLogs',
    components: {
        DataTable,
        Column,
        InputText,
        'p-dropdown': Dropdown,
        TriStateCheckbox,
        InputSwitch,
        ProgressBar,
        loading: LoadingPlugin,
        Panel,
        Badge,
        Tag,
        'p-multiselect': MultiSelect,
        'p-button': Button,
        'p-checkbox': Checkbox
    },
    setup() {
        onMounted(() => {
            getSome('customAttributeDefinition', 'attributeKey', [
                'article_priceoff_noorder'
            ])
                .then((data) => {
                    priceOnRequestOptions.value =
                        data.data['article_priceoff_noorder']
                            ?.selectableValues || [];
                })
                .catch((error) => {
                    toast.error(error.message);
                });

            FilterService.register(
                articleComboFilter.value,
                (value, filter) => {
                    if (
                        filter === undefined ||
                        filter === null ||
                        filter.trim() === ''
                    ) {
                        return true;
                    }

                    if (value === undefined || value === null) {
                        return false;
                    }

                    return (
                        (value.articleNumber &&
                            value.articleNumber.includes(filter)) ||
                        (value.name && value.name.includes(filter)) ||
                        (value.ean && value.ean.includes(filter))
                    );
                }
            );

            FilterService.register(
                articleOnlineStatusFilter.value,
                (value, filter) => {
                    if (filter === undefined || filter === null) {
                        return true;
                    }

                    if (value === undefined || value === null) {
                        return false;
                    }

                    return filter === value;
                }
            );

            FilterService.register(
                teltecStatusFilter.value,
                (value, filter) => {
                    if (
                        typeof filter === 'undefined' ||
                        filter === null ||
                        filter.length === 0
                    ) {
                        return true;
                    }

                    if (value === undefined || value === null) {
                        return false;
                    }

                    return filter.includes(value);
                }
            );

            FilterService.register(
                teltecChangeStatusFilter.value,
                (value, filter) => {
                    if (
                        typeof filter === 'undefined' ||
                        filter === null ||
                        filter.length === 0
                    ) {
                        return true;
                    }

                    if (value === undefined || value === null) {
                        return false;
                    }

                    return filter.every((itm: any) => {
                        if (itm === -1) {
                            return (
                                value < 1000 &&
                                (value & 128) === 0 &&
                                (value % 32 | 3) === 3
                            );
                        }

                        if (itm === -2) {
                            return (
                                value < 1000 &&
                                (value & 3) === 0 &&
                                (value & 256) === 0 &&
                                (value | 31) === 31
                            );
                        }

                        return value < 1000 && (value & itm) === itm;
                    });
                }
            );

            FilterService.register(percentageFilter.value, (value, filter) => {
                if (typeof filter === 'undefined') {
                    return true;
                }

                if (value === undefined || value === null) {
                    return false;
                }

                if (filter === 0) {
                    return value < 0.01 && value > -0.01;
                }

                if (filter === 1) {
                    return value <= -0.01;
                }

                if (filter === 2) {
                    return value >= 0.01;
                }

                if (filter === -4) {
                    return value <= 0.01 && value >= -5;
                }

                if (filter === 4) {
                    return value >= 0.01 && value <= 5;
                }

                if (filter === -5) {
                    return value < -5 && value >= -15;
                }

                if (filter === 5) {
                    return value > 5 && value <= 15;
                }

                return filter < 0 ? value < filter : value > filter;
            });

            loadLazyData();
        });

        const dt = ref();
        const loading = ref(false);
        const totalRecords = ref(0);
        const toast = useToast();
        const route = useRoute();
        const url = ref(null);
        const parentData = ref(null);
        const articleComboFilter = ref('articleComboFilter');
        const articleOnlineStatusFilter = ref('articleOnlineStatusFilter');
        const teltecStatusFilter = ref('teltecStatusFilter');
        const teltecChangeStatusFilter = ref('teltecChangeStatusFilter');
        const percentageFilter = ref('percentageFilter');
        const selectedItems = ref([]);
        const confirm = useConfirm();
        const priceOnRequestOptions = ref([]);

        const logs = ref();

        const filterDefintions: any = {
            status: {
                value: route.query?.status
                    ? typeof route.query?.status === 'string'
                        ? route.query?.status === 'eol'
                            ? []
                            : [parseInt(route.query?.status)]
                        : route.query?.status.map((item) => parseInt(item))
                    : [],
                matchMode: teltecStatusFilter.value
            },
            changeStatus: {
                value: route.query?.changeStatus
                    ? typeof route.query?.changeStatus === 'string'
                        ? [parseInt(route.query?.changeStatus)]
                        : route.query?.changeStatus.map((item) =>
                              parseInt(item)
                          )
                    : [],
                matchMode: teltecChangeStatusFilter.value
            },
            changeStatusDates: {
                value: [],
                matchMode: teltecChangeStatusFilter.value
            },
            sku: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            weclappArticle: {
                value: null,
                matchMode: articleComboFilter.value
            },
            onlineStatus: {
                value: null,
                matchMode: articleOnlineStatusFilter.value
            },
            trailingDiscount: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
            costPriceDifferenceInPercentage: {
                value: null,
                matchMode: percentageFilter.value
            },
            listPriceDifferenceInPercentage: {
                value: null,
                matchMode: percentageFilter.value
            },
            isProcessed: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
            hasError: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            }
        };

        const filters = ref(filterDefintions);

        const isIrregularFilterActive = computed(() => {
            return !!route.query?.status;
        });

        const loadLazyData = () => {
            loading.value = true;

            getSingle(
                route.params.id,
                !isIrregularFilterActive.value,
                isIrregularFilterActive.value
            )
                .then((data) => {
                    totalRecords.value = data.data.total;
                    parentData.value = data.data.parentData
                        ? Object.assign(data.data.parentData, {
                              importedAt: data.data.parentData
                                  .insertedAtTimestamp
                                  ? DateTime.fromSeconds(
                                        data.data.parentData.insertedAtTimestamp
                                    )
                                        .setLocale(i18n.global.locale)
                                        .setZone(
                                            process.env
                                                ?.VUE_APP_DEFAULT_TIME_ZONE
                                        )
                                        .toLocaleString(
                                            DateTime.DATETIME_MED_WITH_SECONDS
                                        )
                                  : null,
                              processingEndedAt: data.data.parentData
                                  .processingEndedAtTimestamp
                                  ? DateTime.fromSeconds(
                                        data.data.parentData
                                            .processingEndedAtTimestamp
                                    )
                                        .setLocale(i18n.global.locale)
                                        .setZone(
                                            process.env
                                                ?.VUE_APP_DEFAULT_TIME_ZONE
                                        )
                                        .toLocaleString(
                                            DateTime.DATETIME_MED_WITH_SECONDS
                                        )
                                  : null,
                              costPriceStartDate: data.data.parentData
                                  .costPriceStartDateTimestamp
                                  ? DateTime.fromSeconds(
                                        data.data.parentData
                                            .costPriceStartDateTimestamp
                                    )
                                        .setLocale(i18n.global.locale)
                                        .setZone(
                                            process.env
                                                ?.VUE_APP_DEFAULT_TIME_ZONE
                                        )
                                        .toLocaleString({
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit'
                                        })
                                  : null,
                              listPriceStartDate: data.data.parentData
                                  .listPriceStartDateTimestamp
                                  ? DateTime.fromSeconds(
                                        data.data.parentData
                                            .listPriceStartDateTimestamp
                                    )
                                        .setLocale(i18n.global.locale)
                                        .setZone(
                                            process.env
                                                ?.VUE_APP_DEFAULT_TIME_ZONE
                                        )
                                        .toLocaleString({
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit'
                                        })
                                  : null
                          })
                        : null;
                    url.value = data.data.url || null;

                    logs.value = data.data.items.map((obj: any) => ({
                        ...obj,
                        changeStatus:
                            obj.status !== null && obj.status < 1000
                                ? obj.status
                                : null,
                        changeStatusDates:
                            obj.status !== null && obj.status < 1000
                                ? obj.status
                                : null,
                        onlineStatus:
                            obj.weclappArticle?.customAttributes
                                ?.article_ecom_new_tt_frontend || false,
                        costPriceLocked: isPriceLocked(
                            obj.weclappArticle?.customAttributes
                                ?.article_promo_ek_start || null,
                            obj.weclappArticle?.customAttributes
                                ?.article_promo_ek_ende || null
                        ),
                        sellPriceLocked: isPriceLocked(
                            obj.weclappArticle?.customAttributes
                                ?.article_vk_promo_start || null,
                            obj.weclappArticle?.customAttributes
                                ?.article_vk_promo_end || null
                        ),
                        listPriceLocked: isPriceLocked(
                            obj.weclappArticle?.customAttributes
                                ?.article_uvp_lock_start || null,
                            obj.weclappArticle?.customAttributes
                                ?.article_uvp_lock_end || null
                        ),
                        calculatedCostPrice: roundTo2Decimals(
                            ekPriceCalculation(
                                obj.weclappArticle,
                                false,
                                parentData.value?.supplierConfiguration
                                    ?.supplierNumber,
                                obj.extractedData?.costPriceStartDate
                                    ? parseInt(
                                          obj.extractedData.costPriceStartDate
                                      ) * 1000
                                    : null
                            )
                        ),
                        calculatedCostPriceFormatted: ekPriceCalculation(
                            obj.weclappArticle,
                            true,
                            parentData.value?.supplierConfiguration
                                ?.supplierNumber,
                            obj.extractedData?.costPriceStartDate
                                ? parseInt(
                                      obj.extractedData.costPriceStartDate
                                  ) * 1000
                                : null
                        ),
                        calculatedSellPrice: roundTo2Decimals(
                            vkPriceCalculation(obj.weclappArticle, false)
                        ),
                        calculatedListPrice: roundTo2Decimals(
                            uvpPriceCalculation(
                                obj.weclappArticle,
                                false,
                                obj.extractedData?.listPriceStartDate
                                    ? parseInt(
                                          obj.extractedData?.listPriceStartDate
                                      ) * 1000
                                    : null
                            )
                        ),
                        processedAt: obj.processedAtTimestamp
                            ? DateTime.fromSeconds(obj.processedAtTimestamp)
                                  .setLocale(i18n.global.locale)
                                  .setZone(
                                      process.env?.VUE_APP_DEFAULT_TIME_ZONE
                                  )
                                  .toLocaleString(
                                      DateTime.DATETIME_MED_WITH_SECONDS
                                  )
                            : null,
                        sourceProductTag: obj.weclappArticle?.systemTags.find(
                            (tag: string) => tag.startsWith('TTC-PRODUCT-NEW-')
                        ),
                        trailingDiscount: !!obj.extractedData?.nsr,
                        costPriceDifferenceInPercentage:
                            calculateCostPriceDifferenceInPercentage(obj),
                        listPriceDifferenceInPercentage:
                            calculateListPriceDifferenceInPercentage(obj),
                        isPrimarySupplySource: isPrimarySupplySource(obj),
                        isProcessed: !!obj.processedAtTimestamp,
                        hasError: !!obj.weclappError
                    }));
                })
                .catch((error) => {
                    toast.error(error.message);
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        const isPriceLocked = (
            lockStartCustomAttribute: number | null,
            lockEndCustomAttribute: number | null
        ): boolean => {
            if (
                lockStartCustomAttribute === null &&
                lockEndCustomAttribute === null
            ) {
                return false;
            }

            const currentTime = DateTime.now().toMillis();

            if (lockStartCustomAttribute === null) {
                return (
                    DateTime.fromMillis(lockEndCustomAttribute)
                        .endOf('day')
                        .toMillis() >= currentTime
                );
            }

            const lockStartParsed = DateTime.fromMillis(
                lockStartCustomAttribute
            ).startOf('day');

            if (lockStartParsed.toMillis() > currentTime) {
                return false;
            }

            if (lockEndCustomAttribute === null) {
                return true;
            }

            return (
                DateTime.fromMillis(lockEndCustomAttribute)
                    .endOf('day')
                    .toMillis() >= currentTime
            );
        };

        const calculateCostPriceDifferenceInPercentage = (
            data: any
        ): number | null => {
            if (!data.extractedData) {
                return null;
            }

            const calculatedCostPrice = roundTo2Decimals(
                ekPriceCalculation(
                    data.weclappArticle,
                    false,
                    parentData.value?.supplierConfiguration?.supplierNumber,
                    data.extractedData?.costPriceStartDate
                        ? parseInt(data.extractedData.costPriceStartDate) * 1000
                        : null
                )
            );

            if (data.extractedData.costPrice > calculatedCostPrice) {
                return calculatedCostPrice
                    ? ((data.extractedData.costPrice - calculatedCostPrice) /
                          calculatedCostPrice) *
                          100
                    : 100;
            }

            if (data.extractedData.costPrice < calculatedCostPrice) {
                return (
                    ((data.extractedData.costPrice - calculatedCostPrice) /
                        calculatedCostPrice) *
                    100
                );
            }

            return 0;
        };

        const calculateListPriceDifferenceInPercentage = (
            data: any
        ): number | null => {
            if (!data.extractedData) {
                return null;
            }

            const calculatedListPrice = roundTo2Decimals(
                uvpPriceCalculation(
                    data.weclappArticle,
                    false,
                    data.extractedData?.listPriceStartDate
                        ? parseInt(data.extractedData?.listPriceStartDate) *
                              1000
                        : null
                )
            );

            if (data.extractedData.listPrice > calculatedListPrice) {
                return calculatedListPrice
                    ? ((data.extractedData.listPrice - calculatedListPrice) /
                          calculatedListPrice) *
                          100
                    : 100;
            }

            if (data.extractedData.listPrice < calculatedListPrice) {
                return (
                    ((data.extractedData.listPrice - calculatedListPrice) /
                        calculatedListPrice) *
                    100
                );
            }

            return 0;
        };

        const isPrimarySupplySource = (data: any): boolean => {
            if (!data.weclappArticle?.primarySupplySourceId) {
                return false;
            }

            return (data.weclappArticle?.articleSupplySources || []).some(
                (item: any) =>
                    item.id.toString() ===
                        data.weclappArticle?.primarySupplySourceId &&
                    item.supplierNumber ===
                        parentData.value?.supplierConfiguration?.supplierNumber
            );
        };

        const matchModesNumeric = [
            {label: 'Equals', value: FilterMatchMode.EQUALS},
            {label: 'Not Equals', value: FilterMatchMode.NOT_EQUALS},
            {label: 'Less Than', value: FilterMatchMode.LESS_THAN},
            {
                label: 'Less or Equal',
                value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
            },
            {label: 'Greater Than', value: FilterMatchMode.GREATER_THAN},
            {
                label: 'Greater or Equal',
                value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
            }
        ];

        const getStatusCellStyle = (data: any) => {
            if (!data) {
                return '';
            }

            if (!data?.processingStartedAt) {
                return 'text-orange-400';
            }

            if (!data?.processingEndedAt) {
                return 'text-blue-400';
            }

            return 'text-green-400';
        };

        const getStatusCellContent = (data: any) => {
            if (!data) {
                return '';
            }

            if (!data?.processingStartedAt) {
                return i18n.global.t('labels.scheduled');
            }

            if (!data?.processingEndedAt) {
                return i18n.global.t('labels.inProgress');
            }

            return i18n.global.t('labels.done');
        };

        const getStatusTooltip = (status: number) => {
            switch (status) {
                case 10002:
                    return 'Artikel in weclapp als "Nicht in PL - Ersatzteil" gekennzeichnet';
                case 10003:
                    return 'Artikel in weclapp inaktiv, in PL vorhanden';
                case 10004:
                    return 'Fehler in PL';
                case 10005:
                    return 'Bestehender EK in der Zukunft';
                case 10006:
                    return 'Bestehender UVP in der Zukunft';
                case 1001:
                    return 'In PL nicht vorhanden';
                case 1002:
                    return 'In PL nicht vorhanden, ist primäre BZQ und mehrere BZQ vorhanden';
                case 1003:
                    return 'In PL nicht vorhanden, ist primäre BZQ und nur eine BZQ vorhanden, ohne Lagerbestand';
                case 1004:
                    return 'In PL nicht vorhanden, ist primäre BZQ und nur eine BZQ vorhanden und Lagerbestand vorhanden';
            }

            return '';
        };

        const onBackToListClick = () => {
            router.push({
                name: 'Price Import Logs'
            });
        };

        const onBackToOverviewClick = () => {
            router.push({
                name: 'Price Import Log Summary',
                params: {id: route.params.id}
            });
        };

        const runManually = async () => {
            confirm.require({
                message: i18n.global.t('messages.runPriceImportManually', {
                    number:
                        parentData.value.statistics?.regularItemsCount -
                        parentData.value.statistics?.handledItemsCount -
                        selectedItems.value.length
                }),
                header: i18n.global.t('messages.pleaseConfirm'),
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: i18n.global.t('labels.yes'),
                rejectLabel: i18n.global.t('labels.no'),
                accept: async () => {
                    try {
                        await runPriceImportManually(
                            parentData.value.id,
                            selectedItems.value
                        );
                        toast.success(
                            i18n.global.t('messages.cronJobExecutionScheduled')
                        );
                    } catch (error: any) {
                        toast.error(
                            error.response?.data?.error || error.message
                        );
                    }
                }
            });
        };

        const rejectImport = async () => {
            confirm.require({
                message: i18n.global.t('messages.deleteConfirmation', {
                    item: 'den Preisimport'
                }),
                header: i18n.global.t('messages.pleaseConfirm'),
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: i18n.global.t('labels.yes'),
                rejectLabel: i18n.global.t('labels.no'),
                accept: async () => {
                    try {
                        await removePriceImport(parentData.value.id);
                        toast.success(
                            i18n.global.t('messages.changesSavedSuccessfully')
                        );
                        onBackToListClick();
                    } catch (error: any) {
                        toast.error(
                            error.response?.data?.error || error.message
                        );
                    }
                }
            });
        };

        const sellPriceChangeRejectedDueToNetRivalsUpdate = (
            netRivalsUpdateTime: number | null
        ): boolean => {
            if (
                (!parentData.value?.insertedAtTimestamp &&
                    !parentData.value?.costPriceStartDateTimestamp) ||
                !netRivalsUpdateTime
            ) {
                return false;
            }

            return (
                DateTime.fromMillis(netRivalsUpdateTime)
                    .setLocale(i18n.global.locale)
                    .setZone(process.env?.VUE_APP_DEFAULT_TIME_ZONE)
                    .startOf('day')
                    .toMillis() >=
                DateTime.fromSeconds(
                    parentData.value?.costPriceStartDateTimestamp ||
                        parentData.value?.insertedAtTimestamp
                )
                    .setLocale(i18n.global.locale)
                    .setZone(process.env?.VUE_APP_DEFAULT_TIME_ZONE)
                    .startOf('day')
                    .toMillis()
            );
        };

        const layoutConfig = computed(() => {
            return store.getters['ui/layoutConfig'] as ILayoutConfig;
        });

        return {
            logs,
            dt,
            totalRecords,
            loading,
            filters,
            matchModesNumeric,
            getPlatformCellContent: getPlatformCellContent,
            getStatusCellStyle,
            getStatusCellContent,
            parentData,
            url,
            formatter: new Intl.NumberFormat(i18n.global.locale, {
                style: 'currency',
                currency: 'EUR'
            }),
            liteFormatter: new Intl.NumberFormat(i18n.global.locale),
            formatInCurrency: (input: number, currency: string) => {
                const formatter = new Intl.NumberFormat(i18n.global.locale, {
                    style: 'currency',
                    currency
                });

                return formatter.format(input);
            },
            getStatusTooltip,
            articleComboFilter,
            articleOnlineStatusFilter,
            teltecStatusFilter,
            teltecChangeStatusFilter,
            percentageFilter,
            onBackToListClick,
            onBackToOverviewClick,
            isIrregularFilterActive,
            selectedItems,
            runManually,
            rowClass: (data: any) => {
                const colorVariant = layoutConfig.value?.darkTheme ? 800 : 100;
                return data?.isProcessed
                    ? data?.weclappError
                        ? 'bg-red-' + colorVariant
                        : 'bg-green-' + colorVariant
                    : '';
            },
            stripTags: stripTagsAndTruncate,
            priceOnRequestOptions,
            rejectImport,
            sellPriceChangeRejectedDueToNetRivalsUpdate
        };
    }
};
