import Chart from 'primevue/chart';
import {i18n} from '@/utils/i18n';
import LoadingPlugin from 'vue-loading-overlay';
import {computed, onMounted, ref} from 'vue';
import {useToast} from 'vue-toastification';
import {
    ekPriceCalculation,
    getPlatformCellContent,
    uvpPriceCalculation
} from '@/utils/helpers';
import Panel from 'primevue/panel';
import {getSingle, getAll} from '@/services/price-import';
import {useRoute} from 'vue-router';
import {DateTime} from 'luxon';
import Tag from 'primevue/tag';
import {FilterMatchMode} from 'primevue/api';
import router from '@/router';
import ScrollPanel from 'primevue/scrollpanel';
import Button from 'primevue/button';
import {removePriceImport, runPriceImportManually} from '@/services/import';
import {useConfirm} from 'primevue/useconfirm';

export default {
    name: 'ImportLogs',
    components: {
        loading: LoadingPlugin,
        Panel,
        Chart,
        Tag,
        ScrollPanel,
        'p-button': Button
    },
    setup() {
        onMounted(() => {
            loadLazyData();

            statusChartOptions.value = setChartOptions();
            costPriceChartOptions.value = setPriceChartOptions();
            listPriceChartOptions.value = setPriceChartOptions();
        });

        const dt = ref();
        const loading = ref(false);
        const totalRecords = ref(0);
        const toast = useToast();
        const route = useRoute();
        const url = ref(null);
        const parentData = ref(null);
        const statusChartData = ref();
        const statusChartOptions = ref(null);
        const lastRunData = ref(null);

        const costPriceChartData = ref();
        const costPriceChartOptions = ref(null);
        const listPriceChartData = ref();
        const listPriceChartOptions = ref(null);

        const logs = ref([]);
        const confirm = useConfirm();

        const loadLazyData = () => {
            loading.value = true;

            getSingle(route.params.id)
                .then((data) => {
                    totalRecords.value = data.data.total;
                    parentData.value = data.data.parentData
                        ? Object.assign(data.data.parentData, {
                              processingEndedAt: data.data.parentData
                                  .processingEndedAtTimestamp
                                  ? DateTime.fromSeconds(
                                        data.data.parentData
                                            .processingEndedAtTimestamp
                                    )
                                        .setLocale(i18n.global.locale)
                                        .setZone(
                                            process.env
                                                ?.VUE_APP_DEFAULT_TIME_ZONE
                                        )
                                        .toLocaleString(
                                            DateTime.DATETIME_MED_WITH_SECONDS
                                        )
                                  : null
                          })
                        : null;
                    url.value = data.data.url || null;
                    logs.value = data.data.items.map((obj: any) => ({
                        ...obj,
                        calculatedCostPrice: ekPriceCalculation(
                            obj.weclappArticle,
                            false,
                            parentData.value?.supplierConfiguration
                                ?.supplierNumber
                        ),
                        calculatedListPrice: uvpPriceCalculation(
                            obj.weclappArticle,
                            false
                        )
                    }));

                    getAll({
                        first: 0,
                        rows: 1,
                        sortField: 'id',
                        sortOrder: -1,
                        filters: {
                            supplierName: {
                                value: parentData.value?.supplierConfiguration
                                    ?.supplierNumber,
                                matchMode: FilterMatchMode.EQUALS
                            },
                            referenceImportId: {
                                value: route.params.id,
                                matchMode: FilterMatchMode.EQUALS
                            }
                        },
                        allEnvs: false
                    })
                        .then((getAllData: any) => {
                            if (getAllData.data.items.length === 1) {
                                lastRunData.value = getAllData.data.items.map(
                                    (obj: any) => ({
                                        ...obj,
                                        processingEndedAt:
                                            obj.processingEndedAtTimestamp
                                                ? DateTime.fromSeconds(
                                                      obj.processingEndedAtTimestamp
                                                  )
                                                      .setLocale(
                                                          i18n.global.locale
                                                      )
                                                      .setZone(
                                                          process.env
                                                              ?.VUE_APP_DEFAULT_TIME_ZONE
                                                      )
                                                      .toLocaleString(
                                                          DateTime.DATETIME_MED_WITH_SECONDS
                                                      )
                                                : null
                                    })
                                )[0];
                            }
                        })
                        .catch((error) => {
                            loading.value = false;
                            toast.error(error.message);
                        });

                    statusChartData.value = setStatusChartData();

                    costPriceChartData.value = setPriceChartData(
                        'EK Diff',
                        [
                            [-1000, -15],
                            [-15, -5],
                            [-5, 0],
                            [0, 5],
                            [5, 15],
                            [15, 1000]
                        ].map(
                            (range: any) =>
                                logs.value.filter(
                                    (item: any) =>
                                        item.costPriceChange &&
                                        calculateEkDiffInPercentage(item) <
                                            range[1] &&
                                        calculateEkDiffInPercentage(item) >=
                                            range[0]
                                ).length
                        )
                    );

                    listPriceChartData.value = setPriceChartData(
                        'UVP Diff',
                        [
                            [-1000, -15],
                            [-15, -5],
                            [-5, 0],
                            [0, 5],
                            [5, 15],
                            [15, 1000]
                        ].map(
                            (range: any) =>
                                logs.value.filter(
                                    (item: any) =>
                                        item.listPriceChange &&
                                        calculateUvpDiffInPercentage(item) <
                                            range[1] &&
                                        calculateUvpDiffInPercentage(item) >=
                                            range[0]
                                ).length
                        )
                    );
                })
                .catch((error) => {
                    toast.error(error.message);
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        const setStatusChartData = () => {
            const documentStyle = getComputedStyle(document.body);
            return {
                labels: [
                    'Status 10002',
                    'Status 10003',
                    'Status 10004',
                    'Status 10005',
                    'Status 10006',
                    'Status 1001',
                    'Status 1002',
                    'Status 1003',
                    'Status 1004'
                ],
                datasets: [
                    {
                        data: [
                            10002, 10003, 10004, 10005, 10006, 1001, 1002, 1003,
                            1004
                        ].map(
                            (status) =>
                                logs.value.filter(
                                    (item: any) => item.status === status
                                ).length
                        ),
                        backgroundColor: [
                            10002, 10003, 10004, 10005, 10006, 1001, 1002, 1003,
                            1004
                        ].map((status, index) => {
                            return documentStyle.getPropertyValue(
                                '--' + colorContainer[index % 9] + '-500'
                            );
                        })
                    }
                ]
            };
        };

        const calculateEkDiffInPercentage = (data: any): number => {
            return data.calculatedCostPrice
                ? ((data.extractedData.costPrice - data.calculatedCostPrice) /
                      data.calculatedCostPrice) *
                      100
                : 100;
        };

        const calculateUvpDiffInPercentage = (data: any): number => {
            return data.calculatedListPrice
                ? ((data.extractedData.listPrice - data.calculatedListPrice) /
                      data.calculatedListPrice) *
                      100
                : 100;
        };

        const setPriceChartData = (label: string, data: Array<any>) => {
            const documentStyle = getComputedStyle(document.documentElement);

            const priceChartColorContainer = [
                'red',
                'yellow',
                'green',
                'green',
                'yellow',
                'red'
            ];

            return {
                labels: ['> -15%', '> -5%', '<= -5%', '<= 5%', '> 5%', '> 15%'],
                datasets: [
                    {
                        label,
                        data,
                        backgroundColor: Array.from({length: 6}, (i) => i).map(
                            (status, index) => {
                                return documentStyle.getPropertyValue(
                                    '--' +
                                        priceChartColorContainer[index] +
                                        '-500'
                                );
                            }
                        ),
                        borderColor: Array.from({length: 6}, (i) => i).map(
                            (status, index) => {
                                return documentStyle.getPropertyValue(
                                    '--' +
                                        priceChartColorContainer[index] +
                                        '-500'
                                );
                            }
                        ),
                        borderWidth: 1
                    }
                ]
            };
        };

        const setChartOptions = () => {
            return {
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        callbacks: {
                            label: function (context: any) {
                                const label = ' ' + context.raw;

                                const initialValue = 0;
                                const sumWithInitial =
                                    context.dataset.data.reduce(
                                        (accumulator: any, currentValue: any) =>
                                            accumulator + currentValue,
                                        initialValue
                                    );

                                return (
                                    label +
                                    ' (' +
                                    (
                                        (context.raw / sumWithInitial) *
                                        100
                                    ).toFixed(2) +
                                    '%)'
                                );
                            }
                        }
                    }
                }
            };
        };

        const onStatusDetailsClick = (status: number) => {
            router.push({
                name: 'Price Import Log Details',
                params: {id: route.params.id},
                query: {status}
            });
        };

        const onBackToListClick = () => {
            router.push({
                name: 'Price Import Logs'
            });
        };

        const onEolDetailsClick = () => {
            router.push({
                name: 'Price Import Log Details',
                params: {id: route.params.id},
                query: {status: [1001, 1002, 1003, 1004]}
            });
        };

        const onMultipleStatusChangeDetailsClick = (
            changeStatus: Array<number>
        ) => {
            router.push({
                name: 'Price Import Log Details',
                params: {id: route.params.id},
                query: {changeStatus}
            });
        };

        const calculateNumberOfItemsForStatus = (status: number) => {
            return logs.value.filter((item: any) => item.status === status)
                .length;
        };

        const calculatePercentageForStatus = (status: number) => {
            return (
                (logs.value.filter((item: any) => item.status === status)
                    .length /
                    logs.value.length) *
                100
            ).toFixed(2);
        };

        const colorContainer = [
            'orange',
            'red',
            'purple',
            'green',
            'pink',
            'cyan',
            'blue',
            'yellow',
            'indigo'
        ];

        const getStatusTooltip = (status: number) => {
            switch (status) {
                case 10002:
                    return 'Artikel in weclapp als "Nicht in PL - Ersatzteil" gekennzeichnet';
                case 10003:
                    return 'Artikel in weclapp inaktiv, in PL vorhanden';
                case 10004:
                    return 'Fehler in PL';
                case 10005:
                    return 'Bestehender EK in der Zukunft';
                case 10006:
                    return 'Bestehender UVP in der Zukunft';
                case 1001:
                    return 'In PL nicht vorhanden';
                case 1002:
                    return 'In PL nicht vorhanden, ist primäre BZQ und mehrere BZQ vorhanden';
                case 1003:
                    return 'In PL nicht vorhanden, ist primäre BZQ und nur eine BZQ vorhanden, ohne Lagerbestand';
                case 1004:
                    return 'In PL nicht vorhanden, ist primäre BZQ und nur eine BZQ vorhanden und Lagerbestand vorhanden';
            }

            return '';
        };

        const setPriceChartOptions = () => {
            const documentStyle = getComputedStyle(document.documentElement);
            const textColorSecondary = documentStyle.getPropertyValue(
                '--text-color-secondary'
            );
            const surfaceBorder =
                documentStyle.getPropertyValue('--surface-border');

            return {
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        callbacks: {
                            label: function (context: any) {
                                const label = ' ' + context.raw;

                                const initialValue = 0;
                                const sumWithInitial =
                                    context.dataset.data.reduce(
                                        (accumulator: any, currentValue: any) =>
                                            accumulator + currentValue,
                                        initialValue
                                    );

                                return (
                                    label +
                                    ' (' +
                                    (
                                        (context.raw / sumWithInitial) *
                                        100
                                    ).toFixed(2) +
                                    '%)'
                                );
                            }
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: textColorSecondary
                        },
                        grid: {
                            color: surfaceBorder
                        }
                    },
                    y: {
                        beginAtZero: true,
                        ticks: {
                            color: textColorSecondary
                        },
                        grid: {
                            color: surfaceBorder
                        }
                    }
                }
            };
        };

        const totalNonChanged = computed(() => {
            return logs.value.filter(
                (item: any) =>
                    item.status < 1000 && (item.status % 32 | 3) === 3
            ).length;
        });

        const totalEol = computed(() => {
            return logs.value.filter((item: any) =>
                [1001, 1002, 1003, 1004].includes(item.status)
            ).length;
        });

        const totalCostPriceChanged = computed(() => {
            return logs.value.filter((item: any) => item.costPriceChange)
                .length;
        });

        const totalListPriceChanged = computed(() => {
            return logs.value.filter((item: any) => item.listPriceChange)
                .length;
        });

        const totalWbtChanged = computed(() => {
            return logs.value.filter((item: any) => item.wbtChange).length;
        });

        const totalIrregular = computed(() => {
            return (
                totalEol.value +
                logs.value.filter((item: any) =>
                    [10002, 10003, 10004, 10005, 10006].includes(item.status)
                ).length
            );
        });

        const runManually = async () => {
            confirm.require({
                message: i18n.global.t('messages.runPriceImportManually', {
                    number:
                        parentData.value.statistics?.regularItemsCount -
                        parentData.value.statistics?.handledItemsCount
                }),
                header: i18n.global.t('messages.pleaseConfirm'),
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: i18n.global.t('labels.yes'),
                rejectLabel: i18n.global.t('labels.no'),
                accept: async () => {
                    try {
                        await runPriceImportManually(parentData.value.id);
                        toast.success(
                            i18n.global.t('messages.cronJobExecutionScheduled')
                        );
                        loadLazyData();
                    } catch (error: any) {
                        toast.error(
                            error.response?.data?.error || error.message
                        );
                    }
                }
            });
        };

        const rejectImport = async () => {
            confirm.require({
                message: i18n.global.t('messages.deleteConfirmation', {
                    item: 'den Preisimport'
                }),
                header: i18n.global.t('messages.pleaseConfirm'),
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: i18n.global.t('labels.yes'),
                rejectLabel: i18n.global.t('labels.no'),
                accept: async () => {
                    try {
                        await removePriceImport(parentData.value.id);
                        toast.success(
                            i18n.global.t('messages.changesSavedSuccessfully')
                        );
                        onBackToListClick();
                    } catch (error: any) {
                        toast.error(
                            error.response?.data?.error || error.message
                        );
                    }
                }
            });
        };

        return {
            logs,
            dt,
            totalRecords,
            loading,
            parentData,
            url,
            formatter: new Intl.NumberFormat(i18n.global.locale, {
                style: 'currency',
                currency: 'EUR'
            }),
            getPlatformCellContent: getPlatformCellContent,
            statusChartData,
            statusChartOptions,
            calculateNumberOfItemsForStatus,
            calculatePercentageForStatus,
            getStatusTooltip,
            colorContainer,
            costPriceChartData,
            costPriceChartOptions,
            listPriceChartData,
            listPriceChartOptions,
            totalCostPriceChanged,
            totalListPriceChanged,
            totalEol,
            totalNonChanged,
            totalWbtChanged,
            lastRunData,
            onStatusDetailsClick,
            onEolDetailsClick,
            onMultipleStatusChangeDetailsClick,
            onBackToListClick,
            totalIrregular,
            runManually,
            rejectImport
        };
    }
};
