import {getFileTempate} from '@/services/import';
import {ref, watch} from 'vue';
import {useToast} from 'vue-toastification';
import Button from 'primevue/button';
import {useDropzone} from 'vue3-dropzone';
import FileSaver from 'file-saver';
import ChooseSpreadsheetDialog from '@/components/dialog/prices/choose-spreadsheet.vue';
import Message from 'primevue/message';

export default {
    name: 'PriceListUploadStep',
    emits: ['sheet-chosen'],
    components: {
        'p-button': Button,
        ChooseSpreadsheetDialog,
        Message
    },
    props: {
        supplierConfiguration: {
            type: Object,
            default: null
        }
    },
    setup(props: any, context: any) {
        const toast = useToast();
        const acceptFiles = ref([]);
        const showChooseSpreadsheetDialog = ref(false);
        const sheetName = ref(null);
        const chosenManufacturer = ref(null);
        const supplierConfiguration = ref(null);

        const savedFile = ref(null);

        const onDrop = (files: any, rejectReasons: any) => {
            acceptFiles.value = files;
            savedFile.value = null;

            if (rejectReasons.length > 0) {
                toast.error(rejectReasons[0]['errors'][0]['message']);
                acceptFiles.value.length = 0;
            } else {
                sheetName.value = null;
                chosenManufacturer.value = null;
                showChooseSpreadsheetDialog.value = true;
            }
        };

        const dropzone = ref(null);
        dropzone.value = useDropzone({
            onDrop,
            accept: ['.xlsx', '.xls']
        });

        const downloadFileTemplate = async () => {
            const file = await getFileTempate('weclapp-price-wizard-add');
            const filename = file.headers['content-disposition']
                .split('filename=')[1]
                .split(';')[0];

            FileSaver.saveAs(
                new Blob([file.data], {
                    type: file.headers['content-type']
                }),
                filename
            );
        };

        const onCloseSpreadsheetDialog = (
            chosenSheetName: any,
            manufacturer: any,
            costPriceStartDate: any,
            listPriceStartDate: any,
            partialPriceList: boolean
        ) => {
            sheetName.value = chosenSheetName;
            chosenManufacturer.value = manufacturer || null;
            showChooseSpreadsheetDialog.value = false;
            context.emit(
                'sheet-chosen',
                acceptFiles.value,
                sheetName.value,
                chosenManufacturer.value?.id,
                costPriceStartDate,
                listPriceStartDate,
                partialPriceList
            );
        };

        watch(props, async (args) => {
            if (
                args.supplierConfiguration !== null &&
                args.supplierConfiguration.supplierNumber !==
                    supplierConfiguration.value?.supplierNumber
            ) {
                supplierConfiguration.value = args.supplierConfiguration;
            }
        });

        return {
            dropzone,
            acceptFiles,
            downloadFileTemplate,
            onCloseSpreadsheetDialog,
            showChooseSpreadsheetDialog,
            sheetName,
            chosenManufacturer,
            supplierConfiguration
        };
    }
};
