import {ref, onMounted, computed, toRefs, watch} from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import LoadingPlugin from 'vue-loading-overlay';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import {FilterMatchMode} from 'primevue/api';
import {useToast} from 'vue-toastification';
import {getAll, updateSingle} from '@/services/crons';
import store from '@/store';
import useVuelidate from '@vuelidate/core';
import {helpers, required, requiredIf} from '@vuelidate/validators';
import Editor from '@tinymce/tinymce-vue';
import Fieldset from 'primevue/fieldset';
import Calendar from 'primevue/calendar';
import Divider from 'primevue/divider';
import {i18n} from '@/utils/i18n';
import CronJobLogsOverview from '@/components/dialog/jobs/logs/cron-job-logs.vue';
import MailLogsOverview from '@/components/dialog/jobs/logs/automated-mail-logs.vue';
import ManualRunDialog from '@/components/dialog/jobs/logs/automated-mail-manual-run.vue';
import MultiSelect from 'primevue/multiselect';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Textarea from 'primevue/textarea';
import Panel from 'primevue/panel';
import {DateTime, Interval} from 'luxon';
import Checkbox from 'primevue/checkbox';

export default {
    components: {
        DataTable,
        Column,
        Calendar,
        'p-dropdown': Dropdown,
        'p-button': Button,
        'p-textarea': Textarea,
        TriStateCheckbox,
        Checkbox,
        loading: LoadingPlugin,
        tiny: Editor,
        'p-fieldset': Fieldset,
        'p-divider': Divider,
        CronJobLogsOverview,
        MailLogsOverview,
        ManualRunDialog,
        MultiSelect,
        InputText,
        InputNumber,
        Panel
    },
    props: {
        cronType: String,
        reloadRequired: {
            type: Number,
            default: 0
        }
    },
    setup(props: any) {
        onMounted(() => {
            filters.value = Object.assign({}, filterDefintions);

            lazyParams.value = {
                first: 0,
                rows: dt.value.rows || 10,
                sortField: 'id',
                sortOrder: isOneTimeTypeOfJob.value ? -1 : 1,
                filters: filters.value
            };

            loadLazyData();
        });

        watch(props, (args) => {
            if (reloadRequired.value !== args.reloadRequired) {
                loadLazyData();
                reloadRequired.value = args.reloadRequired;
            }
        });

        const {cronType} = toRefs(props);
        const dt = ref();
        const loading = ref(false);
        const totalRecords = ref(0);
        const toast = useToast();
        const lazyParams = ref(null);
        const reloadRequired = ref(false);

        const crons = ref([]);
        const expandedRows = ref([]);

        const dialogVisible = ref(false);

        const submitted = ref(false);
        const savingInProgress = ref(false);

        const logsOverviewDialogVisible = ref(false);
        const mailLogsOverviewDialogVisible = ref(false);
        const runDialogVisible = ref(false);

        const state = ref({
            automatic: false,
            cycle: null,
            startTime: null,
            emailToAddress: null,
            emailCcAddress: null,
            emailSubject: {
                de: null,
                en: null
            },
            emailBody: {
                de: null,
                en: null
            },
            daysIndices: [],
            minuteInterval: null
        });

        const cycleValueValid = (value: any) => {
            return (
                state.value['automatic'] ||
                ['1', '2', '3', '4'].indexOf(value) !== -1
            );
        };

        const rules = {
            automatic: {required},
            cycle: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return state.value['automatic'];
                    })
                ),
                cycleValueValid
            },
            startTime: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return state.value['automatic'];
                    })
                )
            },
            emailToAddress: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return isMailOnlyTypeOfJob.value;
                    })
                )
            },
            emailCcAddress: {},
            emailSubject: {
                de: {
                    required: helpers.withMessage(
                        i18n.global.t('messages.valueIsRequired'),
                        requiredIf(() => {
                            return isMailOnlyTypeOfJob.value;
                        })
                    )
                },
                en: {}
            },
            emailBody: {
                de: {
                    required: helpers.withMessage(
                        i18n.global.t('messages.valueIsRequired'),
                        requiredIf(() => {
                            return isMailOnlyTypeOfJob.value;
                        })
                    )
                },
                en: {}
            },
            daysIndices: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return (
                            state.value['automatic'] &&
                            ['3', '4'].indexOf(state.value.cycle) !== -1
                        );
                    })
                )
            },
            minuteInterval: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return (
                            state.value['automatic'] &&
                            state.value.cycle === '1'
                        );
                    })
                )
            }
        };

        const v$ = useVuelidate(rules, state);

        const selectedRow = ref(null);

        const filterDefintions: any = {
            automatic: {value: null, matchMode: FilterMatchMode.EQUALS},
            manuallyInitiatedBy: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            name: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            }
        };

        const filters = ref(filterDefintions);

        const loadLazyData = () => {
            loading.value = true;

            getAll(lazyParams.value, cronType.value)
                .then((data) => {
                    totalRecords.value = data.data.total;
                    crons.value = data.data.items.map((item: any) => ({
                        ...item,
                        selectedLanguage: 'de',
                        lastRunAtFormatted: item.lastRunAt
                            ? DateTime.fromSeconds(item.lastRunAt)
                                  .setLocale(i18n.global.locale)
                                  .setZone(
                                      process.env?.VUE_APP_DEFAULT_TIME_ZONE
                                  )
                                  .toLocaleString(
                                      DateTime.DATETIME_MED_WITH_SECONDS
                                  )
                            : null,
                        startTimeFormatted: item.startTime
                            ? DateTime.fromSQL(item.startTime)
                                  .setLocale(i18n.global.locale)
                                  .setZone(
                                      process.env?.VUE_APP_DEFAULT_TIME_ZONE
                                  )
                                  .toLocaleString(
                                      DateTime.DATETIME_MED_WITH_SECONDS
                                  )
                            : null,
                        averageExecution: item.averageExecutionTime
                            ? Interval.fromDateTimes(
                                  DateTime.fromSeconds(0, {
                                      locale: i18n.global.locale
                                  }),
                                  DateTime.fromSeconds(
                                      item.averageExecutionTime,
                                      {locale: i18n.global.locale}
                                  )
                              )
                                  .toDuration(['minutes', 'seconds'])
                                  .toHuman({
                                      unitDisplay: 'short'
                                  })
                            : null
                    }));

                    loading.value = false;
                })
                .catch((error) => {
                    loading.value = false;
                    toast.error(error.message);
                });
        };

        const onPage = (event: any) => {
            lazyParams.value = event;
            loadLazyData();
        };

        const onFilter = () => {
            lazyParams.value.filters = filters.value;
            lazyParams.value.first = 0;
            loadLazyData();
        };

        const onSort = (event: any) => {
            lazyParams.value = event;
            loadLazyData();
        };

        const editPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'];
            return cronType.value === 'net-rivals'
                ? user?.permissions?.indexOf('net-rivals-edit') !== -1
                : user?.permissions?.indexOf('cron-jobs-edit') !== -1;
        });

        const handleDialogOpen = (event: any, item: any) => {
            submitted.value = false;
            dialogVisible.value = true;
            selectedRow.value = item;
            const {
                automatic,
                emailToAddress,
                emailCcAddress,
                emailSubject,
                emailBody,
                cycle,
                startTime,
                daysIndices,
                minuteInterval
            } = item;

            const date = new Date();
            if (startTime) {
                const exploded = startTime.split(':');
                date.setHours(exploded[0]);
                date.setMinutes(exploded[1]);
            }

            Object.assign(state.value, {
                automatic,
                emailToAddress,
                emailCcAddress,
                emailSubject,
                emailBody,
                cycle: cycle.toString(),
                startTime: date,
                daysIndices,
                minuteInterval
            });
        };

        const handleDialogClose = () => {
            Object.assign(state.value, {
                automatic: null,
                emailToAddress: null,
                emailCcAddress: null,
                emailSubject: null,
                emailBody: null,
                cycle: null,
                startTime: null,
                daysIndices: [],
                minuteInterval: null
            });
            dialogVisible.value = false;
            selectedRow.value = null;
        };

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            savingInProgress.value = true;

            updateSingle(selectedRow.value?.id, state.value)
                .then(() => {
                    toast.success(
                        i18n.global.t('messages.changesSavedSuccessfully')
                    );
                    handleDialogClose();
                    loadLazyData();
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    savingInProgress.value = false;
                });
        };

        const handleLogsClick = (event: any, data: any) => {
            selectedRow.value = data;
            logsOverviewDialogVisible.value = true;
        };

        const handleMailLogsClick = (event: any, data: any) => {
            selectedRow.value = data;
            mailLogsOverviewDialogVisible.value = true;
        };

        const handleRunDialogOpen = (event: any, data: any) => {
            selectedRow.value = data;
            runDialogVisible.value = true;
        };

        const onCycleChange = (event: any) => {
            Object.assign(state.value, {
                cycle: event.target.value,
                daysIndices: []
            });
        };

        const onExecutionChange = (event: any) => {
            Object.assign(state.value, {
                automatic: event.target.value
            });
        };

        const isMailOnlyTypeOfJob = computed(() => {
            return (
                cronType.value === 'alert' || cronType.value === 'notification'
            );
        });

        const isOneTimeTypeOfJob = computed(() => {
            return cronType.value === 'one-time';
        });

        const switchCronTemplateLanguage = (
            cronId: string,
            chosenLanguage: string
        ) => {
            const index = crons.value.findIndex(
                (item: any) => item.id === cronId
            );

            if (index !== -1) {
                crons.value[index] = Object.assign(crons.value[index], {
                    selectedLanguage: chosenLanguage
                });
            }
        };

        const cleanUpLengthyProperties = (data: any) => {
            if ((data?.data?.mappedSkus || []).length > 10) {
                const toReturn: any = (({...o}) => o)(data);

                toReturn['data']['mappedSkus'] = 'More than 10 items...';

                return toReturn;
            }

            return data;
        };

        return {
            crons,
            cronType,
            dt,
            totalRecords,
            loading,
            filters,
            onPage,
            onFilter,
            onSort,
            expandedRows,
            editPermissionAvailable,
            v$,
            submitted,
            savingInProgress,
            handleSubmit,
            handleDialogOpen,
            handleDialogClose,
            dialogVisible,
            selectedRow,
            logsOverviewDialogVisible,
            mailLogsOverviewDialogVisible,
            handleLogsClick,
            handleMailLogsClick,
            runDialogVisible,
            handleRunDialogOpen,
            onCycleChange,
            onExecutionChange,
            daysOfWeekOptions: Array.from({length: 7}, (x, i) => i + 1).map(
                (item) => {
                    return {
                        label: i18n.global.t('labels.mail.dayOfWeek' + item),
                        value: item
                    };
                }
            ),
            daysOfMonthOptions: Array.from({length: 31}, (x, i) => i + 1).map(
                (item) => {
                    return {
                        label: item,
                        value: item
                    };
                }
            ),
            isMailOnlyTypeOfJob,
            isOneTimeTypeOfJob,
            switchCronTemplateLanguage,
            tinyUrl: process.env?.VUE_APP_TINYMCE_URL,
            cleanUpLengthyProperties
        };
    }
};
