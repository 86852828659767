import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "w-full flex justify-content-between" }
const _hoisted_2 = { class: "flex align-items-center" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "mt-2" }
const _hoisted_5 = { class: "w-full m-2 flex justify-content-between" }
const _hoisted_6 = { class: "flex align-content-center" }
const _hoisted_7 = { style: {"vertical-align":"middle"} }
const _hoisted_8 = ["href"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { style: {"vertical-align":"middle"} }
const _hoisted_11 = {
  key: 0,
  class: "pi pi-exclamation-triangle"
}
const _hoisted_12 = { class: "mt-2" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "mt-3 flex justify-content-end" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { class: "text-xs" }
const _hoisted_17 = {
  key: 1,
  class: "font-bold"
}
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { key: 0 }
const _hoisted_23 = { class: "mb-2" }
const _hoisted_24 = { style: {"vertical-align":"middle"} }
const _hoisted_25 = { key: 0 }
const _hoisted_26 = { key: 0 }
const _hoisted_27 = { key: 0 }
const _hoisted_28 = { style: {"vertical-align":"middle"} }
const _hoisted_29 = { key: 0 }
const _hoisted_30 = { style: {"vertical-align":"middle"} }
const _hoisted_31 = {
  key: 0,
  class: "pi pi-exclamation-triangle",
  style: {"margin-left":"0.5rem","vertical-align":"middle"}
}
const _hoisted_32 = { style: {"vertical-align":"middle"} }
const _hoisted_33 = {
  key: 0,
  class: "pi pi-exclamation-triangle",
  style: {"margin-left":"0.5rem","vertical-align":"middle"}
}
const _hoisted_34 = { style: {"vertical-align":"middle"} }
const _hoisted_35 = {
  key: 0,
  class: "pi pi-exclamation-triangle"
}
const _hoisted_36 = { style: {"vertical-align":"middle"} }
const _hoisted_37 = { key: 0 }
const _hoisted_38 = { key: 1 }
const _hoisted_39 = { key: 2 }
const _hoisted_40 = { class: "flex align-content-center" }
const _hoisted_41 = { key: 0 }
const _hoisted_42 = { key: 0 }
const _hoisted_43 = { class: "font-semibold" }
const _hoisted_44 = ["href"]
const _hoisted_45 = ["href"]
const _hoisted_46 = { class: "pi pi-calendar text-color mr-2" }
const _hoisted_47 = { class: "fa-box-open fas text-color text-lg mr-2" }
const _hoisted_48 = { class: "pi pi-clone text-color mr-2" }
const _hoisted_49 = { class: "pi pi-pencil text-color" }
const _hoisted_50 = ["innerHTML"]
const _hoisted_51 = { key: 1 }
const _hoisted_52 = { class: "font-bold" }
const _hoisted_53 = ["innerHTML"]

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_p_button = _resolveComponent("p-button")
  const _component_SalesChannelSwitcher = _resolveComponent("SalesChannelSwitcher")
  const _component_p_multiselect = _resolveComponent("p-multiselect")
  const _component_FilterMenu = _resolveComponent("FilterMenu")
  const _component_Column = _resolveComponent("Column")
  const _component_Badge = _resolveComponent("Badge")
  const _component_BulkActionsMenu = _resolveComponent("BulkActionsMenu")
  const _component_InputText = _resolveComponent("InputText")
  const _component_Calendar = _resolveComponent("Calendar")
  const _component_Tag = _resolveComponent("Tag")
  const _component_CategoriesTree = _resolveComponent("CategoriesTree")
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")
  const _component_TriStateCheckbox = _resolveComponent("TriStateCheckbox")
  const _component_Dropdown = _resolveComponent("Dropdown")
  const _component_router_link = _resolveComponent("router-link")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_PrimePanel = _resolveComponent("PrimePanel")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_PrimePanel, { class: "panel-container-datatable" }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('labels.products')), 1),
            _createVNode(_component_p_button, {
              class: "ml-2",
              onClick: _ctx.onAddNewProductClick,
              size: "small",
              severity: "success"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("buttons.addNewGeneric")), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_SalesChannelSwitcher)
          ]),
          _createVNode(_component_p_multiselect, {
            class: "text-sm",
            modelValue: _ctx.selectedColumns,
            ref: "columnSelectorComponent",
            options: _ctx.columns,
            optionLabel: "header",
            optionValue: "field",
            optionDisabled: "optionDisabled",
            onChange: _ctx.onToggleColumnVisibility,
            placeholder: "Select Columns",
            style: {"min-width":"5rem","max-width":"20rem"}
          }, {
            footer: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_p_button, {
                  severity: "danger",
                  size: "small",
                  onClick: _ctx.onCancelColumnSelection
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_p_button, {
                  severity: "success",
                  size: "small",
                  onClick: _ctx.onConfirmColumnSelection
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("buttons.confirm")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "options", "onChange"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_DataTable, {
          class: "p-datatable-sm text-sm",
          paginator: "",
          rows: 10,
          lazy: "",
          scrollable: "",
          scrollHeight: "calc(100vh - 25rem)",
          ref: "dt",
          dataKey: "id",
          totalRecords: _ctx.totalRecords,
          value: _ctx.products,
          "paginator-template": 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown',
          "rows-per-page-options": [10,20,50,100,200],
          "current-page-report-template": '{first} to {last} of {totalRecords}',
          filters: _ctx.filters,
          "onUpdate:filters": _cache[6] || (_cache[6] = $event => ((_ctx.filters) = $event)),
          filterDisplay: "row",
          onPage: _cache[7] || (_cache[7] = $event => (_ctx.onPage($event))),
          onFilter: _cache[8] || (_cache[8] = $event => (_ctx.onFilter($event))),
          onSort: _cache[9] || (_cache[9] = $event => (_ctx.onSort($event))),
          onStateSave: _ctx.onStateSave,
          onStateRestore: _ctx.onStateRestore,
          expandedRows: _ctx.expandedRows,
          "onUpdate:expandedRows": _cache[10] || (_cache[10] = $event => ((_ctx.expandedRows) = $event)),
          selection: _ctx.selectedProducts,
          "onUpdate:selection": _cache[11] || (_cache[11] = $event => ((_ctx.selectedProducts) = $event)),
          stateStorage: "local",
          stateKey: "products-list-state-session",
          onRowSelect: _ctx.onRowSelect,
          onRowSelectAll: _ctx.onRowSelectAll,
          onRowUnselect: _ctx.onRowUnselect,
          onRowUnselectAll: _ctx.onRowUnselectAll
        }, {
          expansion: _withCtx((slotProps) => [
            (slotProps.data?.shopwareData?.description || slotProps.data?.description)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  innerHTML: slotProps.data?.shopwareData?.description || slotProps.data?.description
                }, null, 8, _hoisted_50))
              : (_openBlock(), _createElementBlock("div", _hoisted_51, "No description available."))
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              expander: true,
              style: {"min-width":"4rem","max-width":"fit-content"},
              class: "tt-filter-menu-th"
            }, {
              filter: _withCtx(() => [
                _createVNode(_component_FilterMenu, {
                  "parent-component-id": "product-list",
                  "parent-filters": _ctx.filters,
                  onClearFilters: _ctx.clearFilters,
                  onApplyFilters: _ctx.applyFilters
                }, null, 8, ["parent-filters", "onClearFilters", "onApplyFilters"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              selectionMode: "multiple",
              style: {"min-width":"3rem","max-width":"6rem"}
            }, {
              header: _withCtx(() => [
                _createVNode(_component_Badge, {
                  value: _ctx.mergedSelection.length
                }, null, 8, ["value"]),
                (_ctx.mergedSelection.length > 0)
                  ? (_openBlock(), _createElementBlock("i", {
                      key: 0,
                      class: "pi pi-times-circle text-red-600",
                      style: {"padding-left":"7px","cursor":"pointer"},
                      onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.clearSelection && _ctx.clearSelection(...args)))
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_BulkActionsMenu, {
                  mergedSelection: _ctx.mergedSelection,
                  totalRecords: _ctx.totalRecords,
                  filters: _ctx.filters,
                  visibleColumns: Object.keys(_ctx.filters),
                  onForceDataRefresh: _ctx.onCloseBulkBadgesCategoriesDialog
                }, null, 8, ["mergedSelection", "totalRecords", "filters", "visibleColumns", "onForceDataRefresh"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "articleNumber",
              sortable: true,
              header: _ctx.$t('labels.articleNumber'),
              style: {"min-width":"13rem"}
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs",
                  placeholder: _ctx.$t('labels.articleNumber')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["sales-channel-dot w-1rem h-1rem mr-1", {'sales-channel-teltec':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.shopwareData?.active, 'sales-channel-videodata':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.shopwareData?.active, 'sales-channel-teltec-border':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.shopwareData && !data.shopwareData.active, 'sales-channel-videodata-border':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.shopwareData && !data.shopwareData.active, 'bg-gray-200': !data?.shopwareData?.active}])
                  }, null, 2),
                  _createElementVNode("div", {
                    class: _normalizeClass(["sales-channel-dot w-1rem h-1rem mr-1", {'sales-channel-videodata':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.secondShopwareData?.active, 'sales-channel-teltec':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.secondShopwareData?.active, 'sales-channel-videodata-border':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.secondShopwareData && !data.secondShopwareData.active, 'sales-channel-teltec-border':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.secondShopwareData && !data.secondShopwareData.active, 'bg-gray-200': !data?.secondShopwareData?.active}])
                  }, null, 2),
                  _createElementVNode("span", _hoisted_7, [
                    _createElementVNode("a", {
                      href: data.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + data.id,
                      target: "_blank"
                    }, _toDisplayString(data.articleNumber), 9, _hoisted_8)
                  ]),
                  (_ctx.showCommentsColumn && data.comments.length > 0)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: "ml-3",
                        style: {"cursor":"pointer"},
                        onClick: $event => (_ctx.showCommentsDialog(data))
                      }, [
                        _cache[14] || (_cache[14] = _createElementVNode("i", {
                          class: "pi pi-comments",
                          style: {"padding-right":"5px"}
                        }, null, -1)),
                        _createTextVNode(" " + _toDisplayString(data.comments.length), 1)
                      ], 8, _hoisted_9))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, { style: {"min-width":"12rem"} }, {
              header: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.articleNumber')) + " ", 1),
                _cache[15] || (_cache[15] = _createElementVNode("div", { class: "sales-channel-dot sales-channel-videodata w-1rem h-1rem ml-1" }, null, -1))
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("span", null, _toDisplayString(data.customAttributes?.article_webshop_VDHarticleNumber), 1)
              ]),
              _: 1
            }),
            (_ctx.visibleColumns.indexOf('createdDate') !== -1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 0,
                  field: "createdDate",
                  dataType: "date",
                  sortable: true,
                  header: _ctx.$t('labels.createdAt'),
                  style: {"min-width":"10rem"}
                }, {
                  filter: _withCtx(({filterModel}) => [
                    _createVNode(_component_Calendar, {
                      class: "p-column-filter text-xs",
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                      dateFormat: "dd.mm.yy"
                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                  ]),
                  body: _withCtx((data) => [
                    _createTextVNode(_toDisplayString(new Date(data.data.createdDate).toLocaleDateString("de-DE")), 1)
                  ]),
                  _: 1
                }, 8, ["header"]))
              : _createCommentVNode("", true),
            _createVNode(_component_Column, {
              field: "name",
              sortable: true,
              style: {"min-width":"12rem"},
              header: _ctx.$t('labels.articleName')
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs",
                  placeholder: _ctx.$t('labels.name')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("span", _hoisted_10, _toDisplayString(data.name || data.shopwareData?.name), 1),
                (data.shopwareData && !_ctx.isValueEqualCaseInsensitive(data.name || null, data.shopwareData.name || null))
                  ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_11, null, 512)), [
                      [
                        _directive_tooltip,
                        _ctx.$t('labels.inconsistentData') + ': ' + _ctx.$t('labels.name'),
                        void 0,
                        { top: true }
                      ]
                    ])
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_12, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.tags, (tag) => {
                    return (_openBlock(), _createBlock(_component_Tag, {
                      severity: "info",
                      class: "mt-1 mr-1"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(tag.toUpperCase().replace('TTC-PRODUCT-META-',
                            '')), 1)
                      ]),
                      _: 2
                    }, 1024))
                  }), 256))
                ])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, { style: {"min-width":"12rem"} }, {
              header: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.articleName')) + " ", 1),
                _cache[16] || (_cache[16] = _createElementVNode("div", { class: "sales-channel-dot sales-channel-videodata w-1rem h-1rem ml-1" }, null, -1))
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("span", null, _toDisplayString(data.customAttributes?.article_webshop_VDHname), 1)
              ]),
              _: 1
            }),
            (_ctx.visibleColumns.indexOf('description') !== -1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 1,
                  field: "description",
                  style: {"min-width":"12rem"}
                }, {
                  filter: _withCtx(({filterModel,filterCallback}) => [
                    _createVNode(_component_InputText, {
                      type: "text",
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                      onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                      class: "p-column-filter text-xs",
                      placeholder: _ctx.$t('labels.productDescription.webshopText')
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
                  ]),
                  header: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('labels.productDescription.webshopText')) + " ", 1),
                    _createElementVNode("div", {
                      class: _normalizeClass(["sales-channel-dot w-1rem h-1rem ml-1", {'sales-channel-teltec':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
                    }, null, 2)
                  ]),
                  body: _withCtx(({data}) => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.stripTagsAndTruncate(data.shopwareData?.description,
                    400, '...')), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.visibleColumns.indexOf('description') !== -1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 2,
                  style: {"min-width":"12rem"}
                }, {
                  header: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('labels.productDescription.webshopText')) + " ", 1),
                    _createElementVNode("div", {
                      class: _normalizeClass(["sales-channel-dot w-1rem h-1rem ml-1", {'sales-channel-videodata':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-teltec':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
                    }, null, 2)
                  ]),
                  body: _withCtx(({data}) => [
                    (data.secondShopwareData?.description)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.stripTagsAndTruncate(data.secondShopwareData?.description,
                    400, '...')), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.visibleColumns.indexOf('productCategory') !== -1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 3,
                  field: "shopCategoryId",
                  style: {"min-width":"12rem"},
                  filterMatchModeOptions: [{label: 'Equals', value: 'in'}]
                }, {
                  filter: _withCtx(({filterModel,filterCallback}) => [
                    _createVNode(_component_InputText, {
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                      onClick: _cache[2] || (_cache[2] = $event => {_ctx.displayCategoriesFilterDialog = true;}),
                      onKeydown: _cache[3] || (_cache[3] = _withModifiers($event => {_ctx.displayCategoriesFilterDialog = true;}, ["prevent"])),
                      class: "p-column-filter text-xs",
                      placeholder: _ctx.$t('labels.articleCategory')
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"]),
                    _createVNode(_component_PrimeDialog, {
                      visible: _ctx.displayCategoriesFilterDialog,
                      "onUpdate:visible": _cache[5] || (_cache[5] = $event => ((_ctx.displayCategoriesFilterDialog) = $event)),
                      header: _ctx.$t('messages.pleaseSelectOneOrMoreCategories'),
                      breakpoints: {'960px': '75vw'},
                      style: {width: '50vw'},
                      modal: "",
                      closable: false
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ScrollPanel, { style: {"height":"calc(100vh - 38rem)"} }, {
                          default: _withCtx(() => [
                            _createVNode(_component_CategoriesTree, {
                              "enable-parent-category-selection": "",
                              "enable-child-tree-checkbox": "",
                              showSearchField: "",
                              onSelectionComplete: _ctx.onCategoriesFilterSelection
                            }, null, 8, ["onSelectionComplete"])
                          ]),
                          _: 1
                        }),
                        _createElementVNode("div", _hoisted_14, [
                          _createVNode(_component_p_button, {
                            severity: "danger",
                            onClick: _cache[4] || (_cache[4] = $event => {_ctx.displayCategoriesFilterDialog = false;}),
                            textContent: _toDisplayString(_ctx.$t('buttons.cancel')),
                            class: "text-sm mr-3"
                          }, null, 8, ["textContent"]),
                          _createVNode(_component_p_button, {
                            class: "text-sm",
                            severity: "success",
                            textContent: _toDisplayString(_ctx.$t('buttons.confirm')),
                            onClick: $event => (_ctx.handleCategoriesFilterSubmit(filterModel, filterCallback))
                          }, null, 8, ["textContent", "onClick"])
                        ])
                      ]),
                      _: 2
                    }, 1032, ["visible", "header"]),
                    (_ctx.filterCategoriesLabels)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterCategoriesLabels, (label, index) => {
                            return _renderSlot(_ctx.$slots, "default", {}, () => [
                              (index<3)
                                ? (_openBlock(), _createBlock(_component_Tag, {
                                    key: 0,
                                    severity: "info",
                                    class: "mr-1 mt-1"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("span", _hoisted_16, _toDisplayString(label.label.toUpperCase()), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                : _createCommentVNode("", true),
                              (index===3)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_17, "..."))
                                : _createCommentVNode("", true)
                            ], true)
                          }), 256))
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  header: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('labels.articleCategory')) + " ", 1),
                    _createElementVNode("div", {
                      class: _normalizeClass(["sales-channel-dot w-1rem h-1rem ml-1", {'sales-channel-teltec':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
                    }, null, 2)
                  ]),
                  body: _withCtx(({data}) => [
                    (data?.shopwareData?.categories)
                      ? (_openBlock(), _createElementBlock("ul", _hoisted_18, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.shopwareData.categories, (element) => {
                            return (_openBlock(), _createElementBlock("li", null, _toDisplayString(_ctx.renderShopwareCategory(element)), 1))
                          }), 256))
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 3
                }))
              : _createCommentVNode("", true),
            (_ctx.visibleColumns.indexOf('productCategory') !== -1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 4,
                  style: {"min-width":"12rem"}
                }, {
                  header: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('labels.articleCategory')) + " ", 1),
                    _createElementVNode("div", {
                      class: _normalizeClass(["sales-channel-dot w-1rem h-1rem ml-1", {'sales-channel-videodata':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-teltec':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
                    }, null, 2)
                  ]),
                  body: _withCtx(({data}) => [
                    (data?.secondShopwareData?.categories)
                      ? (_openBlock(), _createElementBlock("ul", _hoisted_19, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.secondShopwareData.categories, (element) => {
                            return (_openBlock(), _createElementBlock("li", null, _toDisplayString(_ctx.renderShopwareCategory(element, true)), 1))
                          }), 256))
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.visibleColumns.indexOf('productBadges') !== -1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 5,
                  field: "productBadges",
                  header: _ctx.$t('labels.badges'),
                  style: {"min-width":"12rem"},
                  filterMatchModeOptions: [{label: 'Contains', value: 'contains'}, {label: 'Not contains', value: 'notContains'}]
                }, {
                  filter: _withCtx(({filterModel,filterCallback}) => [
                    _createVNode(_component_InputText, {
                      type: "text",
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                      onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                      class: "p-column-filter text-xs",
                      placeholder: _ctx.$t('labels.badges')
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
                  ]),
                  body: _withCtx(({data}) => [
                    (data?.shopwareData?.badges)
                      ? (_openBlock(), _createElementBlock("ul", _hoisted_20, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.shopwareData.badges, (element) => {
                            return (_openBlock(), _createElementBlock(_Fragment, null, [
                              (_ctx.getBadgeName(element))
                                ? (_openBlock(), _createElementBlock("li", _hoisted_21, _toDisplayString(_ctx.getBadgeName(element)), 1))
                                : _createCommentVNode("", true)
                            ], 64))
                          }), 256))
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["header"]))
              : _createCommentVNode("", true),
            (_ctx.visibleColumns.indexOf('productDiscounts') !== -1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 6,
                  field: "productDiscounts",
                  header: _ctx.$t('labels.discounts') + ' (SW6)',
                  style: {"min-width":"12rem"}
                }, {
                  body: _withCtx(({data}) => [
                    (data?.shopwareData?.promotions)
                      ? (_openBlock(), _createElementBlock("ul", _hoisted_22, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.shopwareData.promotions, (element) => {
                            return (_openBlock(), _createElementBlock("li", _hoisted_23, _toDisplayString(element.name), 1))
                          }), 256))
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["header"]))
              : _createCommentVNode("", true),
            (_ctx.visibleColumns.indexOf('articleCategoryName') !== -1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 7,
                  field: "articleCategoryName",
                  style: {"min-width":"13rem"},
                  sortable: true,
                  header: _ctx.$t('labels.articleWeclappCategory')
                }, {
                  filter: _withCtx(({filterModel,filterCallback}) => [
                    _createVNode(_component_InputText, {
                      type: "text",
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                      onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                      class: "p-column-filter text-xs",
                      placeholder: _ctx.$t('labels.articleWeclappCategory')
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["header"]))
              : _createCommentVNode("", true),
            (_ctx.visibleColumns.indexOf('shortDescription1') !== -1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 8,
                  header: _ctx.$t('labels.productDescription.shortDescription'),
                  field: "shortDescription1",
                  style: {"min-width":"10rem"}
                }, {
                  filter: _withCtx(({filterModel,filterCallback}) => [
                    _createVNode(_component_InputText, {
                      type: "text",
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                      onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                      class: "p-column-filter text-xs"
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
                  ]),
                  _: 1
                }, 8, ["header"]))
              : _createCommentVNode("", true),
            (_ctx.visibleColumns.indexOf('weclappDescription') !== -1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 9,
                  field: "weclappDescription",
                  style: {"min-width":"12rem"},
                  header: _ctx.$t('labels.description') + ' [Weclapp]'
                }, {
                  filter: _withCtx(({filterModel,filterCallback}) => [
                    _createVNode(_component_InputText, {
                      type: "text",
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                      onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                      class: "p-column-filter text-xs"
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
                  ]),
                  body: _withCtx(({data}) => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.stripTagsAndTruncate(data.description || '', 400, '...')), 1)
                  ]),
                  _: 1
                }, 8, ["header"]))
              : _createCommentVNode("", true),
            (_ctx.visibleColumns.indexOf('stock') !== -1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 10,
                  header: _ctx.$t('labels.stock'),
                  field: "availableStock",
                  style: {"min-width":"8rem"},
                  filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
                }, {
                  filter: _withCtx(({filterModel, filterCallback}) => [
                    _createVNode(_component_TriStateCheckbox, {
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                      onChange: $event => (filterCallback())
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                  ]),
                  body: _withCtx(({data}) => [
                    _createTextVNode(_toDisplayString(data.shopwareData?.availableStock), 1)
                  ]),
                  _: 1
                }, 8, ["header"]))
              : _createCommentVNode("", true),
            (_ctx.visibleColumns.indexOf('stockInSalesOrders') !== -1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 11,
                  header: _ctx.$t('labels.stockInSalesOrders'),
                  style: {"min-width":"3rem"}
                }, {
                  body: _withCtx(({data}) => [
                    _createTextVNode(_toDisplayString(_ctx.stockInSalesOrdersCalculation(data)), 1)
                  ]),
                  _: 1
                }, 8, ["header"]))
              : _createCommentVNode("", true),
            (_ctx.visibleColumns.indexOf('stockInInternalShipments') !== -1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 12,
                  header: _ctx.$t('labels.stockInInternalShipments'),
                  style: {"min-width":"3rem"}
                }, {
                  body: _withCtx(({data}) => [
                    _createTextVNode(_toDisplayString(_ctx.stockInInternalShipmentsCalculation(data)), 1)
                  ]),
                  _: 1
                }, 8, ["header"]))
              : _createCommentVNode("", true),
            (_ctx.visibleColumns.indexOf('accessories') !== -1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 13,
                  field: "accessories",
                  style: {"min-width":"13rem"},
                  filterMatchModeOptions: [
                        {label: 'Contains', value: 'contains'}
                    ]
                }, {
                  filter: _withCtx(({filterModel,filterCallback}) => [
                    _createVNode(_component_InputText, {
                      type: "text",
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                      onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                      class: "p-column-filter text-xs",
                      placeholder: _ctx.$t('labels.articleAccessories')
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
                  ]),
                  header: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('labels.articleAccessories')) + " ", 1),
                    _createElementVNode("div", {
                      class: _normalizeClass(["sales-channel-dot w-1rem h-1rem ml-2", {'sales-channel-teltec':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
                    }, null, 2)
                  ]),
                  body: _withCtx(({data}) => [
                    _createElementVNode("div", null, [
                      _createElementVNode("span", _hoisted_24, [
                        (data.shopwareData?.accessories && data.shopwareData.accessories.length > 0)
                          ? (_openBlock(), _createElementBlock("ul", _hoisted_25, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((data.shopwareData.accessories.slice(0, 5)), (element) => {
                                return (_openBlock(), _createElementBlock("li", null, _toDisplayString(element.name) + " [" + _toDisplayString(element.productNumber) + "] ", 1))
                              }), 256)),
                              (data.shopwareData.accessories.length > 5)
                                ? (_openBlock(), _createElementBlock("li", _hoisted_26, [
                                    _cache[17] || (_cache[17] = _createTextVNode(" ... ")),
                                    _createElementVNode("b", null, "(Total: " + _toDisplayString(data.shopwareData.accessories.length) + ")", 1)
                                  ]))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.visibleColumns.indexOf('accessories') !== -1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 14,
                  style: {"min-width":"13rem"}
                }, {
                  header: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('labels.articleAccessories')) + " ", 1),
                    _createElementVNode("div", {
                      class: _normalizeClass(["sales-channel-dot w-1rem h-1rem ml-2", {'sales-channel-videodata':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-teltec':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
                    }, null, 2)
                  ]),
                  body: _withCtx(({data}) => [
                    (data.secondShopwareData?.accessories && data.secondShopwareData.accessories.length > 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                          _createElementVNode("span", _hoisted_28, [
                            _createElementVNode("ul", null, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((data.secondShopwareData.accessories.slice(0, 5)), (element) => {
                                return (_openBlock(), _createElementBlock("li", null, _toDisplayString(element.name) + " [" + _toDisplayString(element.productNumber) + "] ", 1))
                              }), 256)),
                              (data.secondShopwareData.accessories.length > 5)
                                ? (_openBlock(), _createElementBlock("li", _hoisted_29, [
                                    _cache[18] || (_cache[18] = _createTextVNode(" ... ")),
                                    _createElementVNode("b", null, "(Total: " + _toDisplayString(data.secondShopwareData.accessories.length) + ")", 1)
                                  ]))
                                : _createCommentVNode("", true)
                            ])
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.visibleColumns.indexOf('ean') !== -1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 15,
                  field: "ean",
                  sortable: true,
                  style: {"min-width":"10rem"},
                  header: _ctx.$t('labels.ean')
                }, {
                  filter: _withCtx(({filterModel,filterCallback}) => [
                    _createVNode(_component_InputText, {
                      type: "text",
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                      onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                      class: "p-column-filter text-xs",
                      placeholder: _ctx.$t('labels.ean')
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
                  ]),
                  body: _withCtx(({data}) => [
                    _createElementVNode("span", _hoisted_30, _toDisplayString(data.ean || data.shopwareData?.ean), 1),
                    (data.shopwareData && !_ctx.isValueEqualCaseInsensitive(data.ean || null, data.shopwareData.ean || null))
                      ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_31, null, 512)), [
                          [
                            _directive_tooltip,
                            _ctx.$t('labels.inconsistentData') + ': ' + _ctx.$t('labels.ean'),
                            void 0,
                            { top: true }
                          ]
                        ])
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["header"]))
              : _createCommentVNode("", true),
            (_ctx.visibleColumns.indexOf('manufacturerPartNumber') !== -1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 16,
                  field: "manufacturerPartNumber",
                  sortable: true,
                  style: {"min-width":"10rem"},
                  header: _ctx.$t('labels.mpn')
                }, {
                  filter: _withCtx(({filterModel,filterCallback}) => [
                    _createVNode(_component_InputText, {
                      type: "text",
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                      onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                      class: "p-column-filter text-xs",
                      placeholder: _ctx.$t('labels.mpn')
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
                  ]),
                  body: _withCtx(({data}) => [
                    _createElementVNode("span", _hoisted_32, _toDisplayString(data.manufacturerPartNumber || data.shopwareData?.mpn), 1),
                    (data.shopwareData && !_ctx.isValueEqualCaseInsensitive(data.manufacturerPartNumber || null, data.shopwareData.mpn || null))
                      ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_33, null, 512)), [
                          [
                            _directive_tooltip,
                            _ctx.$t('labels.inconsistentData') + ': ' + _ctx.$t('labels.mpn'),
                            void 0,
                            { top: true }
                          ]
                        ])
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["header"]))
              : _createCommentVNode("", true),
            (_ctx.visibleColumns.indexOf('manufacturerName') !== -1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 17,
                  field: "manufacturerName",
                  sortable: true,
                  style: {"min-width":"10rem"},
                  header: _ctx.$t('labels.manufacturer')
                }, {
                  filter: _withCtx(({filterModel,filterCallback}) => [
                    _createVNode(_component_InputText, {
                      type: "text",
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                      onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                      class: "p-column-filter text-xs",
                      placeholder: _ctx.$t('labels.manufacturer')
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
                  ]),
                  body: _withCtx(({data}) => [
                    _createElementVNode("span", _hoisted_34, _toDisplayString(data.manufacturerName || data.shopwareData?.manufacturer), 1),
                    (data.shopwareData && !_ctx.isValueEqualCaseInsensitive(data.manufacturerName || null, data.shopwareData.manufacturer || null))
                      ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_35, null, 512)), [
                          [
                            _directive_tooltip,
                            _ctx.$t('labels.inconsistentData') + ': ' + _ctx.$t('labels.manufacturer'),
                            void 0,
                            { top: true }
                          ]
                        ])
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["header"]))
              : _createCommentVNode("", true),
            (_ctx.visibleColumns.indexOf('sellOut') !== -1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 18,
                  field: "sellOut",
                  dataType: "boolean",
                  bodyClass: "text-center",
                  style: {"max-width":"6rem"},
                  header: _ctx.$t('labels.productHome.sellOut')
                }, {
                  filter: _withCtx(({filterModel, filterCallback}) => [
                    _createVNode(_component_TriStateCheckbox, {
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                      onChange: $event => (filterCallback())
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                  ]),
                  body: _withCtx(({data}) => [
                    _createElementVNode("i", {
                      class: _normalizeClass(["pi", {'text-green-600 pi-check-circle': data.shopwareData?.sellOut, 'text-red-600 pi-times-circle': !(data.shopwareData?.sellOut)}])
                    }, null, 2)
                  ]),
                  _: 1
                }, 8, ["header"]))
              : _createCommentVNode("", true),
            (_ctx.visibleColumns.indexOf('metaTags') !== -1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 19,
                  field: "metaTags",
                  bodyClass: "text-center",
                  style: {"min-width":"10rem"},
                  header: _ctx.$t('labels.productHome.metaTagsSEO')
                }, {
                  filter: _withCtx(({filterModel,filterCallback}) => [
                    _createVNode(_component_InputText, {
                      type: "text",
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                      onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                      class: "p-column-filter text-xs",
                      placeholder: _ctx.$t('labels.productHome.metaTagsSEO')
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
                  ]),
                  body: _withCtx(({data}) => [
                    _createElementVNode("span", _hoisted_36, _toDisplayString(data.customAttributes?.article_metatags), 1)
                  ]),
                  _: 1
                }, 8, ["header"]))
              : _createCommentVNode("", true),
            (_ctx.visibleColumns.indexOf('bulkGoods') !== -1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 20,
                  field: "bulkGoods",
                  dataType: "boolean",
                  bodyClass: "text-center",
                  style: {"max-width":"6rem"},
                  header: _ctx.$t('labels.productHome.bulkGoods')
                }, {
                  filter: _withCtx(({filterModel, filterCallback}) => [
                    _createVNode(_component_TriStateCheckbox, {
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                      onChange: $event => (filterCallback())
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                  ]),
                  body: _withCtx(({data}) => [
                    _createElementVNode("i", {
                      class: _normalizeClass(["pi", {'text-green-600 pi-check-circle': data.customAttributes?.article_sperrgut, 'text-red-600 pi-times-circle': !(data.customAttributes?.article_sperrgut)}])
                    }, null, 2)
                  ]),
                  _: 1
                }, 8, ["header"]))
              : _createCommentVNode("", true),
            (_ctx.visibleColumns.indexOf('blackWeekPromoFlag') !== -1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 21,
                  field: "blackWeekPromoFlag",
                  dataType: "boolean",
                  bodyClass: "text-center",
                  style: {"max-width":"6rem"},
                  header: _ctx.$t('labels.productPromotions.promoFlag')
                }, {
                  filter: _withCtx(({filterModel, filterCallback}) => [
                    _createVNode(_component_TriStateCheckbox, {
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                      onChange: $event => (filterCallback())
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                  ]),
                  body: _withCtx(({data}) => [
                    _createElementVNode("i", {
                      class: _normalizeClass(["pi", {'text-green-600 pi-check-circle': data.customAttributes?.article_promo_flag, 'text-red-600 pi-times-circle': !(data.customAttributes?.article_promo_flag)}])
                    }, null, 2)
                  ]),
                  _: 1
                }, 8, ["header"]))
              : _createCommentVNode("", true),
            (_ctx.visibleColumns.indexOf('tempSelect') !== -1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 22,
                  field: "tempSelect",
                  dataType: "boolean",
                  bodyClass: "text-center",
                  style: {"max-width":"6rem"},
                  header: _ctx.$t('labels.productHome.tempSelect')
                }, {
                  filter: _withCtx(({filterModel, filterCallback}) => [
                    _createVNode(_component_TriStateCheckbox, {
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                      onChange: $event => (filterCallback())
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                  ]),
                  body: _withCtx(({data}) => [
                    _createElementVNode("i", {
                      class: _normalizeClass(["pi", {'text-green-600 pi-check-circle': data.customAttributes?.admin_temp_select_article, 'text-red-600 pi-times-circle': !(data.customAttributes?.admin_temp_select_article)}])
                    }, null, 2)
                  ]),
                  _: 1
                }, 8, ["header"]))
              : _createCommentVNode("", true),
            (_ctx.visibleColumns.indexOf('availableInShop') !== -1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 23,
                  field: "availableInShop",
                  dataType: "boolean",
                  bodyClass: "text-center",
                  style: {"max-width":"6rem"},
                  header: _ctx.$t('labels.productHome.availableInShop')
                }, {
                  filter: _withCtx(({filterModel, filterCallback}) => [
                    _createVNode(_component_TriStateCheckbox, {
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                      onChange: $event => (filterCallback())
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                  ]),
                  body: _withCtx(({data}) => [
                    _createElementVNode("i", {
                      class: _normalizeClass(["pi", {'text-green-600 pi-check-circle': data.customAttributes?.article_ecom_new_tt_backend, 'text-red-600 pi-times-circle': !(data.customAttributes?.article_ecom_new_tt_backend)}])
                    }, null, 2)
                  ]),
                  _: 1
                }, 8, ["header"]))
              : _createCommentVNode("", true),
            (_ctx.visibleColumns.indexOf('activeInShop') !== -1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 24,
                  field: "activeInShop",
                  dataType: "boolean",
                  bodyClass: "text-center",
                  style: {"max-width":"6rem"},
                  header: _ctx.$t('labels.productHome.activeInShop')
                }, {
                  filter: _withCtx(({filterModel, filterCallback}) => [
                    _createVNode(_component_TriStateCheckbox, {
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                      onChange: $event => (filterCallback())
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                  ]),
                  body: _withCtx(({data}) => [
                    _createElementVNode("i", {
                      class: _normalizeClass(["pi", {'text-green-600 pi-check-circle': data.customAttributes?.article_ecom_new_tt_frontend, 'text-red-600 pi-times-circle': !(data.customAttributes?.article_ecom_new_tt_frontend)}])
                    }, null, 2)
                  ]),
                  _: 1
                }, 8, ["header"]))
              : _createCommentVNode("", true),
            (_ctx.visibleColumns.indexOf('orderPositionCheckStock') !== -1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 25,
                  field: "orderPositionCheckStock",
                  dataType: "boolean",
                  bodyClass: "text-center",
                  style: {"max-width":"7rem"},
                  header: _ctx.$t('labels.productHome.orderPositionCheckStock')
                }, {
                  filter: _withCtx(({filterModel, filterCallback}) => [
                    _createVNode(_component_TriStateCheckbox, {
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                      onChange: $event => (filterCallback())
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                  ]),
                  body: _withCtx(({data}) => [
                    (data.customAttributes && typeof data.customAttributes['order_position_check_stock'] !== 'undefined')
                      ? (_openBlock(), _createElementBlock("i", {
                          key: 0,
                          class: _normalizeClass(["pi", {'text-green-600 pi-check-circle':
                                data.customAttributes.order_position_check_stock,
                                'text-red-600 pi-times-circle':
                                !(data.customAttributes.order_position_check_stock)}])
                        }, null, 2))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["header"]))
              : _createCommentVNode("", true),
            (_ctx.visibleColumns.indexOf('priceOnRequest') !== -1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 26,
                  field: "priceOnRequest",
                  bodyClass: "text-center",
                  style: {"min-width":"16rem"},
                  header: _ctx.$t('labels.productHome.priceUponRequest')
                }, {
                  filter: _withCtx(({filterModel, filterCallback}) => [
                    _createVNode(_component_Dropdown, {
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                      options: [
                                    {
                                        value: '0',
                                        label: _ctx.$t('labels.productHome.deactivated')
                                    },
                                    {
                                        value: '1',
                                        label: _ctx.$t('labels.productHome.activated')
                                    },
                                    {
                                        value: '2',
                                        label: _ctx.$t(
                                            'labels.productHome.individualPriceUponRequest'
                                        )
                                    }
                                ],
                      placeholder: "Any",
                      optionLabel: "label",
                      optionValue: "value",
                      onChange: filterCallback,
                      class: "p-column-filter text-xs",
                      showClear: true
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange"])
                  ]),
                  body: _withCtx(({data}) => [
                    (data.shopwareData?.priceOnRequest == '2')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_37, _toDisplayString(_ctx.$t('labels.productHome.individualPriceUponRequest')), 1))
                      : (data.shopwareData?.priceOnRequest == '1')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_38, _toDisplayString(_ctx.$t('labels.productHome.activated')), 1))
                        : (_openBlock(), _createElementBlock("div", _hoisted_39, _toDisplayString(_ctx.$t('labels.productHome.deactivated')), 1))
                  ]),
                  _: 1
                }, 8, ["header"]))
              : _createCommentVNode("", true),
            (_ctx.visibleColumns.indexOf('vkPrice') !== -1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 27,
                  style: {"min-width":"7rem"}
                }, {
                  header: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('labels.vkPrice')) + " ", 1),
                    _cache[19] || (_cache[19] = _createElementVNode("div", { class: "sales-channel-dot sales-channel-teltec w-1rem h-1rem ml-1" }, null, -1))
                  ]),
                  body: _withCtx(({data}) => [
                    _createElementVNode("div", _hoisted_40, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.vkPriceCalculation(data)), 1)
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.visibleColumns.indexOf('vkPrice') !== -1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 28,
                  style: {"min-width":"7rem"}
                }, {
                  header: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('labels.vkPrice')) + " ", 1),
                    _cache[20] || (_cache[20] = _createElementVNode("div", { class: "sales-channel-dot sales-channel-videodata w-1rem h-1rem ml-1" }, null, -1))
                  ]),
                  body: _withCtx(({data}) => [
                    (_ctx.vkPriceCalculation(data, true, 'NET2'))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_41, _toDisplayString(_ctx.vkPriceCalculation(data, true, 'NET2')), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.visibleColumns.indexOf('ekPrice') !== -1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 29,
                  header: _ctx.$t('labels.ekPrice'),
                  style: {"min-width":"6rem"}
                }, {
                  body: _withCtx(({data}) => [
                    _createTextVNode(_toDisplayString(_ctx.ekPriceCalculation(data)), 1)
                  ]),
                  _: 1
                }, 8, ["header"]))
              : _createCommentVNode("", true),
            (_ctx.visibleColumns.indexOf('uvpPrice') !== -1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 30,
                  header: _ctx.$t('labels.productAdd.uvpPrice'),
                  style: {"min-width":"6rem"}
                }, {
                  body: _withCtx(({data}) => [
                    _createTextVNode(_toDisplayString(_ctx.uvpPriceCalculation(data)), 1)
                  ]),
                  _: 1
                }, 8, ["header"]))
              : _createCommentVNode("", true),
            (_ctx.visibleColumns.indexOf('tags') !== -1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 31,
                  field: "tags",
                  header: _ctx.$t('labels.tags'),
                  style: {"min-width":"10rem"},
                  filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
                }, {
                  filter: _withCtx(({filterModel,filterCallback}) => [
                    _createVNode(_component_p_multiselect, {
                      class: "text-sm",
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                      options: _ctx.availableProductTags,
                      maxSelectedLabels: 3,
                      optionLabel: "name",
                      optionValue: "name",
                      filter: "",
                      onChange: $event => (filterCallback()),
                      style: {"min-width":"5rem","max-width":"20rem"}
                    }, {
                      option: _withCtx(({option}) => [
                        _createTextVNode(_toDisplayString(option.name.toUpperCase().replace('TTC-PRODUCT-META-',
                        '')), 1)
                      ]),
                      value: _withCtx((content) => [
                        _createTextVNode(_toDisplayString((content.value || []).map((item) =>
                        item.toUpperCase().replace('TTC-PRODUCT-META-', ''))
                        .join(', ')), 1)
                      ]),
                      _: 2
                    }, 1032, ["modelValue", "onUpdate:modelValue", "options", "onChange"])
                  ]),
                  body: _withCtx(({data}) => [
                    (data?.tags)
                      ? (_openBlock(), _createElementBlock("ul", _hoisted_42, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.tags, (tag) => {
                            return (_openBlock(), _createElementBlock("li", null, [
                              _createElementVNode("span", _hoisted_43, _toDisplayString(tag.toUpperCase().replace('TTC-PRODUCT-META-',
                                '')), 1)
                            ]))
                          }), 256))
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["header"]))
              : _createCommentVNode("", true),
            _createVNode(_component_Column, {
              style: _normalizeStyle('min-width: ' + (_ctx.showChangeLogIcon ? 160 : 130) + 'px; max-width: fit-content'),
              frozen: true,
              alignFrozen: "right"
            }, _createSlots({ _: 2 }, [
              (_ctx.editPermissionAvailable || _ctx.viewAccessoriesPermissionAvailable || (_ctx.data.shopwareData?.name && _ctx.data.shopwareUrl) )
                ? {
                    name: "body",
                    fn: _withCtx(({data}) => [
                      ((data.shopwareData?.active && data.shopwareData?.name && data.shopwareUrl))
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            target: "_blank",
                            href: _ctx.resolvedShopwareUrl(data.shopwareUrl, data.shopwareData, _ctx.resolvedPlatform?.threeLetterId)
                          }, [
                            _createElementVNode("i", {
                              class: _normalizeClass(["pi pi-shopping-cart mr-2", {'sales-channel-teltec-color':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.shopwareData?.active, 'sales-channel-videodata-color':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.shopwareData?.active}])
                            }, null, 2)
                          ], 8, _hoisted_44))
                        : _createCommentVNode("", true),
                      ((data.secondShopwareData?.active && data.secondShopwareData?.name && data.secondShopwareUrl))
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 1,
                            target: "_blank",
                            href: _ctx.resolvedShopwareUrl(data.secondShopwareUrl, data.secondShopwareData, _ctx.secondPlatform?.threeLetterId)
                          }, [
                            _createElementVNode("i", {
                              class: _normalizeClass(["pi pi-shopping-cart mr-2", {'sales-channel-videodata-color':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.secondShopwareData?.active, 'sales-channel-teltec-color':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.secondShopwareData?.active}])
                            }, null, 2)
                          ], 8, _hoisted_45))
                        : _createCommentVNode("", true),
                      (_ctx.changelog.length > 0 && _ctx.changelog.some((item) => item.entityId === data.articleNumber))
                        ? (_openBlock(), _createBlock(_component_router_link, {
                            key: 2,
                            to: {
                                name: 'ProductChangelog',
                                params: {id: data.articleNumber}
                            }
                          }, {
                            default: _withCtx(() => [
                              _withDirectives(_createElementVNode("i", _hoisted_46, null, 512), [
                                [
                                  _directive_tooltip,
                                  _ctx.$t('labels.changelog'),
                                  void 0,
                                  { left: true }
                                ]
                              ])
                            ]),
                            _: 2
                          }, 1032, ["to"]))
                        : _createCommentVNode("", true),
                      (_ctx.editPermissionAvailable)
                        ? (_openBlock(), _createBlock(_component_router_link, {
                            key: 3,
                            to: {
                                    name: 'ProductsAddUsedWizard',
                                    state: {originalProduct: JSON.stringify(data)}
                                }
                          }, {
                            default: _withCtx(() => [
                              _withDirectives(_createElementVNode("i", _hoisted_47, null, 512), [
                                [
                                  _directive_tooltip,
                                  _ctx.$t('labels.addUsedProduct'),
                                  void 0,
                                  { left: true }
                                ]
                              ])
                            ]),
                            _: 2
                          }, 1032, ["to"]))
                        : _createCommentVNode("", true),
                      (_ctx.editPermissionAvailable)
                        ? (_openBlock(), _createBlock(_component_router_link, {
                            key: 4,
                            to: {
                                    name: 'CloneExistingProduct',
                                    params: {id: data.articleNumber}
                                }
                          }, {
                            default: _withCtx(() => [
                              _withDirectives(_createElementVNode("i", _hoisted_48, null, 512), [
                                [
                                  _directive_tooltip,
                                  _ctx.$t('labels.copyProduct'),
                                  void 0,
                                  { left: true }
                                ]
                              ])
                            ]),
                            _: 2
                          }, 1032, ["to"]))
                        : _createCommentVNode("", true),
                      (_ctx.editPermissionAvailable)
                        ? (_openBlock(), _createBlock(_component_router_link, {
                            key: 5,
                            to: {
                                    name: 'ProductDetails',
                                    params: {id: data.articleNumber},
                                    state: {historyFilters: _ctx.historyFilters}
                                }
                          }, {
                            default: _withCtx(() => [
                              _withDirectives(_createElementVNode("i", _hoisted_49, null, 512), [
                                [
                                  _directive_tooltip,
                                  _ctx.$t('labels.articleDetails'),
                                  void 0,
                                  { left: true }
                                ]
                              ])
                            ]),
                            _: 2
                          }, 1032, ["to"]))
                        : _createCommentVNode("", true)
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["style"])
          ]),
          _: 3
        }, 8, ["totalRecords", "value", "filters", "onStateSave", "onStateRestore", "expandedRows", "selection", "onRowSelect", "onRowSelectAll", "onRowUnselect", "onRowUnselectAll"])
      ]),
      _: 3
    }),
    _createVNode(_component_PrimeDialog, {
      visible: _ctx.dialogs.find(item => item.name === 'Comments').visible,
      "onUpdate:visible": _cache[12] || (_cache[12] = $event => ((_ctx.dialogs.find(item => item.name === 'Comments').visible) = $event)),
      header: _ctx.$t('labels.articleNumber') + ' ' + (_ctx.comments?.articleNumber || ''),
      breakpoints: {'960px': '75vw'},
      style: {width: '50vw'},
      onHide: _cache[13] || (_cache[13] = $event => {_ctx.comments.value = null;_ctx.closeDialog()}),
      modal: true,
      dismissableMask: true
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.comments?.comments || []), (comment) => {
          return (_openBlock(), _createBlock(_component_PrimePanel, { class: "small mb-3" }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_52, _toDisplayString(comment.author) + " at " + _toDisplayString(comment.createdDate), 1)
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", {
                innerHTML: comment.htmlComment || comment.comment
              }, null, 8, _hoisted_53)
            ]),
            _: 2
          }, 1024))
        }), 256))
      ]),
      _: 1
    }, 8, ["visible", "header"])
  ], 64))
}