<loading v-model:active="loading" />
<Panel header="Protokolle der Artikelsynchronisation">
    <DataTable
        class="p-datatable-sm text-sm"
        stripedRows
        paginator
        :rows="10"
        scrollable
        scrollHeight="calc(100vh - 23rem)"
        lazy
        ref="dt"
        dataKey="id"
        :totalRecords="totalRecords"
        :value="logs"
        responsiveLayout="scroll"
        paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rows-per-page-options="[10,20,50]"
        current-page-report-template="{first} to {last} of {totalRecords}"
        v-model:filters="filters"
        filterDisplay="row"
        @page="loadLazyData($event)"
        @filter="loadLazyData($event)"
        @sort="loadLazyData($event)"
    >
        <Column
            field="productNumber"
            :header="$t('labels.articleNumber')"
            sortable
            ><template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                />
            </template>
            <template #body="{data}">
                <span style="vertical-align: middle"
                    ><a
                        :href="data.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + data.weclappId"
                        target="_blank"
                        >{{data.productNumber}}</a
                    >
                    [<span class="font-semibold">{{data.weclappName}}</span
                    >]</span
                >
            </template>
        </Column>

        <Column
            field="status"
            :header="$t('labels.status')"
            sortable
            :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
            ><template #body="{data}">
                <template v-if="data">
                    <Tag v-if="!data?.processingStartedAt" severity="warning">
                        <span>{{$t('labels.scheduled')}}</span>
                    </Tag>
                    <Tag v-else-if="!data?.processingEndedAt" severity="info">
                        <span>{{$t('labels.inProgress')}}</span>
                    </Tag>
                    <Tag v-else severity="success">
                        <span>{{$t('labels.done')}}</span>
                    </Tag>
                </template>
            </template>
        </Column>

        <Column field="createdAt" sortable :header="$t('labels.initiatedAt')">
            <template #body="{data}">
                <div>
                    <span class="font-semibold"> {{data.createdAt}}</span>
                </div>
                <div>
                    <Tag v-if="data.isAutomatic" severity="primary">
                        <span style="text-transform: uppercase"
                            >{{$t('labels.mail.automatic')}}</span
                        >
                    </Tag>
                    <span v-else class="text-xs"
                        >{{$t('labels.initiatedBy')}}
                        <span class="font-semibold"
                            >{{data.initiatedBy}}</span
                        ></span
                    >
                </div>
            </template>
        </Column>
        <Column field="changeSet">
            <template #header>
                <span class="w-full text-center"
                    >{{$t('labels.changeSet')}}</span
                >
            </template>
            <template #filter="{filterModel, filterCallback}">
                <div class="w-full text-center">
                    <TriStateCheckbox
                        v-model="filterModel.value"
                        @change="filterCallback()"
                    />
                </div>
            </template>
            <template #body="{data}">
                <div class="grid">
                    <div class="col w-full text-center">
                        <span
                            class="sales-channel-dot w-1rem h-1rem mr-1 sales-channel-teltec"
                        ></span>
                        <div
                            class="mt-2"
                            v-if="Object.keys(JSON.parse(data.changeSet || '{}')).includes('teltec')"
                        >
                            <ChangeSet
                                :changeSet="JSON.parse(data.changeSet)['teltec']"
                            ></ChangeSet>
                        </div>
                        <div
                            class="mt-2"
                            v-else-if="Object.keys(JSON.parse(data.changeSet || '{}')).includes('teltec-demo')"
                        >
                            <ChangeSet
                                :changeSet="JSON.parse(data.changeSet)['teltec-demo']"
                            ></ChangeSet>
                        </div>
                    </div>
                    <div class="col w-full text-center">
                        <span
                            class="sales-channel-dot w-1rem h-1rem mr-1 sales-channel-videodata"
                        ></span>
                        <div
                            class="mt-2"
                            v-if="Object.keys(JSON.parse(data.changeSet || '{}')).includes('videodata')"
                        >
                            <ChangeSet
                                :changeSet="JSON.parse(data.changeSet)['videodata']"
                            ></ChangeSet>
                        </div>
                        <div
                            class="mt-2"
                            v-else-if="Object.keys(JSON.parse(data.changeSet || '{}')).includes('videodata-demo')"
                        >
                            <ChangeSet
                                :changeSet="JSON.parse(data.changeSet)['videodata-demo']"
                            ></ChangeSet>
                        </div>
                    </div>
                </div>
            </template>
        </Column>
    </DataTable>
</Panel>
