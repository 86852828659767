import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment, withKeys as _withKeys, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex justify-content-between w-full" }
const _hoisted_2 = {
  key: 0,
  class: "text-lg font-bold"
}
const _hoisted_3 = ["href"]
const _hoisted_4 = { style: {"text-transform":"uppercase"} }
const _hoisted_5 = { class: "mt-2" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "font-semibold" }
const _hoisted_8 = {
  key: 1,
  class: "spacer-dot"
}
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { class: "font-semibold" }
const _hoisted_11 = { class: "flex justify-content-start w-full ff" }
const _hoisted_12 = { class: "font-bold" }
const _hoisted_13 = {
  key: 0,
  class: "text-sm"
}
const _hoisted_14 = { key: 0 }
const _hoisted_15 = ["href"]
const _hoisted_16 = {
  key: 1,
  class: "text-sm mt-1"
}
const _hoisted_17 = {
  key: 2,
  class: "text-sm mt-1"
}
const _hoisted_18 = { class: "mt-2" }
const _hoisted_19 = { class: "mt-1" }
const _hoisted_20 = { class: "text-sm" }
const _hoisted_21 = { class: "list-none changeset p-0 m-0 flex-grow-1" }
const _hoisted_22 = {
  key: 0,
  class: "flex align-items-center mb-1"
}
const _hoisted_23 = {
  key: 1,
  class: "flex align-items-center mb-1"
}
const _hoisted_24 = {
  key: 0,
  class: "pi pi-info-circle text-red-500 mr-2"
}
const _hoisted_25 = {
  key: 1,
  class: "pi pi-info-circle text-red-500 mr-2"
}
const _hoisted_26 = {
  key: 2,
  class: "pi pi-check-circle text-green-500 mr-2"
}
const _hoisted_27 = {
  key: 2,
  class: "flex align-items-center mb-1"
}
const _hoisted_28 = {
  key: 3,
  class: "flex align-items-center mb-1"
}
const _hoisted_29 = { class: "text-sm" }
const _hoisted_30 = { class: "list-none changeset p-0 m-0 flex-grow-1" }
const _hoisted_31 = {
  key: 0,
  class: "flex align-items-center mb-1"
}
const _hoisted_32 = {
  key: 1,
  class: "flex align-items-center mb-1"
}
const _hoisted_33 = {
  key: 2,
  class: "flex align-items-center mb-1"
}
const _hoisted_34 = {
  key: 3,
  class: "flex align-items-center"
}
const _hoisted_35 = {
  key: 0,
  class: "font-medium text-lg mb-2 text-primary"
}
const _hoisted_36 = {
  class: "text-sm",
  style: {"width":"70%"}
}
const _hoisted_37 = { class: "text-sm" }
const _hoisted_38 = {
  key: 0,
  class: "pi pi-check-circle text-green-500 mr-2"
}
const _hoisted_39 = {
  key: 1,
  class: "pi pi-times-circle text-red-500 mr-2"
}
const _hoisted_40 = { key: 0 }
const _hoisted_41 = { key: 1 }
const _hoisted_42 = {
  key: 0,
  class: "text-sm"
}
const _hoisted_43 = { class: "text-sm" }
const _hoisted_44 = { key: 0 }
const _hoisted_45 = { key: 1 }
const _hoisted_46 = {
  key: 0,
  class: "text-sm"
}
const _hoisted_47 = {
  key: 0,
  class: "pi pi-calculator ml-1"
}
const _hoisted_48 = { class: "text-sm" }
const _hoisted_49 = { class: "text-sm" }
const _hoisted_50 = { key: 0 }
const _hoisted_51 = {
  key: 1,
  class: "text-sm"
}
const _hoisted_52 = { class: "text-sm mt-1" }
const _hoisted_53 = {
  key: 0,
  class: "text-sm"
}
const _hoisted_54 = { class: "text-sm font-semibold" }
const _hoisted_55 = { class: "text-xs" }
const _hoisted_56 = { class: "text-sm" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_Tag = _resolveComponent("Tag")
  const _component_p_button = _resolveComponent("p-button")
  const _component_Badge = _resolveComponent("Badge")
  const _component_p_checkbox = _resolveComponent("p-checkbox")
  const _component_Column = _resolveComponent("Column")
  const _component_InputText = _resolveComponent("InputText")
  const _component_p_multiselect = _resolveComponent("p-multiselect")
  const _component_TriStateCheckbox = _resolveComponent("TriStateCheckbox")
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_Panel = _resolveComponent("Panel")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, null, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", null, [
            (_ctx.parentData?.supplierId)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                  _createTextVNode(_toDisplayString(_ctx.parentData?.supplierConfiguration?.supplierName) + " [", 1),
                  _createElementVNode("a", {
                    href: _ctx.url + 'webapp/view/party/PartyDetail.page?entityId=' + _ctx.parentData.supplierId,
                    target: "_blank"
                  }, _toDisplayString(_ctx.parentData?.supplierConfiguration?.supplierNumber), 9, _hoisted_3),
                  _cache[4] || (_cache[4] = _createTextVNode("] "))
                ]))
              : _createCommentVNode("", true),
            (_ctx.parentData?.manufacturerName)
              ? (_openBlock(), _createBlock(_component_Tag, { key: 1 }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('labels.manufacturer')) + ": " + _toDisplayString(_ctx.parentData.manufacturerName), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_5, [
              (_ctx.parentData?.uploadedBy)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                    _createTextVNode(_toDisplayString(_ctx.$t('labels.uploadedBy')) + ": ", 1),
                    _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.parentData.uploadedBy), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.parentData?.processingEndedAt)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8))
                : _createCommentVNode("", true),
              (_ctx.parentData?.processingEndedAt)
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                    _createTextVNode(_toDisplayString(_ctx.$t('labels.importedAt')) + ": ", 1),
                    _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.parentData.processingEndedAt), 1)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_p_button, {
              class: "mr-3",
              severity: "success",
              textContent: _toDisplayString(_ctx.$t('labels.overview')),
              onClick: _ctx.onBackToOverviewClick
            }, null, 8, ["textContent", "onClick"]),
            _createVNode(_component_p_button, {
              textContent: _toDisplayString(_ctx.$t('labels.importLog')),
              onClick: _ctx.onBackToListClick
            }, null, 8, ["textContent", "onClick"]),
            (!_ctx.isIrregularFilterActive && !_ctx.parentData?.importInProgress && _ctx.parentData?.processingEndedAt && _ctx.parentData?.statistics?.regularItemsCount > _ctx.parentData?.statistics?.handledItemsCount)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_p_button, {
                    class: "ml-3",
                    severity: "danger",
                    icon: "fas fa-play",
                    onClick: _ctx.runManually
                  }, null, 8, ["onClick"]),
                  (!_ctx.parentData?.lastRunAtTimestamp)
                    ? (_openBlock(), _createBlock(_component_p_button, {
                        key: 0,
                        class: "ml-3",
                        severity: "warning",
                        onClick: _ctx.rejectImport,
                        textContent: _toDisplayString(_ctx.$t('buttons.rejectImport'))
                      }, null, 8, ["onClick", "textContent"]))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_DataTable, {
          class: "p-datatable-sm",
          stripedRows: "",
          paginator: "",
          rows: 20,
          scrollable: "",
          scrollHeight: "calc(100vh - 25rem)",
          ref: "dt",
          dataKey: "id",
          totalRecords: _ctx.totalRecords,
          value: _ctx.logs,
          rowClass: _ctx.rowClass,
          responsiveLayout: "scroll",
          "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
          "rows-per-page-options": [10,20,50,100,200,500],
          "current-page-report-template": "{first} to {last} of {totalRecords}",
          filters: _ctx.filters,
          "onUpdate:filters": _cache[3] || (_cache[3] = $event => ((_ctx.filters) = $event)),
          filterDisplay: "row"
        }, {
          default: _withCtx(() => [
            (!_ctx.isIrregularFilterActive && (_ctx.logs || []).some((item) => item.status < 1000 && !item.processedAtTimestamp))
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 0,
                  headerStyle: "width: 3rem",
                  header: _ctx.$t('labels.excludeFromProcessing')
                }, {
                  filter: _withCtx(() => [
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_Badge, {
                        value: _ctx.selectedItems.length
                      }, null, 8, ["value"]),
                      (_ctx.selectedItems.length > 0)
                        ? (_openBlock(), _createElementBlock("i", {
                            key: 0,
                            class: "pi pi-times-circle text-red-600 ml-1 mt-1",
                            onClick: _cache[1] || (_cache[1] = $event => {_ctx.selectedItems = [];})
                          }))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  body: _withCtx(({data}) => [
                    (!data.processedAtTimestamp)
                      ? (_openBlock(), _createBlock(_component_p_checkbox, {
                          key: 0,
                          value: data.id,
                          modelValue: _ctx.selectedItems,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.selectedItems) = $event))
                        }, null, 8, ["value", "modelValue"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["header"]))
              : _createCommentVNode("", true),
            _createVNode(_component_Column, {
              field: "sku",
              header: _ctx.$t('labels.productAdd.sku'),
              sortable: "",
              style: {"max-width":"16rem"}
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-sm"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("div", _hoisted_12, _toDisplayString(data.sku), 1),
                (data.extractedData?.ean)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, " EAN: " + _toDisplayString(data.extractedData?.ean), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              header: _ctx.$t('labels.article'),
              field: "weclappArticle",
              style: {"min-width":"16rem"},
              filterMatchModeOptions: [{label: 'Contains', value: _ctx.articleComboFilter}]
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-sm"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              body: _withCtx(({data}) => [
                (data.weclappArticle?.articleNumber)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createElementVNode("a", {
                        href: _ctx.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + data.weclappArticle?.id,
                        target: "_blank"
                      }, _toDisplayString(data.weclappArticle?.articleNumber), 9, _hoisted_15),
                      (!data.weclappArticle.active)
                        ? (_openBlock(), _createBlock(_component_Tag, {
                            key: 0,
                            severity: "danger",
                            class: "ml-2"
                          }, {
                            default: _withCtx(() => _cache[5] || (_cache[5] = [
                              _createElementVNode("span", null, "Inaktiv", -1)
                            ])),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                (data.weclappArticle?.name)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(data.weclappArticle.name), 1))
                  : _createCommentVNode("", true),
                (data.weclappArticle?.ean)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, " EAN: " + _toDisplayString(data.weclappArticle?.ean), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_18, [
                  (!data.isPrimarySupplySource)
                    ? (_openBlock(), _createBlock(_component_Tag, {
                        key: 0,
                        severity: "danger",
                        class: "mr-1"
                      }, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createElementVNode("span", null, "Lieferant nicht primär", -1)
                        ])),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (data.costPriceLocked)
                    ? (_openBlock(), _createBlock(_component_Tag, {
                        key: 1,
                        class: "mr-1",
                        severity: "danger"
                      }, {
                        default: _withCtx(() => _cache[7] || (_cache[7] = [
                          _createElementVNode("span", null, "EK Promo", -1)
                        ])),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (data.sellPriceLocked)
                    ? (_openBlock(), _createBlock(_component_Tag, {
                        key: 2,
                        class: "mr-1",
                        severity: "info"
                      }, {
                        default: _withCtx(() => _cache[8] || (_cache[8] = [
                          _createElementVNode("span", null, "VK Promo", -1)
                        ])),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (data.listPriceLocked)
                    ? (_openBlock(), _createBlock(_component_Tag, {
                        key: 3,
                        severity: "warning"
                      }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createElementVNode("span", null, "UVP Lock", -1)
                        ])),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_19, [
                    (data.weclappArticle.systemTags.includes('TTC-PRODUCT-DEMO'))
                      ? (_openBlock(), _createBlock(_component_Tag, {
                          key: 0,
                          severity: "info",
                          class: "mr-2"
                        }, {
                          default: _withCtx(() => _cache[10] || (_cache[10] = [
                            _createElementVNode("span", null, "DEMO", -1)
                          ])),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (data.weclappArticle.systemTags.includes('TTC-PRODUCT-USED'))
                      ? (_openBlock(), _createBlock(_component_Tag, {
                          key: 1,
                          class: "mr-1"
                        }, {
                          default: _withCtx(() => _cache[11] || (_cache[11] = [
                            _createElementVNode("span", null, "USED", -1)
                          ])),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (data.weclappArticle.systemTags.includes('TTC-PRODUCT-EXDEMO'))
                      ? (_openBlock(), _createBlock(_component_Tag, {
                          key: 2,
                          severity: "success",
                          class: "mr-1"
                        }, {
                          default: _withCtx(() => _cache[12] || (_cache[12] = [
                            _createElementVNode("span", null, "EX-DEMO", -1)
                          ])),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (data.weclappArticle.systemTags.includes('TTC-PRODUCT-OPENBOX'))
                      ? (_openBlock(), _createBlock(_component_Tag, {
                          key: 3,
                          severity: "warning",
                          class: "mr-1"
                        }, {
                          default: _withCtx(() => _cache[13] || (_cache[13] = [
                            _createElementVNode("span", null, "OPENBOX", -1)
                          ])),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (data.weclappArticle.systemTags.includes('TTC-PRODUCT-COMMISSION-GOOD'))
                      ? (_openBlock(), _createBlock(_component_Tag, {
                          key: 4,
                          severity: "danger",
                          class: "mr-1"
                        }, {
                          default: _withCtx(() => _cache[14] || (_cache[14] = [
                            _createElementVNode("span", null, "KOMMISSIONSWARE", -1)
                          ])),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (data.sourceProductTag)
                      ? (_openBlock(), _createBlock(_component_Tag, {
                          key: 5,
                          severity: "primary",
                          class: "mt-1"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", null, "NEUWARE: " + _toDisplayString(data.sourceProductTag.replace(
                                'TTC-PRODUCT-NEW-', '' )), 1)
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _: 1
            }, 8, ["header", "filterMatchModeOptions"]),
            (!_ctx.isIrregularFilterActive)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 1,
                  header: "Änderungssatz",
                  style: {"max-width":"16rem"},
                  field: "changeStatus",
                  filterMatchModeOptions: [{label: 'Equals', value: _ctx.teltecChangeStatusFilter}]
                }, {
                  filter: _withCtx(({filterModel, filterCallback}) => [
                    _createVNode(_component_p_multiselect, {
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                      options: [
                                    {
                                        value: -1,
                                        label: 'Keine Änderung'
                                    },
                                    {
                                        value: 4,
                                        label: 'UVP'
                                    },
                                    {
                                        value: 8,
                                        label: 'VK'
                                    },
                                    {
                                        value: 16,
                                        label: 'EK'
                                    },
                                    {
                                        value: 128,
                                        label: 'WBT'
                                    }
                                   
                                ],
                      placeholder: "Any",
                      optionLabel: "label",
                      optionValue: "value",
                      onChange: filterCallback,
                      class: "p-column-filter text-xs",
                      showClear: true
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                  ]),
                  body: _withCtx(({data}) => [
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("ul", _hoisted_21, [
                        (data.costPriceChange)
                          ? (_openBlock(), _createElementBlock("li", _hoisted_22, _cache[15] || (_cache[15] = [
                              _createElementVNode("i", { class: "pi pi-check-circle text-green-500 mr-2" }, null, -1),
                              _createElementVNode("span", null, "EK", -1)
                            ])))
                          : _createCommentVNode("", true),
                        (data.sellPriceChange)
                          ? (_openBlock(), _createElementBlock("li", _hoisted_23, [
                              ((_ctx.priceOnRequestOptions || []).some((item) => item.value.startsWith('Preis auf Anfrage') && item.id === data.weclappArticle?.customAttributes?.article_priceoff_noorder))
                                ? _withDirectives((_openBlock(), _createElementBlock("i", _hoisted_24, null, 512)), [
                                    [
                                      _directive_tooltip,
                                      _ctx.$t('messages.sellPriceWillNotBeUpdatedDueToPriceOnRequestSwitch'),
                                      void 0,
                                      { top: true }
                                    ]
                                  ])
                                : (_ctx.sellPriceChangeRejectedDueToNetRivalsUpdate(data.weclappArticle?.customAttributes?.article_netRivals_last_change))
                                  ? _withDirectives((_openBlock(), _createElementBlock("i", _hoisted_25, null, 512)), [
                                      [
                                        _directive_tooltip,
                                        _ctx.$t('messages.sellPriceWillNotBeUpdatedDueToNetRivalsPriceUpdate'),
                                        void 0,
                                        { top: true }
                                      ]
                                    ])
                                  : (_openBlock(), _createElementBlock("i", _hoisted_26)),
                              _cache[16] || (_cache[16] = _createElementVNode("span", null, "VK", -1))
                            ]))
                          : _createCommentVNode("", true),
                        (data.listPriceChange)
                          ? (_openBlock(), _createElementBlock("li", _hoisted_27, _cache[17] || (_cache[17] = [
                              _createElementVNode("i", { class: "pi pi-check-circle text-green-500 mr-2" }, null, -1),
                              _createElementVNode("span", null, "UVP", -1)
                            ])))
                          : _createCommentVNode("", true),
                        (data.wbtChange)
                          ? (_openBlock(), _createElementBlock("li", _hoisted_28, _cache[18] || (_cache[18] = [
                              _createElementVNode("i", { class: "pi pi-check-circle text-green-500 mr-2" }, null, -1),
                              _createElementVNode("span", null, "WBT", -1)
                            ])))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ]),
                  _: 1
                }, 8, ["filterMatchModeOptions"]))
              : _createCommentVNode("", true),
            (!_ctx.isIrregularFilterActive)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 2,
                  header: "Änderungssatz (Daten)",
                  style: {"max-width":"16rem"},
                  field: "changeStatusDates",
                  filterMatchModeOptions: [{label: 'Equals', value: _ctx.teltecChangeStatusFilter}]
                }, {
                  filter: _withCtx(({filterModel, filterCallback}) => [
                    _createVNode(_component_p_multiselect, {
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                      options: [
                                    {
                                        value: -2,
                                        label: 'Keine Änderung'
                                    },
                                    {
                                        value: 1,
                                        label: 'UVP Datum'
                                    },
                                    {
                                        value: 2,
                                        label: 'EK Datum'
                                    },
                                    {
                                        value: 32,
                                        label: 'Datum im letzten EK (Sekundäre BZQ)'
                                    },
                                    {
                                        value: 256,
                                        label: 'WBT Datum'
                                    }
                                   
                                ],
                      placeholder: "Any",
                      optionLabel: "label",
                      optionValue: "value",
                      onChange: filterCallback,
                      class: "p-column-filter text-xs",
                      showClear: true
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                  ]),
                  body: _withCtx(({data}) => [
                    _createElementVNode("div", _hoisted_29, [
                      _createElementVNode("ul", _hoisted_30, [
                        (data.costPriceDateChange)
                          ? (_openBlock(), _createElementBlock("li", _hoisted_31, _cache[19] || (_cache[19] = [
                              _createElementVNode("i", { class: "pi pi-check-circle text-green-500 mr-2" }, null, -1),
                              _createElementVNode("span", null, "EK Datum", -1)
                            ])))
                          : _createCommentVNode("", true),
                        (data.listPriceDateChange)
                          ? (_openBlock(), _createElementBlock("li", _hoisted_32, _cache[20] || (_cache[20] = [
                              _createElementVNode("i", { class: "pi pi-check-circle text-green-500 mr-2" }, null, -1),
                              _createElementVNode("span", null, "UVP Datum", -1)
                            ])))
                          : _createCommentVNode("", true),
                        (data.status === 32)
                          ? (_openBlock(), _createElementBlock("li", _hoisted_33, _cache[21] || (_cache[21] = [
                              _createElementVNode("i", { class: "pi pi-check-circle text-green-500 mr-2" }, null, -1),
                              _createElementVNode("span", null, "Datum im letzten EK", -1)
                            ])))
                          : _createCommentVNode("", true),
                        ((data.status & 256) === 256)
                          ? (_openBlock(), _createElementBlock("li", _hoisted_34, _cache[22] || (_cache[22] = [
                              _createElementVNode("i", { class: "pi pi-check-circle text-green-500 mr-2" }, null, -1),
                              _createElementVNode("span", null, "WBT Datum", -1)
                            ])))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ]),
                  _: 1
                }, 8, ["filterMatchModeOptions"]))
              : _createCommentVNode("", true),
            (_ctx.isIrregularFilterActive)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 3,
                  style: {"max-width":"16rem"},
                  field: "status",
                  header: _ctx.$t('labels.status'),
                  sortable: "",
                  filterMatchModeOptions: [{label: 'Equals', value: _ctx.teltecStatusFilter}]
                }, {
                  filter: _withCtx(({filterModel, filterCallback}) => [
                    _createVNode(_component_p_multiselect, {
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                      options: [
                                    {
                                        value: 10002,
                                        label: 'Artikel in weclapp als \'Nicht in PL - Ersatzteil\' gekennzeichnet'
                                    },
                                    {
                                        value: 10003,
                                        label: 'Artikel in weclapp inaktiv, in PL vorhanden'
                                    },
                                    {
                                        value: 10004,
                                        label: 'Fehler in PL'
                                    },
                                    {
                                        value: 10005,
                                        label: 'Bestehender EK in der Zukunft'
                                    },
                                    {
                                        value: 10006,
                                        label: 'Bestehender UVP in der Zukunft'
                                    },
                                    {
                                        value: 1001,
                                        label: 'In PL nicht vorhanden, Lieferant nicht primär'
                                    },
                                    {
                                        value: 1002,
                                        label: 'In PL nicht vorhanden, ist primäre BZQ und mehrere BZQ vorhanden'
                                    },
                                    {
                                        value: 1003,
                                        label: 'In PL nicht vorhanden, ist primäre BZQ und nur eine BZQ vorhanden, ohne Lagerbestand'
                                    },
                                    {
                                        value: 1004,
                                        label: 'In PL nicht vorhanden, ist primäre BZQ und nur eine BZQ vorhanden und Lagerbestand vorhanden'
                                    }
                                   
                                ],
                      placeholder: "Any",
                      optionLabel: "label",
                      optionValue: "value",
                      onChange: filterCallback,
                      class: "p-column-filter text-xs",
                      showClear: true
                    }, {
                      option: _withCtx(({option}) => [
                        _createTextVNode("(Status " + _toDisplayString(option.value) + ") " + _toDisplayString(option.label), 1)
                      ]),
                      _: 2
                    }, 1032, ["modelValue", "onUpdate:modelValue", "onChange"])
                  ]),
                  body: _withCtx(({data}) => [
                    (_ctx.getStatusTooltip(data.status))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                          _createTextVNode(_toDisplayString(data.status) + " ", 1),
                          ([1001, 1002, 1003, 1004].includes(data.status))
                            ? (_openBlock(), _createBlock(_component_Tag, {
                                key: 0,
                                severity: "warning"
                              }, {
                                default: _withCtx(() => _cache[23] || (_cache[23] = [
                                  _createElementVNode("span", null, "EOL", -1)
                                ])),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_36, _toDisplayString(_ctx.getStatusTooltip(data.status)), 1)
                  ]),
                  _: 1
                }, 8, ["header", "filterMatchModeOptions"]))
              : _createCommentVNode("", true),
            _createVNode(_component_Column, {
              header: "Online",
              field: "onlineStatus",
              filterMatchModeOptions: [{label: 'Equals', value: _ctx.articleOnlineStatusFilter}]
            }, {
              filter: _withCtx(({filterModel, filterCallback}) => [
                _createVNode(_component_TriStateCheckbox, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onChange: $event => (filterCallback())
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("div", _hoisted_37, [
                  (data.onlineStatus)
                    ? (_openBlock(), _createElementBlock("i", _hoisted_38))
                    : (_openBlock(), _createElementBlock("i", _hoisted_39))
                ])
              ]),
              _: 1
            }, 8, ["filterMatchModeOptions"]),
            (!_ctx.isIrregularFilterActive)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 4,
                  style: {"min-width":"7rem"}
                }, {
                  header: _withCtx(() => [
                    (_ctx.parentData?.costPriceStartDate)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                          _cache[24] || (_cache[24] = _createElementVNode("div", { class: "w-full" }, "EK PL", -1)),
                          _createVNode(_component_Tag, { severity: "info" }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", null, "AB " + _toDisplayString(_ctx.parentData?.costPriceStartDate), 1)
                            ]),
                            _: 1
                          })
                        ]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_41, "EK PL"))
                  ]),
                  body: _withCtx(({data}) => [
                    (data.extractedData?.costPrice)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_42, _toDisplayString(_ctx.formatInCurrency(data.extractedData.costPrice,data.extractedData.costPriceCurrency
                    || 'EUR')), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_Column, {
              header: "Ek Wcp",
              sortable: "",
              field: "calculatedCostPrice"
            }, {
              body: _withCtx(({data}) => [
                _createElementVNode("div", _hoisted_43, _toDisplayString(data.calculatedCostPriceFormatted), 1)
              ]),
              _: 1
            }),
            (!_ctx.isIrregularFilterActive)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 5,
                  header: "Ek Diff",
                  sortable: "",
                  style: {"min-width":"6rem"},
                  field: "costPriceDifferenceInPercentage",
                  filterMatchModeOptions: [{label: 'Equals', value: _ctx.percentageFilter}]
                }, {
                  filter: _withCtx(({filterModel, filterCallback}) => [
                    _createVNode(_component_p_dropdown, {
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                      options: [
                                    {
                                        value: 0,
                                        label: 'Keine Änderung'
                                    },
                                    {
                                        value: 1,
                                        label: 'Alle günstiger'
                                    },
                                    {
                                        value: 2,
                                        label: 'Alle teurer'
                                    },
                                    {
                                        value: -15,
                                        label: '> -15%'
                                    },
                                    {
                                        value: -5,
                                        label: '> -5%'
                                    },
                                    {
                                        value: -4,
                                        label: '<= -5%'
                                    },
                                    {
                                        value: 4,
                                        label: '<= 5%'
                                    },
                                    {
                                        value: 5,
                                        label: '> 5%'
                                    },
                                    {
                                        value: 15,
                                        label: '> 15%'
                                    }
                                   
                                ],
                      placeholder: "Any",
                      optionLabel: "label",
                      optionValue: "value",
                      onChange: filterCallback,
                      inputClass: "p-column-filter text-xs"
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                  ]),
                  body: _withCtx(({data}) => [
                    _createVNode(_component_Tag, {
                      severity: (data.costPriceDifferenceInPercentage > 0) ? 'danger' : (data.costPriceDifferenceInPercentage < 0 ? 'success' : '')
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(Math.abs(data.costPriceDifferenceInPercentage).toFixed(2)) + " %", 1)
                      ]),
                      _: 2
                    }, 1032, ["severity"])
                  ]),
                  _: 1
                }, 8, ["filterMatchModeOptions"]))
              : _createCommentVNode("", true),
            (!_ctx.isIrregularFilterActive)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 6,
                  style: {"min-width":"7rem"}
                }, {
                  header: _withCtx(() => [
                    (_ctx.parentData?.listPriceStartDate)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                          _cache[25] || (_cache[25] = _createElementVNode("div", { class: "w-full" }, "UVP PL", -1)),
                          _createVNode(_component_Tag, { severity: "info" }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", null, "AB " + _toDisplayString(_ctx.parentData?.listPriceStartDate), 1)
                            ]),
                            _: 1
                          })
                        ]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_45, "UVP PL"))
                  ]),
                  body: _withCtx(({data}) => [
                    (data.extractedData?.listPrice)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_46, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.formatter.format(data.extractedData.listPrice)), 1),
                          (data.extractedData.listPriceCalculationFactor)
                            ? _withDirectives((_openBlock(), _createElementBlock("i", _hoisted_47, null, 512)), [
                                [
                                  _directive_tooltip,
                                  _ctx.$t('messages.listPriceWasCalculatedFromCostPrice', {factor : _ctx.liteFormatter.format(data.extractedData.listPriceCalculationFactor) }),
                                  void 0,
                                  { top: true }
                                ]
                              ])
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_Column, {
              header: "UVP Wcp",
              sortable: "",
              field: "calculatedListPrice"
            }, {
              body: _withCtx(({data}) => [
                _createElementVNode("div", _hoisted_48, _toDisplayString(_ctx.formatter.format(data.calculatedListPrice)), 1)
              ]),
              _: 1
            }),
            (!_ctx.isIrregularFilterActive)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 7,
                  header: "UVP Diff",
                  style: {"min-width":"6rem"},
                  sortable: "",
                  field: "listPriceDifferenceInPercentage",
                  filterMatchModeOptions: [{label: 'Equals', value: _ctx.percentageFilter}]
                }, {
                  filter: _withCtx(({filterModel, filterCallback}) => [
                    _createVNode(_component_p_dropdown, {
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                      options: [
                                {
                                    value: 0,
                                    label: 'Keine Änderung'
                                },
                                {
                                    value: 1,
                                    label: 'Alle günstiger'
                                },
                                {
                                    value: 2,
                                    label: 'Alle teurer'
                                },
                                {
                                    value: -15,
                                    label: '> -15%'
                                },
                                {
                                    value: -5,
                                    label: '> -5%'
                                },
                                {
                                    value: -4,
                                    label: '<= -5%'
                                },
                                {
                                    value: 4,
                                    label: '<= 5%'
                                },
                                {
                                    value: 5,
                                    label: '> 5%'
                                },
                                {
                                    value: 15,
                                    label: '> 15%'
                                }
                               
                            ],
                      placeholder: "Any",
                      optionLabel: "label",
                      optionValue: "value",
                      onChange: filterCallback,
                      inputClass: "p-column-filter text-xs"
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                  ]),
                  body: _withCtx(({data}) => [
                    _createVNode(_component_Tag, {
                      severity: (data.listPriceDifferenceInPercentage > 0) ? 'danger' : (data.listPriceDifferenceInPercentage < 0 ? 'success' : '')
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(Math.abs(data.listPriceDifferenceInPercentage).toFixed(2)) + " %", 1)
                      ]),
                      _: 2
                    }, 1032, ["severity"])
                  ]),
                  _: 1
                }, 8, ["filterMatchModeOptions"]))
              : _createCommentVNode("", true),
            _createVNode(_component_Column, {
              header: "VK Wcp",
              style: {"min-width":"6rem"}
            }, {
              body: _withCtx(({data}) => [
                _createElementVNode("div", _hoisted_49, _toDisplayString(_ctx.formatter.format(data.calculatedSellPrice)), 1),
                (data.extractedData?.listPrice && data.calculatedSellPrice > data.extractedData.listPrice)
                  ? (_openBlock(), _createBlock(_component_Tag, {
                      key: 0,
                      severity: "danger"
                    }, {
                      default: _withCtx(() => _cache[26] || (_cache[26] = [
                        _createElementVNode("span", null, "VK > UVP", -1)
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            (!_ctx.isIrregularFilterActive)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 8,
                  header: "WBT",
                  style: {"min-width":"6rem"}
                }, {
                  body: _withCtx(({data}) => [
                    (data.extractedData?.procurementLeadDays !== data.weclappArticle?.procurementLeadDays)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_50, _cache[27] || (_cache[27] = [
                          _createElementVNode("i", { class: "pi pi-exclamation-triangle text-red-500 text-xl mb-1" }, null, -1)
                        ])))
                      : _createCommentVNode("", true),
                    (data.extractedData?.procurementLeadDays)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_51, " PL: " + _toDisplayString(data.extractedData.procurementLeadDays), 1))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_52, " Wcp: " + _toDisplayString(data.weclappArticle?.procurementLeadDays), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (!_ctx.isIrregularFilterActive)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 9,
                  field: "trailingDiscount",
                  header: "NSR",
                  filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
                }, {
                  filter: _withCtx(({filterModel, filterCallback}) => [
                    _createVNode(_component_TriStateCheckbox, {
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                      onChange: $event => (filterCallback())
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                  ]),
                  body: _withCtx(({data}) => [
                    (data.trailingDiscount)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_53, _toDisplayString(_ctx.formatInCurrency(data.extractedData.nsr,data.extractedData.costPriceCurrency
                    || 'EUR')), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (!_ctx.isIrregularFilterActive)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 10,
                  field: "isProcessed",
                  filterMatchModeOptions: [{label: 'Equals', value: 'equals'}],
                  header: _ctx.$t('labels.processedBy')
                }, {
                  filter: _withCtx(({filterModel, filterCallback}) => [
                    _createVNode(_component_TriStateCheckbox, {
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                      onChange: $event => (filterCallback())
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                  ]),
                  body: _withCtx(({data}) => [
                    _createElementVNode("div", _hoisted_54, _toDisplayString(data.processedBy), 1),
                    _createElementVNode("div", _hoisted_55, _toDisplayString(data.processedAt), 1)
                  ]),
                  _: 1
                }, 8, ["header"]))
              : _createCommentVNode("", true),
            (!_ctx.isIrregularFilterActive)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 11,
                  field: "hasError",
                  filterMatchModeOptions: [{label: 'Equals', value: 'equals'}],
                  header: _ctx.$t('labels.error'),
                  style: {"max-width":"10rem","word-wrap":"break-word"}
                }, {
                  filter: _withCtx(({filterModel, filterCallback}) => [
                    _createVNode(_component_TriStateCheckbox, {
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                      onChange: $event => (filterCallback())
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                  ]),
                  body: _withCtx(({data}) => [
                    _createElementVNode("div", _hoisted_56, _toDisplayString(_ctx.stripTags(data.weclappError, 100, '...')), 1)
                  ]),
                  _: 1
                }, 8, ["header"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["totalRecords", "value", "rowClass", "filters"])
      ]),
      _: 1
    })
  ], 64))
}