import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withKeys as _withKeys, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "w-full flex justify-content-between" }
const _hoisted_2 = { class: "flex align-items-center" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "flex align-content-center" }
const _hoisted_5 = { class: "text-lg" }
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "text-xs" }
const _hoisted_8 = { class: "text-xs" }
const _hoisted_9 = { class: "text-xs" }
const _hoisted_10 = { class: "text-xs" }
const _hoisted_11 = {
  key: 0,
  class: "text-xs"
}
const _hoisted_12 = ["href"]
const _hoisted_13 = { class: "mt-2" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = ["href"]
const _hoisted_16 = { class: "pi pi-pencil text-color ml-2" }
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = { key: 1 }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_p_button = _resolveComponent("p-button")
  const _component_Column = _resolveComponent("Column")
  const _component_InputText = _resolveComponent("InputText")
  const _component_Calendar = _resolveComponent("Calendar")
  const _component_Tag = _resolveComponent("Tag")
  const _component_p_multiselect = _resolveComponent("p-multiselect")
  const _component_TriStateCheckbox = _resolveComponent("TriStateCheckbox")
  const _component_router_link = _resolveComponent("router-link")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_Panel = _resolveComponent("Panel")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, null, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('labels.usedProducts')), 1),
            _createVNode(_component_p_button, {
              class: "ml-2",
              onClick: _ctx.onAddNewProductClick,
              size: "small",
              severity: "success"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("buttons.addNewGeneric")), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_DataTable, {
          class: "p-datatable-sm text-sm",
          stripedRows: "",
          lazy: "",
          paginator: true,
          rows: 20,
          scrollable: "",
          scrollHeight: "calc(100vh - 23rem)",
          totalRecords: _ctx.totalRecords,
          "paginator-template": 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown',
          "rows-per-page-options": [10,20,50],
          "current-page-report-template": '{first} to {last} of {totalRecords}',
          ref: "dt",
          dataKey: "id",
          onPage: _cache[1] || (_cache[1] = $event => (_ctx.onPage($event))),
          onFilter: _cache[2] || (_cache[2] = $event => (_ctx.onFilter($event))),
          onSort: _cache[3] || (_cache[3] = $event => (_ctx.onSort($event))),
          value: _ctx.products,
          responsiveLayout: "scroll",
          filters: _ctx.filters,
          "onUpdate:filters": _cache[4] || (_cache[4] = $event => ((_ctx.filters) = $event)),
          filterDisplay: "row",
          expandedRows: _ctx.expandedRows,
          "onUpdate:expandedRows": _cache[5] || (_cache[5] = $event => ((_ctx.expandedRows) = $event))
        }, {
          expansion: _withCtx((slotProps) => [
            (slotProps.data?.description)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  innerHTML: slotProps.data.description
                }, null, 8, _hoisted_17))
              : (_openBlock(), _createElementBlock("div", _hoisted_18, "No description available."))
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              expander: true,
              headerStyle: "width: 3em"
            }),
            _createVNode(_component_Column, {
              field: "articleNumber",
              sortable: true,
              header: _ctx.$t('labels.articleNumber'),
              style: {"min-width":"16rem"}
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-sm",
                  placeholder: _ctx.$t('labels.articleNumber')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["sales-channel-dot w-1rem h-1rem mr-1", {'sales-channel-teltec':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.shopwareData?.active, 'sales-channel-videodata':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.shopwareData?.active, 'sales-channel-teltec-border':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.shopwareData && !data.shopwareData.active, 'sales-channel-videodata-border':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.shopwareData && !data.shopwareData.active, 'bg-gray-200': !data?.shopwareData?.active}])
                  }, null, 2),
                  _createElementVNode("div", {
                    class: _normalizeClass(["sales-channel-dot w-1rem h-1rem mr-1", {'sales-channel-videodata':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.secondShopwareData?.active, 'sales-channel-teltec':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.secondShopwareData?.active, 'sales-channel-videodata-border':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.secondShopwareData && !data.secondShopwareData.active, 'sales-channel-teltec-border':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.secondShopwareData && !data.secondShopwareData.active, 'bg-gray-200': !data?.secondShopwareData?.active}])
                  }, null, 2),
                  _createElementVNode("span", _hoisted_5, [
                    _createElementVNode("a", {
                      href: data.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + data.id,
                      target: "_blank"
                    }, _toDisplayString(data.articleNumber), 9, _hoisted_6)
                  ])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createTextVNode(_toDisplayString(_ctx.$t('labels.stockTotal')) + ": ", 1),
                  _createElementVNode("b", null, _toDisplayString(data.stockQuantity), 1)
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createTextVNode(_toDisplayString(_ctx.$t('labels.stockQuantityWithoutOrder')) + ": ", 1),
                  _createElementVNode("b", null, _toDisplayString(data.stockQuantityWithoutOrder), 1)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createTextVNode(_toDisplayString(_ctx.$t('labels.vkPrice')) + ": ", 1),
                  _createElementVNode("b", null, _toDisplayString(_ctx.vkPriceCalculation(data)), 1)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createTextVNode(_toDisplayString(_ctx.$t('labels.ekPrice')) + ": ", 1),
                  _createElementVNode("b", null, _toDisplayString(_ctx.ekPriceCalculation(data)), 1)
                ]),
                (data.sourceProductNumber)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _cache[6] || (_cache[6] = _createTextVNode(" Neuware: ")),
                      _createElementVNode("a", {
                        href: data.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + data.sourceProductId,
                        target: "_blank"
                      }, _toDisplayString(data.sourceProductNumber), 9, _hoisted_12)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "createdDate",
              dataType: "date",
              sortable: "",
              header: _ctx.$t('labels.createdAt'),
              style: {"max-width":"15rem"}
            }, {
              filter: _withCtx(({filterModel}) => [
                _createVNode(_component_Calendar, {
                  class: "p-column-filter text-xs",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  dateFormat: "dd.mm.yy"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              body: _withCtx((data) => [
                _createTextVNode(_toDisplayString(new Date(data.data.createdDate).toLocaleDateString("de-DE")), 1)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "name",
              sortable: true,
              style: {"min-width":"16rem"},
              header: _ctx.$t('labels.name')
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-sm",
                  placeholder: _ctx.$t('labels.name')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("span", null, _toDisplayString(data.name || data.shopwareData?.name), 1),
                _createElementVNode("div", _hoisted_13, [
                  (data.systemTags.indexOf('TTC-PRODUCT-3-STARS') !== -1)
                    ? (_openBlock(), _createBlock(_component_Tag, {
                        key: 0,
                        severity: "success"
                      }, {
                        default: _withCtx(() => _cache[7] || (_cache[7] = [
                          _createElementVNode("span", null, "3 Sterne ★★★", -1)
                        ])),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (data.systemTags.indexOf('TTC-PRODUCT-2-STARS') !== -1)
                    ? (_openBlock(), _createBlock(_component_Tag, {
                        key: 1,
                        severity: "warning"
                      }, {
                        default: _withCtx(() => _cache[8] || (_cache[8] = [
                          _createElementVNode("span", null, "2 Sterne ★★", -1)
                        ])),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (data.systemTags.indexOf('TTC-PRODUCT-1-STAR') !== -1)
                    ? (_openBlock(), _createBlock(_component_Tag, {
                        key: 2,
                        severity: "danger"
                      }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createElementVNode("span", null, "1 Stern ★", -1)
                        ])),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (data.systemTags.indexOf('TTC-PRODUCT-GRADE-A') !== -1)
                    ? (_openBlock(), _createBlock(_component_Tag, {
                        key: 3,
                        severity: "info"
                      }, {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createElementVNode("span", null, "Grade A", -1)
                        ])),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (data.systemTags.indexOf('TTC-PRODUCT-GRADE-B') !== -1)
                    ? (_openBlock(), _createBlock(_component_Tag, {
                        key: 4,
                        severity: "warning"
                      }, {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createElementVNode("span", null, "Grade B", -1)
                        ])),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "tags",
              style: {"max-width":"12rem"},
              header: _ctx.$t('labels.productHome.productType'),
              filterMatchModeOptions: [{label: 'In', value: 'in'}]
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_p_multiselect, {
                  class: "p-column-filter text-sm w-full",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  options: [{label:'OpenBox', value:'TTC-PRODUCT-OPENBOX'}, {label:'Used', value:'TTC-PRODUCT-USED'}, {label:'ExDemo', value:'TTC-PRODUCT-EXDEMO'}, {label:'DEMO-POOL', value:'TTC-PRODUCT-DEMO'}, {label:'KOMMISSIONSWARE', value:'TTC-PRODUCT-COMMISSION-GOOD'}],
                  optionLabel: "label",
                  optionValue: "value",
                  onChange: $event => (filterCallback()),
                  placeholder: _ctx.$t('labels.productHome.productType')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "placeholder"])
              ]),
              body: _withCtx(({data}) => [
                (data.systemTags.indexOf('TTC-PRODUCT-DEMO') !== -1)
                  ? (_openBlock(), _createBlock(_component_Tag, {
                      key: 0,
                      severity: "info"
                    }, {
                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                        _createElementVNode("span", null, "DEMO", -1)
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (data.systemTags.indexOf('TTC-PRODUCT-USED') !== -1)
                  ? (_openBlock(), _createBlock(_component_Tag, { key: 1 }, {
                      default: _withCtx(() => _cache[13] || (_cache[13] = [
                        _createElementVNode("span", null, "USED", -1)
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (data.systemTags.indexOf('TTC-PRODUCT-EXDEMO') !== -1)
                  ? (_openBlock(), _createBlock(_component_Tag, {
                      key: 2,
                      severity: "success"
                    }, {
                      default: _withCtx(() => _cache[14] || (_cache[14] = [
                        _createElementVNode("span", null, "EX-DEMO", -1)
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (data.systemTags.indexOf('TTC-PRODUCT-OPENBOX') !== -1)
                  ? (_openBlock(), _createBlock(_component_Tag, {
                      key: 3,
                      severity: "warning"
                    }, {
                      default: _withCtx(() => _cache[15] || (_cache[15] = [
                        _createElementVNode("span", null, "OPENBOX", -1)
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (data.systemTags.indexOf('TTC-PRODUCT-COMMISSION-GOOD') !== -1)
                  ? (_openBlock(), _createBlock(_component_Tag, {
                      key: 4,
                      severity: "danger"
                    }, {
                      default: _withCtx(() => _cache[16] || (_cache[16] = [
                        _createElementVNode("span", null, "KOMMISSIONSWARE", -1)
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              header: _ctx.$t('labels.productDescription.shortDescription'),
              field: "shortDescription1",
              style: {"max-width":"12rem"}
            }, null, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "productCategory",
              header: _ctx.$t('labels.articleCategory'),
              style: {"min-width":"12rem"}
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs",
                  placeholder: _ctx.$t('labels.articleCategory')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
              ]),
              body: _withCtx(({data}) => [
                (data?.shopwareData?.categories)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_14, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.shopwareData.categories, (element) => {
                        return (_openBlock(), _createElementBlock("li", null, _toDisplayString(element.name), 1))
                      }), 256))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "availableInShop",
              dataType: "boolean",
              bodyClass: "text-center",
              style: {"max-width":"6rem"},
              header: _ctx.$t('labels.productHome.availableInShop')
            }, {
              filter: _withCtx(({filterModel, filterCallback}) => [
                _createVNode(_component_TriStateCheckbox, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onChange: $event => (filterCallback())
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("i", {
                  class: _normalizeClass(["pi", {'text-green-600 pi-check-circle': data.customAttributes?.article_ecom_new_tt_backend, 'text-red-600 pi-times-circle': !(data.customAttributes?.article_ecom_new_tt_backend)}])
                }, null, 2)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "activeInShop",
              dataType: "boolean",
              bodyClass: "text-center",
              style: {"max-width":"6rem"},
              header: _ctx.$t('labels.productHome.activeInShop')
            }, {
              filter: _withCtx(({filterModel, filterCallback}) => [
                _createVNode(_component_TriStateCheckbox, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onChange: $event => (filterCallback())
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("i", {
                  class: _normalizeClass(["pi", {'text-green-600 pi-check-circle': data.customAttributes?.article_ecom_new_tt_frontend, 'text-red-600 pi-times-circle': !(data.customAttributes?.article_ecom_new_tt_frontend)}])
                }, null, 2)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, { style: {"min-width":"8rem"} }, _createSlots({ _: 2 }, [
              (_ctx.editPermissionAvailable || _ctx.viewAccessoriesPermissionAvailable || (_ctx.data.shopwareData?.name && _ctx.data.shopwareUrl) )
                ? {
                    name: "body",
                    fn: _withCtx(({data}) => [
                      ((data.shopwareData?.name && data.shopwareUrl))
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            target: "_blank",
                            href: _ctx.resolvedShopwareUrl(data)
                          }, _cache[17] || (_cache[17] = [
                            _createElementVNode("i", { class: "pi pi-shopping-cart text-color" }, null, -1)
                          ]), 8, _hoisted_15))
                        : _createCommentVNode("", true),
                      (_ctx.editPermissionAvailable)
                        ? (_openBlock(), _createBlock(_component_router_link, {
                            key: 1,
                            to: {
                                    name: 'ProductDetails',
                                    params: {id: data.articleNumber}
                                }
                          }, {
                            default: _withCtx(() => [
                              _withDirectives(_createElementVNode("i", _hoisted_16, null, 512), [
                                [
                                  _directive_tooltip,
                                  _ctx.$t('labels.articleDetails'),
                                  void 0,
                                  { left: true }
                                ]
                              ])
                            ]),
                            _: 2
                          }, 1032, ["to"]))
                        : _createCommentVNode("", true)
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1024)
          ]),
          _: 1
        }, 8, ["totalRecords", "value", "filters", "expandedRows"])
      ]),
      _: 1
    })
  ], 64))
}